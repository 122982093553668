import { t } from 'i18next'
import React, { Component } from "react";
import withRouter from "helpers/withRouter";
import paginate from "utils/paginate";
import formatRow from "utils/formatRow";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import _ from "lodash";
import GoBackPageNavigation from '../../components/GoBackPageNavigation/GoBackPageNavigation'
import { fetchDueDates } from "../../redux/features/dueDates/dueDatesSlice";
import { isTaxAgentSelector } from "../../redux/selectors";
import {
  Page,
  Layout,
  Card,
  Spinner,
  DataTable,
  Text,
  Button,
  Box,
  Inline,
} from "@shopify/polaris";
import NoDataMessage from "components/NoDataMessage/NoDataMessage";
import Pagination from "components/Pagination/Pagination";
import PageHelmet from "components/PageHelmet";
import HighlightableDataTable from "components/HighlightableDataTable";

import { AlertIcon } from "icons";
import { createCustomDispatch } from "helpers/customDispatch";

const defaultLimit = 25;

class DueDates extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pagination: {
        page: 1,
        pages: 1,
        perPage: defaultLimit,
      },
      query: {
        limit: defaultLimit,
      },
    };
  }

  componentDidMount() {
    this.props
      .fetchDueDates({ limit: defaultLimit })
      .then(() => {
        const { dueDates } = this.props;
        return this.setState({
          pages: dueDates && Math.ceil(dueDates.count / defaultLimit),
        });
      })
      .catch(_.noop);
  }

  componentWillReceiveProps(nextProps) {
    const pagination = this.state.pagination;
    const { query } = this.state;
    pagination.pages =
      nextProps.dueDates && nextProps.dueDates.count
        ? Math.ceil(nextProps.dueDates.count / defaultLimit) || 1
        : 1;
    if (query && !query.offset) {
      pagination.page = 1;
    }
    this.setState({ pagination });
  }

  onSelectPage = (inputPage) => {
    const pages =
      Math.ceil(this.props.dueDates.count / this.state.pagination.perPage) || 1;
    const page = Math.min(Math.max(inputPage, 1), pages);

    const query = {
      offset: (page - 1) * this.state.pagination.perPage,
      limit: defaultLimit,
    };
    this.setState(
      {
        query: _.assign(this.state.query, query),
        pagination: {
          ...this.state.pagination,
          page: page,
          pages: pages,
        },
      },
      () => {
        this.props.fetchDueDates(this.state.query);
      }
    );
  };

  renderSummary = () => {
    const { t, fetchingDueDates, dueDates, user } = this.props;
    const rows = !_.isEmpty(dueDates) ? dueDates.summary : [];

    const IS_TAX_AGENT = isTaxAgentSelector(user);

    const columns = [
      {
        property: "tax_number",
        header: {
          label: "Tax number",
        },
      },
      {
        property: "country",
        header: {
          label: t("due.country"),
        },
      },
      {
        property: "state",
        header: {
          label: "State",
        },
      },
      {
        property: "name",
        header: {
          label: t("due.typeOfRep"),
        },
      },
      {
        property: "period_type",
        header: {
          label: "Periodicity",
        },
        cell: {
          formatters: [
            (value) => {
              switch (value) {
                case "year":
                  return "Yearly";
                case "quarter":
                  return "Quarterly";
                default:
                  return "Monthly";
              }
            },
          ],
        },
      },
      {
        property: "deadline",
        header: {
          label: "Deadline",
        },
      },
    ];

    if (IS_TAX_AGENT) {
      columns.push({
        property: "company_name",
        header: {
          label: "Client",
        },
      });
    }

    const sortedRows = formatRow(rows, columns);

    return (
      <Card title={"Your reporting summary:"}>
        {fetchingDueDates ? (
          <div>
            <Spinner />
          </div>
        ) : (
          <>
            {!_.isEmpty(rows) && (
              <>
                <br />
                <DataTable
                  columnContentTypes={[
                    "text",
                    "text",
                    "text",
                    "text",
                    "text",
                    "text",
                  ]}
                  headings={columns.map(({ header }) => (
                    <Text fontWeight='semibold'>{header.label}</Text>
                  ))}
                  rows={sortedRows}
                  hideScrollIndicator
                  truncate
                />
              </>
            )}

            {_.isEmpty(rows) && (
              <div>
                <NoDataMessage style={{ height: "auto" }} />
              </div>
            )}
          </>
        )}
      </Card>
    );
  };

  getPeriodTypeForVat = (value) => {
    const { t } = this.props;
    switch (value) {
      case "year":
        return t("dash.yVAT");
      case "quarter":
        return t("dash.qVAT");
      case "month":
        return t("dash.mVAT");
      case "bi-annual":
        return "Bi-Annual";
      default:
        return value;
    }
  };

  getPeriodTypeForEPR = (value) => {
    const { t } = this.props;
    switch (value) {
      case "year":
        return t("dash.yEPR");
      case "quarter":
        return t("dash.qEPR");
      case "month":
        return t("dash.mEPR");
      default:
        return value;
    }
  };

  getPeriodTypeForSalesTax = (value) => {
    const { t } = this.props;
    switch (value) {
      case "year":
        return t("dash.ySalexTax");
      case "quarter":
        return t("dash.qSalesTax");
      case "month":
        return t("dash.mSalesTax");
      case "bi-annual":
        return "Bi-Annual";
      default:
        return value;
    }
  };

  sortRowsByStatus = (rows) => {
    const statusOrder = { red: 1, yellow: 2 };
    return rows.slice().sort((a, b) => {
      const statusA = a.status.toLowerCase();
      const statusB = b.status.toLowerCase();
      return (
        statusOrder[statusA] - statusOrder[statusB] ||
        statusA.localeCompare(statusB)
      );
    });
  };

  renderDueDates = () => {
    const { t, dueDates, fetchingDueDates, user } = this.props;
    const { pagination } = this.state;
    const IS_TAX_AGENT = isTaxAgentSelector(user);
    const rows = this.sortRowsByStatus(dueDates.items ?? []);

    const columns = [
      {
        property: "name",
        header: {
          label: t("due.typeOfRep"),
        },
      },
      {
        property: "country",
        header: {
          label: t("due.country"),
        },
      },
      {
        property: "period_type",
        header: {
          label: t("due.periodType"),
        },
        cell: {
          formatters: [
            (value, { rowData }) => {
              if (rowData.type_of_report.includes("Sales Tax")) {
                return this.getPeriodTypeForSalesTax(rowData.period_type);
              } else if (rowData.type_of_report.includes("EPR")) {
                return this.getPeriodTypeForEPR(rowData.period_type);
              }

              return this.getPeriodTypeForVat(rowData.period_type);
            },
          ],
        },
      },
      {
        header: {
          label: t("due.period"),
        },
        cell: {
          formatters: [
            (value, { rowData }) =>
              `${rowData.period} ${rowData.period_type} ${rowData.period_year}`,
          ],
        },
      },
      {
        property: "exp_date",
        header: {
          label: t("due.expDate"),
        },
        cell: {
          formatters: [
            (value, { rowData }) => (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                gap="4"
              >
                <Text>{value}</Text>
                {(rowData.status === "red" || rowData.status === "yellow") && (
                  <AlertIcon
                    stroke={rowData.status === "red" ? "#DE3618" : "#B8821B"}
                  />
                )}
              </Box>
            ),
          ],
        },
      },
      {
        property: "",
        header: {
          label: "",
        },
      },
      {
        property: "",
        header: {
          label: IS_TAX_AGENT ? "Client" : "",
        },
        cell: {
          formatters: [
            (value, { rowData }) => {
              return (
                <>
                  {!IS_TAX_AGENT ? (
                    <>
                      {(rowData.status === "red" ||
                        rowData.status === "yellow") && (
                        <Button
                          variant={'primary'}
                          onClick={() => {
                            if (rowData.type_of_report === "VAT return") {
                              this.props.navigate("/vat-returns");
                            } else {
                              this.props.navigate("/tax-returns");
                            }
                          }}
                        >
                          {t("dash.fillVR")}
                        </Button>
                      )}
                    </>
                  ) : (
                    rowData.company_name
                  )}
                </>
              );
            },
          ],
        },
      },
    ];

    const sortedRows = formatRow(rows, columns);
    const paginatedRows = paginate(pagination)(sortedRows);

    return (
      <>
        <Card title={`${t("due.yourDD")}:`} sectioned={fetchingDueDates}>
          {fetchingDueDates ? (
            <Spinner />
          ) : (
            <>
              <br />
              {!_.isEmpty(paginatedRows.rows) && !fetchingDueDates && (
                <HighlightableDataTable
                  headings={columns.map(({ header }) => (
                    <Text fontWeight='semibold'>{header.label}</Text>
                  ))}
                  rows={paginatedRows.rows}
                  rawItems={rows}
                  rowClassNameCallback={(row, rowData) => ({
                    "bg-red": rowData.status === "red",
                    "bg-yellow": rowData.status === "yellow",
                  })}
                  styles={{
                    td: {
                      padding: "0.3rem 0.85rem",
                    },
                  }}
                />
              )}

              {_.isEmpty(rows) && (
                <div>
                  <NoDataMessage style={{ height: "auto" }} />
                </div>
              )}
            </>
          )}
        </Card>
        {!_.isEmpty(paginatedRows.rows) && (
          <Box padding='4'>
            <Pagination
              total={this.props.dueDates.count}
              current={pagination.page}
              pageSize={defaultLimit}
              onChange={(current) => this.onSelectPage(current)}
            />
          </Box>
        )}
      </>
    );
  };

  render() {
    const { t } = this.props;

    return (
      <Page
        fullWidth
        separator
        title={
          <GoBackPageNavigation content={
            <Text variant='heading3xl' as='span'>
            {t("due.dueDates")}
          </Text>
          } />
        }
        subtitle={
          <Text variant='bodyLg' as='span' color='subdued'>
            Here you can see your due dates
          </Text>
        }
      >
        <PageHelmet title={"Due Dates"} />
        <Layout>
          <Layout.Section>{this.renderDueDates()}</Layout.Section>
          <Layout.Section>{this.renderSummary()}</Layout.Section>
        </Layout>
        <br />
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  dueDates: state.dueDates.dueDates,
  fetchingDueDates: state.dueDates.fetching,
  defaultLanguage: state.user.defaultLanguage,
  user: state.user.user,
});

const mapDispatchToProps = (defaultDispatch) => {
  const dispatch = createCustomDispatch(defaultDispatch);

  return {
    fetchDueDates: (params) => dispatch(fetchDueDates(params)),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(DueDates))
);
