import React from "react";

const MessagesIcon = ({ selected }) => {
  if (selected) {
    return (
      <svg
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M1.77858 6.81786C1.52864 6.63933 1.40367 6.55007 1.29472 6.53848C1.13178 6.52114 0.968479 6.60518 0.887876 6.74784C0.833978 6.84324 0.83398 6.99513 0.833985 7.29891V12.7011C0.833974 13.3719 0.833966 13.9255 0.870813 14.3765C0.909084 14.8449 0.991221 15.2755 1.1973 15.68C1.51687 16.3072 2.02681 16.8171 2.65402 17.1367C3.05846 17.3428 3.48905 17.4249 3.95746 17.4632C4.40846 17.5 4.96208 17.5 5.63288 17.5H14.3684C15.0392 17.5 15.5928 17.5 16.0438 17.4632C16.5123 17.4249 16.9428 17.3428 17.3473 17.1367C17.9745 16.8171 18.4844 16.3072 18.804 15.68C19.0101 15.2755 19.0922 14.8449 19.1305 14.3765C19.1673 13.9255 19.1673 13.3719 19.1673 12.7011V7.2989C19.1673 7.15001 19.1673 7.07557 19.1537 7.02358C19.092 6.78856 18.8365 6.65468 18.6082 6.73776C18.5577 6.75614 18.4957 6.79905 18.3717 6.88487L11.9199 11.3516C11.4625 11.6693 11.0609 11.9482 10.6046 12.0588C10.2055 12.1556 9.78867 12.1525 9.39104 12.0499C8.93642 11.9326 8.53892 11.6478 8.08619 11.3234L1.77858 6.81786Z'
          fill='rgba(38, 127, 215, 1)'
        />
        <path
          d='M18.4447 4.80727C18.5985 4.70077 18.6754 4.64752 18.7227 4.56187C18.7593 4.49536 18.7803 4.39003 18.7719 4.31454C18.7611 4.21733 18.7198 4.15314 18.6372 4.02478C18.3206 3.53304 17.8651 3.12713 17.3473 2.86331C16.9428 2.65724 16.5123 2.5751 16.0438 2.53683C15.5928 2.49998 15.0392 2.49999 14.3684 2.5H5.6329C4.9621 2.49999 4.40846 2.49998 3.95746 2.53683C3.48905 2.5751 3.05846 2.65724 2.65402 2.86331C2.18816 3.10068 1.78548 3.44384 1.47929 3.86112C1.38296 3.99241 1.33479 4.05805 1.31892 4.15767C1.30651 4.23558 1.32384 4.34381 1.35995 4.41395C1.40613 4.50364 1.48623 4.56085 1.64642 4.67527L8.95902 9.89856C9.56503 10.3314 9.69363 10.4067 9.80743 10.4361C9.93998 10.4703 10.0789 10.4713 10.2119 10.4391C10.3262 10.4114 10.4559 10.338 11.0682 9.91406L18.4447 4.80727Z'
          fill='rgba(38, 127, 215, 1)'
        />
      </svg>
    );
  }
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        opacity='0.12'
        d='M11.5291 10.5959C10.9781 10.9816 10.7026 11.1744 10.403 11.2491C10.1383 11.3151 9.86142 11.3151 9.59672 11.2491C9.29707 11.1744 9.02158 10.9816 8.4706 10.5959L1.6665 5.83301C1.6665 4.4523 2.78579 3.33301 4.1665 3.33301H15.8332C17.2139 3.33301 18.3332 4.4523 18.3332 5.83301L11.5291 10.5959Z'
        fill='#2E2E2E'
      />
      <path
        d='M1.6665 5.83301L8.4706 10.5959C9.02158 10.9816 9.29707 11.1744 9.59672 11.2491C9.86142 11.3151 10.1383 11.3151 10.403 11.2491C10.7026 11.1744 10.9781 10.9816 11.5291 10.5959L18.3332 5.83301M5.6665 16.6663H14.3332C15.7333 16.6663 16.4334 16.6663 16.9681 16.3939C17.4386 16.1542 17.821 15.7717 18.0607 15.3013C18.3332 14.7665 18.3332 14.0665 18.3332 12.6663V7.33301C18.3332 5.93288 18.3332 5.23281 18.0607 4.69803C17.821 4.22763 17.4386 3.84517 16.9681 3.60549C16.4334 3.33301 15.7333 3.33301 14.3332 3.33301H5.6665C4.26637 3.33301 3.56631 3.33301 3.03153 3.60549C2.56112 3.84517 2.17867 4.22763 1.93899 4.69803C1.6665 5.23281 1.6665 5.93288 1.6665 7.33301V12.6663C1.6665 14.0665 1.6665 14.7665 1.93899 15.3013C2.17867 15.7717 2.56112 16.1542 3.03153 16.3939C3.56631 16.6663 4.26637 16.6663 5.6665 16.6663Z'
        stroke='#707070'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default MessagesIcon;
