import React from "react";
import { formatMoney } from "utils/numbers";
import formatRow from "utils/formatRow";
import { Card, DataTable, Text } from "@shopify/polaris";
import _ from "lodash";
import { t } from "i18next";

const FurniturePreview = (props) => {
  const { data, tableProps, isShortNames } = props;

  const columns = [
    {
      property: "name",
      header: {
        label: "Name",
      },
      cell: {
        formatters: [
          (value) => (
            <div style={isShortNames && { maxWidth: "350px" }}>{value}</div>
          ),
        ],
      },
    },
    {
      property: "summ",
      header: {
        label: t("createReturns.amount"),
      },
      cell: {
        formatters: [(value) => formatMoney(value)],
      },
    },
  ];
  const sortedRows = !_.isEmpty(data) ? formatRow(data, columns) : [];

  return (
    <Card>
      <DataTable
        columnContentTypes={["text", "numeric"]}
        headings={columns.map(({ header }) => {
          return <Text fontWeight='semibold' variant={'bodyMd'}>{header.label}</Text>;
        })}
        rows={sortedRows}
        hideScrollIndicator
        increasedTableDensity
        {...tableProps}
      />
    </Card>
  );
};

export default FurniturePreview;
