// dashboardSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import callApi from "helpers/callApi";
import { parseServerError } from "utils/errors";

export const fetchLastUploadedTransaction = createAsyncThunk(
  "dashboard/fetchLastUploadedTransaction",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi(
        "/websites/last_uploaded_transaction",
        "GET"
      );
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const fetchNews = createAsyncThunk(
  "dashboard/fetchNews",
  async (params = { limit: 25, offset: 0 }, { rejectWithValue }) => {
    try {
      const response = await callApi("/dashboard/news", "GET", params);
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const createNews = createAsyncThunk(
  "dashboard/createNews",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi("/dashboard/news", "POST", data);
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const editNews = createAsyncThunk(
  "dashboard/editNews",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi("/dashboard/news", "PUT", data);
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const deleteNews = createAsyncThunk(
  "dashboard/deleteNews",
  async (id, { rejectWithValue }) => {
    try {
      const response = await callApi(`/dashboard/news?news_id=${id}`, "DELETE");
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const fetchDeemedVat = createAsyncThunk(
  "dashboard/fetchDeemedVat",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi("/omp/deemed_vat", "GET");
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const fetchTransByCountryOmp = createAsyncThunk(
  "dashboard/fetchTransByCountryOmp",
  async (params = { limit: 25, offset: 0 }, { rejectWithValue }) => {
    try {
      const response = await callApi(
        "/omp/transactions_by_country",
        "GET",
        params
      );
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const fetchBalanceDashboard = createAsyncThunk(
  "dashboard/fetchBalanceDashboard",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi("/dashboard/balance", "GET");
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const editBalance = createAsyncThunk(
  "dashboard/editBalance",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi("/dashboard/edit_balance", "POST", data);
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const fetchCredits = createAsyncThunk(
  "dashboard/fetchCredits",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi("/dashboard/credits", "GET");
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const fetchLastUploadedTansation = createAsyncThunk(
  "dashboard/fetchLastUploadedTansation",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi(
        "/websites/last_uploaded_transaction",
        "GET"
      );
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

const initialState = {
  news: [],
  newsCount: 0,
  dueDates: {},
  deemedVat: [],
  balance: {},
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLastUploadedTransaction.pending, (state) => {
        state.fetchingLastUploaded = true;
      })
      .addCase(fetchLastUploadedTransaction.fulfilled, (state, action) => {
        state.fetchingLastUploaded = false;
        state.fetchingLastUploadedError = null;
        state.lastUploaded = action.payload;
      })
      .addCase(fetchLastUploadedTransaction.rejected, (state, action) => {
        state.fetchingLastUploaded = false;
        state.fetchingLastUploadedError = parseServerError(action.error);
      })
      .addCase(fetchNews.pending, (state) => {
        state.fetchingNews = true;
      })
      .addCase(fetchNews.fulfilled, (state, action) => {
        state.fetchingNews = false;
        state.fetchingNewsError = null;
        state.news = action.payload.news;
        state.newsCount = action.payload.count;
      })
      .addCase(fetchNews.rejected, (state, action) => {
        state.fetchingNews = false;
        state.fetchingNewsError = parseServerError(action.error);
      })
      .addCase(createNews.pending, (state) => {
        state.creatingNews = true;
      })
      .addCase(createNews.fulfilled, (state, action) => {
        state.creatingNews = false;
        state.creatingNewsError = null;
        // Optionally update the news state with the new news item
      })
      .addCase(createNews.rejected, (state, action) => {
        state.creatingNews = false;
        state.creatingNewsError = parseServerError(action.error);
      })
      .addCase(editNews.pending, (state) => {
        state.editingNews = true;
      })
      .addCase(editNews.fulfilled, (state, action) => {
        state.editingNews = false;
        state.editingNewsError = null;
        // Optionally update the news state with the edited news item
      })
      .addCase(editNews.rejected, (state, action) => {
        state.editingNews = false;
        state.editingNewsError = parseServerError(action.error);
      })
      .addCase(deleteNews.pending, (state) => {
        state.deletingNews = true;
      })
      .addCase(deleteNews.fulfilled, (state, action) => {
        state.deletingNews = false;
        state.deletingNewsError = null;
        // Optionally update the news state to remove the deleted news item
      })
      .addCase(deleteNews.rejected, (state, action) => {
        state.deletingNews = false;
        state.deletingNewsError = parseServerError(action.error);
      })
      .addCase(fetchDeemedVat.pending, (state) => {
        state.fetchingDeemedVat = true;
      })
      .addCase(fetchDeemedVat.fulfilled, (state, action) => {
        state.fetchingDeemedVat = false;
        state.fetchingDeemedVatError = null;
        state.deemedVat = action.payload;
      })
      .addCase(fetchDeemedVat.rejected, (state, action) => {
        state.fetchingDeemedVat = false;
        state.fetchingDeemedVatError = parseServerError(action.error);
      })
      .addCase(fetchTransByCountryOmp.pending, (state) => {
        state.fetchingTransByCountry = true;
      })
      .addCase(fetchTransByCountryOmp.fulfilled, (state, action) => {
        state.fetchingTransByCountry = false;
        state.fetchingTransByCountryError = null;
        state.transByCountry = action.payload;
      })
      .addCase(fetchTransByCountryOmp.rejected, (state, action) => {
        state.fetchingTransByCountry = false;
        state.fetchingTransByCountryError = parseServerError(action.error);
      })
      .addCase(fetchBalanceDashboard.pending, (state) => {
        state.fetchBalanceDashboard = true;
      })
      .addCase(fetchBalanceDashboard.fulfilled, (state, action) => {
        state.fetchBalanceDashboard = false;
        state.fetchBalanceDashboardError = null;
        state.balance = action.payload;
      })
      .addCase(fetchBalanceDashboard.rejected, (state, action) => {
        state.fetchBalanceDashboard = false;
        state.fetchBalanceDashboardError = parseServerError(action.error);
      })
      .addCase(editBalance.pending, (state) => {
        state.editingBalance = true;
      })
      .addCase(editBalance.fulfilled, (state, action) => {
        state.editingBalance = false;
        state.editingBalanceError = null;
      })
      .addCase(editBalance.rejected, (state, action) => {
        state.editingBalance = false;
        state.editingBalanceError = parseServerError(action.error);
      })
      .addCase(fetchCredits.pending, (state) => {
        state.fetchingCredits = true;
      })
      .addCase(fetchCredits.fulfilled, (state, action) => {
        state.fetchingCredits = false;
        state.fetchingCreditsError = null;
        state.credits = action.payload;
      })
      .addCase(fetchCredits.rejected, (state, action) => {
        state.fetchingCredits = false;
        state.fetchingCreditsError = parseServerError(action.error);
      })
      .addCase(fetchLastUploadedTansation.pending, (state) => {
        state.fetchingLastUploaded = true;
      })
      .addCase(fetchLastUploadedTansation.fulfilled, (state, action) => {
        state.fetchingLastUploaded = false;
        state.lastUploaded = action.result;
        state.fetchingLastUploadedError = null;
      })
      .addCase(fetchLastUploadedTansation.rejected, (state, action) => {
        state.fetchingLastUploaded = false;
        state.fetchingLastUploadedError = parseServerError(action.error);
      });
  },
});

export default dashboardSlice.reducer;
