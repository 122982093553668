import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import withRouter from "helpers/withRouter";
import { Card, Layout, Page, Text } from "@shopify/polaris";
import { Language, Notifications, PersonalDetails, Security } from "./Sections";

class PersonalSettings extends Component {
  render() {
    return (
      <div className={'company-settings'}>
        <PersonalDetails />
        <Security />
        <Notifications />
        <Language />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(PersonalSettings))
);
