import * as PropTypes from 'prop-types'
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import withRouter from "helpers/withRouter";
import {
  Card,
  Layout,
  Button,
  ButtonGroup,
  TextField,
  FormLayout,
  Select,
  DataTable,
  Text,
} from "@shopify/polaris";
import _ from "lodash";
import { preciseRound } from "utils/numbers";
import moment from "moment";
import SaveBar from "components/SaveBar/SaveBar";
import Modal from "components/Modal";
import Stepper from "components/Stepper";
import ArrowLeftBack from '../../../img/arrow-left-for-button-back.svg'
import ArrowRight from '../../../img/arrow-right-white.svg'
import { createDeclaration } from '../../../redux/features/declarations/declarationsSlice'
import UnionOSSPreviewNew from "../Previews/UnionOSSPreviewNew";
import formatRow from "utils/formatRow";

import step1IMG from "img/step1.svg";
import RenderCreatedReturnDialog from "../common/RenderCreatedReturnDialog";
import { createCustomDispatch } from "helpers/customDispatch";

function Typography(props) {
  return null
}

Typography.propTypes = {
  variant: PropTypes.string,
  fontWeight: PropTypes.string,
  children: PropTypes.node
}

class UnionOSSReturn extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stepIndex: 1, // 0 - intro in Create Return
      dataGoodsRC: props.returnData.data.sales_of_goods_from_rc,
      dataGoodsOther: props.returnData.data.sales_of_goods_from_other,
      dataServices: props.returnData.data.sales_of_services_oss,

      dataGoodsRCDeemed: props.returnData.data.sales_of_goods_from_rc_deemed,
      dataGoodsOtherDeemed:
        props.returnData.data.sales_of_goods_from_other_deemed,
      dataServicesDeemed: props.returnData.data.sales_of_services_deemed,

      correctedGoodsRC: props.returnData.data.sales_of_goods_from_rc_corrected,
      correctedGoodsOther:
        props.returnData.data.sales_of_goods_from_other_corrected,
      correctedServices: props.returnData.data.sales_of_services_corrected,

      dataGoodsOtherAdding: {},
      correctedGoodsRCAdding: {},
      correctedGoodsOtherAdding: {},
      correctedServicesAdding: {},
      dataGoodsOtherDeemedAdding: {},

      openSteps: {},

      actionDialogs: {
        reset: { open: false },
        created: { open: false },
      },
    };
  }

  getStepContent(stepIndex) {
    const {
      dataGoodsRC,
      dataGoodsOther,
      dataGoodsOtherAdding,
      dataServices,
      correctedGoodsRC,
      correctedGoodsRCAdding,
      correctedGoodsOther,
      correctedGoodsOtherAdding,
      correctedServices,
      correctedServicesAdding,
    } = this.state;

    switch (stepIndex) {
      case 1:
        return this.stepAllVatRates(
          "sales_of_goods_from_rc",
          dataGoodsRC,
          "dataGoodsRC"
        );
      case 2:
        return this.stepWithAdding(
          ["Departure country", "Arrival country", "Vat rate", "Amount", "Vat"],
          [
            "departure_country",
            "arrival_country",
            "vat_percent",
            "summ",
            "vat",
          ],
          dataGoodsOther,
          "dataGoodsOther",
          dataGoodsOtherAdding,
          "dataGoodsOtherAdding",
          "sumGoodsOther",
          "vatGoodsOther"
        );
      case 3:
        return this.stepAllVatRates(
          "sales_of_services_oss",
          dataServices,
          "dataServices"
        );
      case 4:
        return this.stepWithAdding(
          ["Year", "Quarter", "Arrival country", "Vat rate", "Amount", "Vat"],
          ["year", "quarter", "arrival_country", "vat_percent", "amount", "vat"],
          correctedGoodsRC,
          "correctedGoodsRC",
          correctedGoodsRCAdding,
          "correctedGoodsRCAdding",
          "sumGoodsRCCorrected",
          "vatGoodsRCCorrected"
        );
      case 5:
        return this.stepWithAdding(
          [
            "Year",
            "Quarter",
            "Departure country",
            "Arrival country",
            "Vat rate",
            "Amount",
            "Vat",
          ],
          [
            "year",
            "quarter",
            "departure_country",
            "arrival_country",
            "vat_percent",
            "amount",
            "vat",
          ],
          correctedGoodsOther,
          "correctedGoodsOther",
          correctedGoodsOtherAdding,
          "correctedGoodsOtherAdding",
          "sumGoodsOtherCorrected",
          "vatGoodsOtherCorrected"
        );
      case 6:
        return this.stepWithAdding(
          ["Year", "Quarter", "Arrival country", "Vat rate", "Amount", "Vat"],
          ["year", "quarter", "arrival_country", "vat_percent", "amount", "vat"],
          correctedServices,
          "correctedServices",
          correctedServicesAdding,
          "correctedServicesAdding",
          "sumServicesCorrected",
          "vatServicesCorrected"
        );
      case 7:
        return this.stepPreview();
      default:
        return "";
    }
  }

  getStepContentForOMP(stepIndex) {
    const {
      dataGoodsRC,
      dataGoodsOther,
      dataGoodsOtherAdding,
      dataServices,
      correctedGoodsRC,
      correctedGoodsRCAdding,
      correctedGoodsOther,
      correctedGoodsOtherAdding,
      correctedServices,
      correctedServicesAdding,
      dataGoodsRCDeemed,
      dataGoodsOtherDeemed,
      dataGoodsOtherDeemedAdding,
      dataServicesDeemed,
    } = this.state;
    switch (stepIndex) {
      case 1:
        return this.stepAllVatRates(
          "sales_of_goods_from_rc",
          dataGoodsRC,
          "dataGoodsRC"
        );
      case 2:
        return this.stepWithAdding(
          ["Departure country", "Arrival country", "Vat rate", "Amount", "Vat"],
          [
            "departure_country",
            "arrival_country",
            "vat_percent",
            "amount",
            "vat",
          ],
          dataGoodsOther,
          "dataGoodsOther",
          dataGoodsOtherAdding,
          "dataGoodsOtherAdding",
          "sumGoodsOther",
          "vatGoodsOther"
        );
      case 3:
        return this.stepAllVatRates(
          "sales_of_services_oss",
          dataServices,
          "dataServices"
        );
      case 4:
        return this.stepAllVatRates(
          "sales_of_goods_from_rc_deemed",
          dataGoodsRCDeemed,
          "dataGoodsRCDeemed"
        );
      case 5:
        return this.stepWithAdding(
          ["Departure country", "Arrival country", "Vat rate", "Amount", "Vat"],
          [
            "departure_country",
            "arrival_country",
            "vat_percent",
            "amount",
            "vat",
          ],
          dataGoodsOtherDeemed,
          "dataGoodsOtherDeemed",
          dataGoodsOtherDeemedAdding,
          "dataGoodsOtherDeemedAdding",
          "sumGoodsOtherDeemed",
          "vatGoodsOtherDeemed"
        );
      case 6:
        return this.stepAllVatRates(
          "sales_of_services_deemed",
          dataServicesDeemed,
          "dataServicesDeemed"
        );
      case 7:
        return this.stepWithAdding(
          ["Year", "Quarter", "Arrival country", "Vat rate", "Amount", "Vat"],
          ["year", "quarter", "arrival_country", "vat_percent", "amount", "vat"],
          correctedGoodsRC,
          "correctedGoodsRC",
          correctedGoodsRCAdding,
          "correctedGoodsRCAdding",
          "sumGoodsRCCorrected",
          "vatGoodsRCCorrected"
        );
      case 8:
        return this.stepWithAdding(
          [
            "Year",
            "Quarter",
            "Departure country",
            "Arrival country",
            "Vat rate",
            "Amount",
            "Vat",
          ],
          [
            "year",
            "quarter",
            "departure_country",
            "arrival_country",
            "vat_percent",
            "amount",
            "vat",
          ],
          correctedGoodsOther,
          "correctedGoodsOther",
          correctedGoodsOtherAdding,
          "correctedGoodsOtherAdding",
          "sumGoodsOtherCorrected",
          "vatGoodsOtherCorrected"
        );
      case 9:
        return this.stepWithAdding(
          ["Year", "Quarter", "Arrival country", "Vat rate", "Amount", "Vat"],
          ["year", "quarter", "arrival_country", "vat_percent", "amount", "vat"],
          correctedServices,
          "correctedServices",
          correctedServicesAdding,
          "correctedServicesAdding",
          "sumServicesCorrected",
          "vatServicesCorrected"
        );
      case 10:
        return this.stepPreview();
      default:
        return "";
    }
  }

  getFilteredData = (data) => {
    const filteredData = [...data].filter((item) => item.summ);
    /*const filteredData = {...data};
        for (const key in filteredData) {
          if (data.hasOwnProperty(key)) {
            filteredData[key] = filteredData[key].filter(Boolean).filter(item => item.total_sum !== 0);
            if (_.isEmpty(filteredData[key])) {
              delete filteredData[key];
            }
          }
        }*/
    return filteredData;
  };

  getPreviewData = () => {
    const { user /*, returnData*/ } = this.props;
    const {
      dataGoodsRC,
      dataGoodsRCDeemed,
      dataGoodsOther,
      dataGoodsOtherDeemed,
      dataServices,
      dataServicesDeemed,
      correctedGoodsRC,
      correctedGoodsOther,
      correctedServices,
    } = this.state;

    const data = [];
    const countries = this.getAvailibleCountries();

    countries.map((country, ind) => {
      data[ind] = {};
      data[ind].country_code = country.value;
      data[ind].country_name = country.label;
      data[ind].sales_of_goods_from_rc = dataGoodsRC.filter(
        (item) => item.summ && item.arrival_country === country.value
      );
      data[ind].sales_of_goods_from_rc_corrected = correctedGoodsRC.filter(
        (item) => item.summ && item.arrival_country === country.value
      );
      data[ind].sales_of_goods_from_other = dataGoodsOther.filter(
        (item) => item.summ && item.arrival_country === country.value
      );
      data[ind].sales_of_goods_from_other_corrected =
        correctedGoodsOther.filter(
          (item) => item.summ && item.arrival_country === country.value
        );
      data[ind].sales_of_services_oss = dataServices.filter(
        (item) => item.summ && item.arrival_country === country.value
      );
      data[ind].sales_of_services_corrected = correctedServices.filter(
        (item) => item.summ && item.arrival_country === country.value
      );

      if (user.is_omp) {
        data[ind].sales_of_goods_from_rc_deemed = dataGoodsRCDeemed.filter(
          (item) => item.summ && item.arrival_country === country.value
        );
        data[ind].sales_of_goods_from_other_deemed =
          dataGoodsOtherDeemed.filter(
            (item) => item.summ && item.arrival_country === country.value
          );
        data[ind].sales_of_services_deemed = dataServicesDeemed.filter(
          (item) => item.summ && item.arrival_country === country.value
        );
      }
      return null;
    });

    return data;
  };

  getAvailibleCountries = () => {
    const { returnData } = this.props;
    const countries = [
      {
        value: "",
        label: "",
      },
      ...Object.keys(returnData.dict_of_countries).map((code) => ({
        value: code,
        label: returnData.dict_of_countries[code],
      })),
    ];
    return _.sortBy(countries, (country) => country.value);
  };

  styles = {
    tableCell: {
      padding: "1.5rem 0 1.5rem 2rem",
      overflow: "visible",
      width: "20%",
    },
  };

  doDeleteRow = (key, index, keySum, keyVat) => {
    this.setState(
      (prevState) => {
        const data = [...prevState[key]];
        data.splice(index, 1);
        return {
          [key]: data,
        };
      },
      () => {
        const data = [...this.state[key]];
        data.splice(index, 1);
        this.updateSummary(data, keySum, keyVat);
      }
    );
  };

  handleNext = () => {
    this.setState((prevState) => ({
      stepIndex: prevState.stepIndex + 1,
    }));
  };

  handlePrev = () => {
    this.setState((prevState) => {
      if (prevState.stepIndex > 1) {
        return {
          stepIndex: prevState.stepIndex - 1,
        };
      }
    });
  };

  handleActionDialogsOpen = (name, data = {}) => {
    const { actionDialogs } = this.state;
    actionDialogs[name].open = true;
    actionDialogs.cellData = data;
    this.setState({ actionDialogs });
  };

  handleActionDialogsClose = (name) => {
    const { actionDialogs } = this.state;
    actionDialogs[name].open = false;
    actionDialogs.cellData = {};
    this.setState({ actionDialogs });
  };

  updateSummary = (data, keySum, keyVat) => {
    if (!_.isEmpty(data)) {
      const sum = data
        .map((obj) => obj.summ)
        .reduce((prev, current) => prev + current, 0);

      const vat = data
        .map((obj) => (obj.summ * obj.vat_percent) / 100)
        .reduce((prev, current) => prev + current, 0);

      this.setState({
        [keySum]: sum,
        [keyVat]: vat,
      });
    }
  };

  updateInput = (keyData, id, vatPercent, value) => {
    const data = this.state[keyData].map((item, index) =>
      index === id ? { ...item } : item
    );
    
    if (!data[id]) {
      return;
    }

    data[id].summ = Number(value) || 0;
    data[id].vat_percent = Number(vatPercent) || 0;
    data[id].vat = (data[id].summ * data[id].vat_percent) / 100;
    
    switch (keyData) {
      case "dataGoodsRC":
        this.updateSummary(data, "sumGoodsRC", "vatGoodsRC");
        break;
      case "dataGoodsRCDeemed":
        this.updateSummary(data, "sumGoodsRCDeemed", "vatGoodsRCDeemed");
        break;
      /*case 'dataGoodsOther':
            this.updateSummary(data, 'sumGoodsOther', 'vatGoodsOther');
            break;*/
      case "dataGoodsOtherDeemed":
        this.updateSummary(data, "sumGoodsOtherDeemed", "vatGoodsOtherDeemed");
        break;
      case "dataServices":
        this.updateSummary(data, "sumServices", "vatServices");
        break;
      case "dataServicesDeemed":
        this.updateSummary(data, "sumServicesDeemed", "vatServicesDeemed");
        break;
      /*case 'correctedGoodsRC':
            this.updateSummary(data, 'sumGoodsRCCorrected', 'vatGoodsRCCorrected');
            break;
          case 'correctedGoodsOther':
            this.updateSummary(data, 'sumGoodsOtherCorrected', 'vatGoodsOtherCorrected');
            break;
          case 'correctedServices':
            this.updateSummary(data, 'sumServicesCorrected', 'vatServicesCorrected');
            break;*/
      default:
        break;
    }
    
    // console.log('data ----', data)
    this.setState({
      [keyData]: data,
    });
  };

  numberInput = (
    data,
    keyData,
    id,
    country,
    name,
    value,
    suffix,
    vatPercent,
    onChangeFunc
  ) => {
    const uniqueKey = id + country + name;

    const initValue =
      data[keyData] && data[keyData][id]
        ? data[keyData][id].summ * data[keyData][id].vat_percent
        : value * vatPercent;

    const isSummReserve = data[id] && data[id].summ_reserve;

    return (
      <TextField
        id={uniqueKey}
        key={uniqueKey}
        name={uniqueKey}
        type='number'
        step={0.01}
        suffix={"  " + suffix}
        disabled={name !== "summ" || isSummReserve}
        value={
          name === "summ" || name === "vat_percent"
            ? `${preciseRound(value)}`
            : `${preciseRound(initValue)}`
        }
        placeholder='0.00'
        onChange={(v) => {
          if (name === "summ") {
            onChangeFunc(keyData, id, vatPercent, +v);
          }
        }}
      />
    );
  };

  downloadFile(link) {
    const from = link.indexOf("/") + 1;
    const fileName = link.substring(from);
    const a = document.createElement("a");
    a.setAttribute("href", link);
    a.setAttribute("download", fileName);
    a.style.display = "none";

    document.body.appendChild(a);
    a.click();

    window.URL.revokeObjectURL(link);
    document.body.removeChild(a);
  }

  fillReturn = (finalType) => {
    const { user, returnParams } = this.props;
    const {
      dataGoodsRC,
      dataGoodsRCDeemed,
      dataGoodsOther,
      dataGoodsOtherDeemed,
      dataServices,
      dataServicesDeemed,
      correctedGoodsRC,
      correctedGoodsOther,
      correctedServices,
    } = this.state;

    const params = {
      ...returnParams,
      sales_of_goods_from_rc: this.getFilteredData(dataGoodsRC),
      sales_of_goods_from_other: this.getFilteredData(dataGoodsOther),
      sales_of_services_oss: this.getFilteredData(dataServices),
      sales_of_goods_from_rc_corrected: this.getFilteredData(correctedGoodsRC),
      sales_of_goods_from_other_corrected:
        this.getFilteredData(correctedGoodsOther),
      sales_of_services_corrected: this.getFilteredData(correctedServices),
    };

    if (user.is_omp) {
      params.sales_of_goods_from_rc_deemed =
        this.getFilteredData(dataGoodsRCDeemed);
      params.sales_of_goods_from_other_deemed =
        this.getFilteredData(dataGoodsOtherDeemed);
      params.sales_of_services_deemed =
        this.getFilteredData(dataServicesDeemed);
    }
   
    if (finalType) {
      params[finalType] = true;
    }

    this.props
      .createDeclaration(params)
      .then((result) => {
        if (finalType) {
          this.setState({
            addingResult: result || true,
            addingError: null,
          });
          if (finalType === "report") {
            this.downloadFile(result);
          } else {
            this.handleActionDialogsOpen("created", { finalType });
          }
        } else {
          this.setState({
            previewResult: result,
            addingError: null,
          });
          this.handleNext();
        }
      })
      .catch((result) => {
        this.handleActionDialogsOpen("created", {});
        this.setState({
          addingError: result,
        });
      });
  };

  stepAllVatRates(key, data, name) {
    const { returnData } = this.props;

    return (
      <div>
        {this.renderSummary()}
        <Card sectioned>
          <FormLayout>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "end",
              }}
            >
              <div style={{ width: "20%" }}>
                <Text as="h6" variant="bodyMd"  fontWeight="bold">{"Arrival country"}</Text>
              </div>
              <div style={{ width: "15%" }}>
                <Text as="h6" variant="bodyMd"  fontWeight="bold">
                  Vat rate
                </Text>
              </div>
              <div style={{ width: "30%" }}>
                <Text as="h6" variant="bodyMd"  fontWeight="bold">
                  {"Amount"} ({returnData.currency})
                </Text>
              </div>
              <div style={{ width: "30%" }}>
                <Text as="h6" variant="bodyMd"  fontWeight="bold">{"Vat"}</Text>
              </div>
            </div>

            {returnData.data[key].map((item, ind) => {
              const initTotalSum = data[ind].summ;
              const initVatSum = (data[ind].summ * data[ind].vat_percent) / 100;

              return (
                <div
                  key={`${ind}row`}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "start",
                    marginBottom: "1rem",
                  }}
                >
                  <div style={{ width: "20%" }}>
                    {(ind === 0 ||
                      returnData.data[key][ind - 1].arrival_country !==
                        item.arrival_country) && (
                      <Text variant="bodyMd" color="critical">
                        {item.arrival_country}
                      </Text>
                    )}
                  </div>

                  <div style={{ width: "15%" }}>
                    {this.numberInput(
                      data,
                      name,
                      ind,
                      item.arrival_country,
                      "vat_percent",
                      item.vat_percent,
                      "%"
                    )}
                  </div>

                  <div style={{ width: "30%" }}>
                    {this.numberInput(
                      data,
                      name,
                      ind,
                      item.arrival_country,
                      "summ",
                      initTotalSum || initTotalSum === 0
                        ? initTotalSum
                        : item.summ,
                      returnData.currency,
                      item.vat_percent,
                      this.updateInput
                    )}
                  </div>

                  <div style={{ width: "30%" }}>
                    {this.numberInput(
                      data,
                      name,
                      ind,
                      item.arrival_country,
                      "vat_percent",
                      initVatSum || initVatSum === 0 ? initVatSum : item.vat,
                      returnData.currency
                    )}
                  </div>
                </div>
              );
            })}
          </FormLayout>
        </Card>
      </div>
    );
  }

  stepWithAdding = (
    titles,
    fields,
    data,
    dataKey,
    dataAdding,
    dataAddingKey,
    keySum,
    keyVat
  ) => {
    const { returnData, t } = this.props;

    const columns = [
      {
        property: "departure_country",
        header: {
          label: "Departure country",
        },
        cell: {
          formatters: [(value) => <>{returnData.dict_of_countries[value]}</>],
        },
      },
      {
        property: "arrival_country",
        header: {
          label: "Arrival country",
        },
        cell: {
          formatters: [(value) => <>{returnData.dict_of_countries[value]}</>],
        },
      },
      {
        property: "vat_percent",
        header: {
          label: "Vat rate",
        },
      },
      {
        property: "summ",
        header: {
          label: "Amount",
        },
      },
      {
        property: "vat",
        header: {
          label: "Vat",
        },
      },
    ];

    if (fields.find((field) => field === "quarter")) {
      columns.unshift({
        property: "quarter",
        header: {
          label: "Quarter",
        },
      });
    }

    if (fields.find((field) => field === "year")) {
      columns.unshift({
        property: "year",
        header: {
          label: "Year",
        },
      });
    }

    const rows = formatRow(data, columns);

    return (
      <React.Fragment>
        {this.renderSummary()}
        <Card>
          <DataTable
            columnContentTypes={["text", "text", "text", "text", "text"]}
            headings={columns.map(({ header }) => (
              <Text fontWeight='semibold' variant={'bodyMd'}>{header.label}</Text>
            ))}
            rows={rows}
            hideScrollIndicator
          />
        </Card>
        <Card sectioned>
          <form>
            <FormLayout>
              <FormLayout.Group>
                {fields.some((field) => field === "year") && (
                  <Select
                    name='year'
                    label={"Year"}
                    options={[
                      "",
                      moment().year() - 2,
                      moment().year() - 1,
                      moment().year(),
                      moment().year() + 1,
                      moment().year() + 2,
                    ].map((item) => ({
                      value: item.toString(),
                      label: item.toString(),
                    }))}
                    value={dataAdding.year || ""}
                    onChange={(value) =>
                      this.setState((prevState) => ({
                        [dataAddingKey]: {
                          ...prevState[dataAddingKey],
                          year: value,
                        },
                      }))
                    }
                  />
                )}
                {fields.some((field) => field === "quarter") && (
                  <Select
                    name='quarter'
                    label={"Quarter"}
                    options={["", 1, 2, 3, 4].map((item) => ({
                      value: item.toString(),
                      label: item ? `${item} quarter` : "",
                    }))}
                    value={dataAdding.quarter || ""}
                    onChange={(value) =>
                      this.setState((prevState) => ({
                        [dataAddingKey]: {
                          ...prevState[dataAddingKey],
                          quarter: value,
                        },
                      }))
                    }
                  />
                )}
                {fields.some((field) => field === "departure_country") && (
                  <Select
                    name='dep_country'
                    label={"Departure country"}
                    options={this.getAvailibleCountries()}
                    value={dataAdding.departure_country || ""}
                    onChange={(value) =>
                      this.setState((prevState) => ({
                        [dataAddingKey]: {
                          ...prevState[dataAddingKey],
                          departure_country: value,
                        },
                      }))
                    }
                  />
                )}
                {fields.some((field) => field === "arrival_country") && (
                  <Select
                    name='arr_country'
                    label={"Arrival country"}
                    options={this.getAvailibleCountries()}
                    value={dataAdding.arrival_country || ""}
                    onChange={(value) =>
                      this.setState((prevState) => ({
                        [dataAddingKey]: {
                          ...prevState[dataAddingKey],
                          arrival_country: value,
                        },
                      }))
                    }
                  />
                )}
                {fields.some((field) => field === "vat_percent") && (
                  <Select
                    name='vat_rate'
                    label={"Vat rate"}
                    disabled={!dataAdding.arrival_country}
                    options={
                      dataAdding.arrival_country
                        ? [
                            {
                              value: "",
                              label: "",
                            },
                            ...returnData.vat_rates.goods[
                              dataAdding.arrival_country
                            ].map((vatRate) => ({
                              value: vatRate.toString(),
                              label: vatRate,
                            })),
                          ]
                        : []
                    }
                    value={
                      dataAdding.vat_percent
                        ? dataAdding.vat_percent.toString()
                        : ""
                    }
                    onChange={(value) =>
                      this.setState((prevState) => ({
                        [dataAddingKey]: {
                          ...prevState[dataAddingKey],
                          vat_percent: +value,
                        },
                      }))
                    }
                  />
                )}
                {fields.some((field) => field === "summ") && (
                  <TextField
                    name='summ'
                    label={"Amount"}
                    type='number'
                    disabled={!dataAdding.vat_percent}
                    step={0.01}
                    suffix={"  " + returnData.currency}
                    value={dataAdding.summ ? dataAdding.summ.toString() : ""}
                    placeholder='0.00'
                    onChange={(value) =>
                      this.setState((prevState) => ({
                        [dataAddingKey]: {
                          ...prevState[dataAddingKey],
                          summ: +value,
                          vat: preciseRound(
                            value * (prevState[dataAddingKey].vat_percent / 100)
                          ),
                        },
                      }))
                    }
                  />
                )}
                {fields.some((field) => field === "vat") && (
                  <TextField
                    name='vat'
                    label={"Vat"}
                    type='number'
                    step={0.01}
                    suffix={"  " + returnData.currency}
                    value={dataAdding.vat ? dataAdding.vat.toString() : ""}
                    disabled
                  />
                )}
                <br />
              </FormLayout.Group>
            </FormLayout>
            <br />
            <Button
              disabled={!fields.every((field) => dataAdding[field])}
              variant={'primary'}
              size={'micro'}
              onClick={() => {
                this.setState((prevState) => ({
                  [dataKey]: [...prevState[dataKey], dataAdding],
                  [dataAddingKey]: {},
                }));
                this.updateSummary([...data, dataAdding], keySum, keyVat);
              }}
            >
              {t("createReturns.addRecord")}
            </Button>
          </form>
        </Card>
      </React.Fragment>
    );
  };

  stepPreview() {
    const { creating, t } = this.props;

    return (
      <div>
        {this.renderSummary()}

        <SaveBar title={t("createReturns.createVR")}>
          <ButtonGroup>
            <Button  size={'micro'} onClick={() => this.handlePrev()}>
              <img src={ArrowLeftBack} alt='arrow' style={{width: 10, height:10 , marginRight:8}}/> {t("createReturns.back")}
            </Button>
            <Button
              size={'micro'}
              variant={'primary'}
              disabled={creating}
              onClick={() => this.fillReturn("submit")}
            >
              {"Submit"}
            </Button>
            <Button
              size={'micro'}
              variant={'primary'}
              disabled={creating}
              onClick={() => this.fillReturn("save")}
            >
              {"Save"}
            </Button>
            <Button
              size={'micro'}
              variant={'primary'}
              disabled={creating}
              onClick={() => this.fillReturn("report")}
            >
              {"Download report"}
            </Button>
          </ButtonGroup>
        </SaveBar>
      </div>
    );
  }

  renderResetDialog() {
    const { actionDialogs } = this.state;
    const { t } = this.props;

    return (
      <Modal
        title={t("createReturns.warning")}
        visible={actionDialogs.reset.open}
        onClose={() => this.handleActionDialogsClose("reset")}
        contentOnCenter
        iconType={"warning"}
        description={
          <>
            <p>{t("createReturns.willLose")}</p>
            <p>{t("createReturns.wantComeBack")}</p>
          </>
        }
        footer={
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              onClick={() => {
                this.props.reset();
                this.handleActionDialogsClose("reset");
              }}
            >
              {t("createReturns.toTheFirst")}
            </Button>
          </div>
        }
      />
    );
  }

  renderCreatedReturnDialog() {
    return (
      <RenderCreatedReturnDialog
        state={this.state}
        declarationType={this.props.title}
        handleActionDialogsClose={this.handleActionDialogsClose}
      />
    );
  }

  renderSummary = () => {
    const { user, returnData } = this.props;
    const {
      sumGoodsRC,
      vatGoodsRC,
      sumGoodsRCDeemed,
      vatGoodsRCDeemed,
      sumGoodsOther,
      vatGoodsOther,
      sumGoodsOtherDeemed,
      vatGoodsOtherDeemed,
      sumServices,
      vatServices,
      sumServicesDeemed,
      vatServicesDeemed,
      sumGoodsRCCorrected,
      vatGoodsRCCorrected,
      sumGoodsOtherCorrected,
      vatGoodsOtherCorrected,
      sumServicesCorrected,
      vatServicesCorrected,
      stepIndex,
    } = this.state;

    const lastStep = user.is_omp ? 10 : 7;
   
    return (
      <UnionOSSPreviewNew
        sumGoodsRC={sumGoodsRC || returnData.sales_of_goods_from_rc}
        vatGoodsRC={vatGoodsRC || returnData.vat_of_goods_from_rc}
        sumGoodsRCDeemed={
          sumGoodsRCDeemed || returnData.sales_of_goods_from_rc_deemed
        }
        vatGoodsRCDeemed={
          vatGoodsRCDeemed || returnData.vat_of_goods_from_rc_deemed
        }
        sumGoodsOther={sumGoodsOther || returnData.sales_of_goods_from_other}
        vatGoodsOther={vatGoodsOther || returnData.vat_of_goods_from_other}
        sumGoodsOtherDeemed={
          sumGoodsOtherDeemed || returnData.sales_of_goods_from_other_deemed
        }
        vatGoodsOtherDeemed={
          vatGoodsOtherDeemed || returnData.vat_of_goods_from_other_deemed
        }
        sumServices={sumServices || returnData.sales_of_services_oss}
        vatServices={vatServices || returnData.vat_of_services_oss}
        sumServicesDeemed={
          sumServicesDeemed || returnData.sales_of_services_deemed
        }
        vatServicesDeemed={
          vatServicesDeemed || returnData.vat_of_services_deemed
        }
        sumGoodsRCCorrected={
          sumGoodsRCCorrected || returnData.sales_of_goods_from_rc_corrected
        }
        vatGoodsRCCorrected={
          vatGoodsRCCorrected || returnData.vat_of_goods_from_rc_corrected
        }
        sumGoodsOtherCorrected={
          sumGoodsOtherCorrected ||
          returnData.sales_of_goods_from_other_corrected
        }
        vatGoodsOtherCorrected={
          vatGoodsOtherCorrected || returnData.vat_of_goods_from_other_corrected
        }
        sumServicesCorrected={
          sumServicesCorrected || returnData.sales_of_services_corrected
        }
        vatServicesCorrected={
          vatServicesCorrected || returnData.vat_of_services_corrected
        }
        currency={returnData.currency}
        step={stepIndex}
        showDeemed={user.is_omp}
        data={
          stepIndex === lastStep
            ? this.getPreviewData()
            : null /*previewResult ? previewResult.data : []*/
        }
        dictOfCountries={returnData.dict_of_countries}
      />
    );
  };

  render() {
    const { creating, user, t, title, countryName } = this.props;
    const { stepIndex } = this.state;

    const steps = [
      {
        title: t("createReturns.returnParams"),
        icon: step1IMG,
      },
      {
        title: `Sales of goods from ${countryName}`,
        icon: step1IMG,
      },
      {
        title: "Sales of goods from other countries",
        icon: step1IMG,
      },
      {
        title: "Sales of services",
        icon: step1IMG,
      },
      {
        title: `Sales of goods from ${countryName} corrected`,
        icon: step1IMG,
      },
      {
        title: "Sales of goods from other countries corrected",
        icon: step1IMG,
      },
      {
        title: "Sales of services corrected",
        icon: step1IMG,
      },
      {
        title: "Summary",
        icon: step1IMG,
      },
    ];

    if (user.is_omp) {
      steps.splice(
        4,
        0,
        {
          title: `Sales of goods from ${countryName} deemed`,
          icon: step1IMG,
        },
        {
          title: "Sales of goods from other countries deemed",
          icon: step1IMG,
        },
        {
          title: "Sales of services deemed",
          icon: step1IMG,
        }
      );
    }

    const lastStep = user.is_omp ? 10 : 7;

    return (
      <div style={{marginTop: 32}}>
        <Layout>
          <div style={{display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', width: '100%', padding: '0 0 0 30px'}}>
            {this.state.stepIndex > 0 && (
              <Stepper
                steps={steps}
                activeStep={stepIndex}
                titleFontSize={14}
                activeColor={'#E4F3FE'}
                completeColor={'#216DC5'}
                circleFontColor={'#212B36'}
                defaultTitleColor={'rgba(0, 0, 0, 1)'}
                completeTitleColor={'rgba(0, 0, 0, 1)'}
                activeTitleColor={'#216DC5'}
                completeBorderColor={"#367C41"}
                defaultBorderWidth={2}
                defaultBarColor={'#ADADAD'}
                size={24}
                circleFontSize={14}
              />
            )}
              <div style={{width: '70%', marginBottom: 102}}>
                <Text variant='bodyMd'>{title}</Text>
                <br />
                {user.is_omp
                  ? this.getStepContentForOMP(stepIndex)
                  : this.getStepContent(stepIndex)}
              </div>
            {stepIndex < lastStep && (
              <SaveBar title={t("createReturns.createVR")}>
              <ButtonGroup>
                <Button
                  size={'micro'}
                  disabled={creating}
                  onClick={
                    stepIndex === 1
                      ? () => this.handleActionDialogsOpen("reset", {})
                      : this.handlePrev
                  }
                >
                  <img src={ArrowLeftBack} alt='arrow' style={{width: 10, height:10 , marginRight:8}}/> {t("createReturns.back")}
                </Button>
                <Button  size={'micro'} variant={'primary'} loading={creating} onClick={this.handleNext}>
                  {t("createReturns.next")}<img src={ArrowRight} alt='arrow' style={{width: 10, height:10 , marginLeft:8}}/>
                </Button>
              </ButtonGroup>
            </SaveBar>
            )}
          </div>
        </Layout>

        {this.renderResetDialog()}
        {this.renderCreatedReturnDialog()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  defaultLanguage: state.user.defaultLanguage,
  creating: state.declarations.creating,
});

const mapDispatchToProps = (defaultDispatch) => {
  const dispatch = createCustomDispatch(defaultDispatch);

  return {
    createDeclaration: (params) => dispatch(createDeclaration(params)),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(UnionOSSReturn))
);
