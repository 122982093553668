import React from "react";

const ReportsIcon = ({ selected }) => {
  if (selected) {
    return (
      <svg
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M12.7011 0.833496H7.29892C6.62812 0.833486 6.07447 0.833478 5.62348 0.870325C5.15507 0.908596 4.72448 0.990733 4.32003 1.19681C3.69283 1.51639 3.18289 2.02632 2.86331 2.65353C2.65724 3.05798 2.5751 3.48856 2.53683 3.95698C2.49998 4.40797 2.49999 4.9616 2.5 5.6324V14.3679C2.49999 15.0387 2.49998 15.5924 2.53683 16.0433C2.5751 16.5118 2.65724 16.9424 2.86331 17.3468C3.18289 17.974 3.69283 18.4839 4.32003 18.8035C4.72448 19.0096 5.15507 19.0917 5.62348 19.13C6.07447 19.1668 6.6281 19.1668 7.2989 19.1668H12.7011C13.3719 19.1668 13.9255 19.1668 14.3765 19.13C14.8449 19.0917 15.2755 19.0096 15.68 18.8035C16.3072 18.4839 16.8171 17.974 17.1367 17.3468C17.3428 16.9424 17.4249 16.5118 17.4632 16.0433C17.5 15.5924 17.5 15.0387 17.5 14.3679V5.63239C17.5 4.96159 17.5 4.40797 17.4632 3.95698C17.4249 3.48856 17.3428 3.05798 17.1367 2.65353C16.8171 2.02632 16.3072 1.51639 15.68 1.19681C15.2755 0.990733 14.8449 0.908596 14.3765 0.870325C13.9255 0.833478 13.3719 0.833486 12.7011 0.833496ZM6.66667 5.00016C6.20643 5.00016 5.83333 5.37326 5.83333 5.8335C5.83333 6.29373 6.20643 6.66683 6.66667 6.66683H13.3333C13.7936 6.66683 14.1667 6.29373 14.1667 5.8335C14.1667 5.37326 13.7936 5.00016 13.3333 5.00016H6.66667ZM5.83333 9.16683C5.83333 8.70659 6.20643 8.3335 6.66667 8.3335H11.6667C12.1269 8.3335 12.5 8.70659 12.5 9.16683C12.5 9.62707 12.1269 10.0002 11.6667 10.0002H6.66667C6.20643 10.0002 5.83333 9.62707 5.83333 9.16683ZM5.83333 12.5002C5.83333 12.0399 6.20643 11.6668 6.66667 11.6668H8.33333C8.79357 11.6668 9.16667 12.0399 9.16667 12.5002C9.16667 12.9604 8.79357 13.3335 8.33333 13.3335H6.66667C6.20643 13.3335 5.83333 12.9604 5.83333 12.5002Z'
          fill='rgba(38, 127, 215, 1)'
        />
      </svg>
    );
  }
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        opacity='0.12'
        d='M3.3335 5.66699C3.3335 4.26686 3.3335 3.5668 3.60598 3.03202C3.84566 2.56161 4.22811 2.17916 4.69852 1.93948C5.2333 1.66699 5.93336 1.66699 7.3335 1.66699H12.6668C14.067 1.66699 14.767 1.66699 15.3018 1.93948C15.7722 2.17916 16.1547 2.56161 16.3943 3.03202C16.6668 3.5668 16.6668 4.26686 16.6668 5.66699V14.3337C16.6668 15.7338 16.6668 16.4339 16.3943 16.9686C16.1547 17.439 15.7722 17.8215 15.3018 18.0612C14.767 18.3337 14.067 18.3337 12.6668 18.3337H7.3335C5.93336 18.3337 5.2333 18.3337 4.69852 18.0612C4.22811 17.8215 3.84566 17.439 3.60598 16.9686C3.3335 16.4339 3.3335 15.7338 3.3335 14.3337V5.66699Z'
        fill='#2E2E2E'
      />
      <path
        d='M11.6668 9.16699H6.66683M8.3335 12.5003H6.66683M13.3335 5.83366H6.66683M16.6668 5.66699V14.3337C16.6668 15.7338 16.6668 16.4339 16.3943 16.9686C16.1547 17.439 15.7722 17.8215 15.3018 18.0612C14.767 18.3337 14.067 18.3337 12.6668 18.3337H7.3335C5.93336 18.3337 5.2333 18.3337 4.69852 18.0612C4.22811 17.8215 3.84566 17.439 3.60598 16.9686C3.3335 16.4339 3.3335 15.7338 3.3335 14.3337V5.66699C3.3335 4.26686 3.3335 3.5668 3.60598 3.03202C3.84566 2.56161 4.22811 2.17916 4.69852 1.93948C5.2333 1.66699 5.93336 1.66699 7.3335 1.66699H12.6668C14.067 1.66699 14.767 1.66699 15.3018 1.93948C15.7722 2.17916 16.1547 2.56161 16.3943 3.03202C16.6668 3.5668 16.6668 4.26686 16.6668 5.66699Z'
        stroke='#707070'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default ReportsIcon;
