import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import {
  Page,
  Card,
  Button,
  ButtonGroup,
  DataTable,
  Text,
  Tooltip,
  Filters,
  ChoiceList,
  Spinner,
} from "@shopify/polaris";
import { connect } from "react-redux";
import withRouter from "helpers/withRouter";
import BadgeCustom from '../../components/Badges/BadgeCustom'
import FilterCustom from '../../components/FilterCustom/FilterCustom'
import { setCompany } from "../../redux/features/manager/managerSlice";
import { deleteClient, fetchTaxListCompany } from "../../redux/features/tax/taxSlice";
import _ from "lodash";
import Modal from "components/Modal";
import NoDataMessage from "components/NoDataMessage/NoDataMessage";
import PageHelmet from "components/PageHelmet";
import formatRow from "utils/formatRow";

import deleteIMG from "img/delete.svg";
import { createCustomDispatch } from "helpers/customDispatch";

class Clients extends Component {
  constructor(props) {
    super(props);

    this.state = {
      queryValue: "",
      actionDialogs: {
        delete: { open: false },
      },
      selectedFilter: ["all"],
    };
  }

  componentDidMount() {
    this.props.fetchTaxListCompany();
  }

  setQueryValue = (value) => {
    this.setState({
      queryValue: value,
    });
  };

  clearQueryValue = () => {
    this.setState({
      queryValue: "",
    });
  };

  handleClearAll = () => {
    this.setState({
      queryValue: "",
      selectedFilter: ["all"],
    });
  };

  getStatus = (status) => {
    switch (status) {
      case "ban":
        return "critical";
      case "active":
        return "success";
      default:
        return "attention";
    }
  };

  doDeleteClient(id) {
    this.props
      .deleteClient(id)
      .then(() => {
        this.props.fetchTaxListCompany();
        this.handleActionDialogsClose("delete");
      })
      .catch(_.noop);
  }

  changeCompanyName = (id) => {
    this.props
      .setCompany({ company_id: id })
      .then(() => {
        this.props.navigate("/dashboard");
        window.location.reload();
      })
      .catch((result) => {
        this.setState({ changingError: result });
      });
  };

  handleActionDialogsOpen = (name, data = {}) => {
    const { actionDialogs } = this.state;
    actionDialogs[name].open = true;
    actionDialogs.cellData = data;
    this.setState({ actionDialogs });
  };

  handleActionDialogsClose = (name) => {
    const { actionDialogs } = this.state;
    actionDialogs[name].open = false;
    actionDialogs.cellData = {};
    this.setState({ actionDialogs });
  };

  handleChangeSelectedFilter = (filter) => {
    this.setState({
      selectedFilter: [filter],
    });
  };

  handleAddClient = () => {
    this.props.navigate("/clients/add-client");
  };

  icons = [
    <span>&#129418;</span>,
    <span>&#129436;</span>,
    <span>&#128035;</span>,
    <span>&#128057;</span>,
    <span>&#128048;</span>,
    <span>&#128047;</span>,
    <span>&#128056;</span>,
  ];

  renderDeleteActionDialog = () => {
    const { actionDialogs } = this.state;

    return !_.isEmpty(actionDialogs.cellData) ? (
      <Modal
        title={`Delete`}
        visible={actionDialogs.delete.open}
        iconType={"danger"}
        onClose={this.handleActionDialogsClose.bind(null, "delete")}
        description={
          <p>
            Are you sure you want to delete the client{" "}
            {actionDialogs.cellData.name}?
          </p>
        }
        footer={
          <ButtonGroup fullWidth>
            <Button
              onClick={this.handleActionDialogsClose.bind(null, "delete")}
              size='large'
            >
              Close
            </Button>
            <Button
              tone={'critical'}
              variant={'plain'}
              onClick={() => this.doDeleteClient(actionDialogs.cellData.id)}
              size='large'
            >
              Delete
            </Button>
          </ButtonGroup>
        }
      />
    ) : undefined;
  };

  render() {
    const { taxListCompany, fetchingListCompany, t } = this.props;
    const { selectedFilter, queryValue } = this.state;
    const r = !_.isEmpty(taxListCompany)
      ? taxListCompany.companies.filter(
          (company) => company.id !== taxListCompany.tax_agent_company_id
        )
      : [];

    const columns = [
      {
        property: "",
        header: {
          label: "",
        },
        cell: {
          formatters: [
            (value, { rowData, index }) => {
              return this.icons[index % this.icons.length];
            },
          ],
        },
      },
      {
        property: "name",
        header: {
          label: "Company",
        },
        cell: {
          formatters: [
            (value, { rowData }) => (
              <Button variant={'plain'} onClick={() => this.changeCompanyName(rowData.id)}>
                {value}
              </Button>
            ),
          ],
        },
      },
      {
        property: "users",
        header: {
          label: "Assigned users",
        },
        cell: {
          formatters: [
            (users) =>
              users &&
              users
                .map((item) => `${item.first_name} ${item.last_name}`)
                .join(),
          ],
        },
      },
      {
        property: "company_status",
        header: {
          label: "Status",
        },
        cell: {
          formatters: [
            (value) => {
              return <BadgeCustom content={this.getStatus(value)}>{value}</BadgeCustom >
            },
          ],
        },
      },
      {
        property: "id",
        header: {
          label: "",
          transforms: [],
        },
        cell: {
          formatters: [
            (value, { rowData }) => (
              <div className='ButtonOnHover'>
                <Tooltip content='Delete'>
                  <img
                    src={deleteIMG}
                    alt='icon delete'
                    onClick={this.handleActionDialogsOpen.bind(
                      null,
                      "delete",
                      rowData
                    )}
                    style={{ cursor: "pointer" }}
                  />
                </Tooltip>
              </div>
            ),
          ],
        },
      },
    ];

    const rows = formatRow(r, columns);

    const filteredRows = rows.filter((row) => {
      const companyRow = row[1].props.children,
        assignedUsersRow = row[2].toString(),
        statusRow = row[3].props.children;

      let targetRow = [companyRow, assignedUsersRow, statusRow].join(",");
      switch (selectedFilter[0]) {
        case "company":
          targetRow = companyRow;
          break;
        case "assignedUsers":
          targetRow = assignedUsersRow;
          break;
        case "status":
          targetRow = statusRow;
          break;
        default:
          break;
      }
      
      return targetRow.toLowerCase().indexOf(queryValue.toLowerCase()) > -1;
    });
  
    const choices = [
      { label: "All", value: "all" },
      { label: "Company", value: "company" },
      { label: "Assigned users", value: "assignedUsers" },
      { label: "Status", value: "status" },
    ];

    return (
      <Page
        title={
          <Text variant='heading3xl' as='span'>
            My clients
          </Text>
        }
        primaryAction={{
          content: "Add client",
          onAction: this.handleAddClient,
        }}
        separator
        fullWidth
      >
        <PageHelmet title='Clients' />
       
        <FilterCustom
          choices={choices}
          selected={selectedFilter}
          queryValue={queryValue}
          // onChange={this.handleChangeSelectedFilter}
          onChange={this.setQueryValue}
          onQueryChange={this.setQueryValue}
          onQueryClear={this.clearQueryValue}
          onClearAll={this.handleClearAll}
        />
        
        <br />
        <Card sectioned={fetchingListCompany}>
          {fetchingListCompany ? (
            <Spinner />
          ) : (
            <>
              {!_.isEmpty(rows) ? (
                <>
                  <DataTable
                    columnContentTypes={[
                      "text",
                      "text",
                      "text",
                      "text",
                      "text",
                    ]}
                    headings={columns.map(({ header }) => (
                      <Text fontWeight='semibold'>{header.label}</Text>
                    ))}
                    rows={filteredRows}
                    hideScrollIndicator
                    increasedTableDensity
                  />
                  {_.isEmpty(filteredRows) && (
                    <NoDataMessage
                      title={t("messages.nothingFound")}
                      description={t("messages.tryToChange")}
                      style={{
                        height: "auto",
                        justifyContent: "start",
                        padding: "1rem",
                      }}
                    />
                  )}
                </>
              ) : (
                <NoDataMessage
                  title='There are no clients'
                  description={"To see clients, you need to add them first"}
                  buttonText={"Add clients"}
                  onClick={this.handleAddClient}
                />
              )}
            </>
          )}
        </Card>
        {this.renderDeleteActionDialog()}
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  taxListCompany: state.tax.taxListCompany,
  fetchingListCompany: state.manager.fetchingListCompany,
});

const mapDispatchToProps = (defaultDispatch) => {
  const dispatch = createCustomDispatch(defaultDispatch);

  return {
    setCompany: (params) => dispatch(setCompany(params)),
    deleteClient: (id) => dispatch(deleteClient(id)),
    fetchTaxListCompany: () => dispatch(fetchTaxListCompany()),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(Clients))
);
