import React, { Component } from "react";
import Accordion from "../Accordion/Accordion";
import {
  Button,
  ButtonGroup,
  Collapsible,
  Card,
  Text,
  Spinner,
  Box,
} from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import withRouter from "helpers/withRouter";
import _ from "lodash";
import CompletedIMG from "img/userGuide-complete.svg";
import SuccessIMG from "img/userGuide-success.svg";
import "./index.scss";
import {
  fetchUserGuide,
  skipStepUserGuide,
} from "../../redux/features/user/userSlice";
import { createCustomDispatch } from "helpers/customDispatch";

const PlusIcon = () => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M8 1V15M1 8H15'
      stroke='#2E2E2E'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

const MinusIcon = () => (
  <svg
    width='16'
    height='2'
    viewBox='0 0 16 2'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M1 1H15'
      stroke='#2E2E2E'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

class UserGuide extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPreviousTasksOpened: false,
    };
  }

  doSkipStep = (step) => {
    const { userGuide } = this.props;
    if (userGuide && userGuide.show_user_guide) {
      this.props
        .skipStepUserGuide(step)
        .then(() => {
          this.props.fetchUserGuide();
        })
        .catch(_.noop);
    }
  };

  componentDidMount() {
    this.props.fetchUserGuide().then((res) => {
      if (
        res.steps &&
        res.steps.every((step) => step.complete) &&
        res.show_user_guide
      ) {
        // after the user guide is completed we only show it once, then hide it with skipStepUserGuide = 5
        this.props.skipStepUserGuide(5);
      }
    });
  }

  renderDescription = (step, disabled) => {
    const { user, navigate, onLinksNavigate = _.noop } = this.props;

    const onLinksNavigateClick = (path) => {
      navigate(path);

      onLinksNavigate();
    };

    switch (step) {
      case 1:
        return (
          <Box padding="tight">
            <Text style={{ fontWeight: 'bold' }}>
              Use the English language to fill in your address and the name of
              the company. When you will fill in your VAT returns all info from
              here will go straight to VAT returns translated to a respective
              language.{" "}
            </Text>
            <Button
              size='slim'
              disabled={disabled}
              onClick={() => onLinksNavigateClick("/profile")}
            >
              Go to profile
            </Button>
          </Box>
        );
      case 2:
        return (
          <Box padding="tight">
            <Text style={{ fontWeight: 'bold' }}>
              If you sell at Amazon or Shopify or another Marketplace you can
              connect your store to your Marketplace account right away. If you
              have a custom website you will have a token to connect as you
              create a store. For each store, you can set up a domestic country.
              Domestic country means that for this country VAT is not calculated
              by our software.{" "}
            </Text>
            <Button
              size='slim'
              disabled={disabled}
              onClick={() => onLinksNavigateClick("/stores/add")}
            >
              Create store
            </Button>
          </Box>
        );
      case 3:
        return (
          <Box padding="tight">
            <Text style={{ fontWeight: 'bold' }}>
              This section is very important you can{" "}
              <Button onClick={() => onLinksNavigateClick("/help")} variant={'plain'}>
                <span style={{color: 'rgba(44, 110, 203, 1)'}}>watch video tutorials</span>
              </Button>{" "}
              or ask the onboarding team for support . Fill in at least one VAT
              number or sales tax permit to be able to file tax returns. Bear in
              mind that you need all your numbers filled in and activated before
              you upload any transactions. It influences tax calculations
              significantly.{" "}
            </Text>
            <ButtonGroup>
              <Button
                size='slim'
                disabled={disabled}
                onClick={() => onLinksNavigateClick("/tax-settings")}
              >
                Fill tax settings
              </Button>
              <Button
                size='slim'
                disabled={disabled || this.props.userGuide.progress >= 75}
                loading={this.props.skippingStep}
                variant={'primary'}
                onClick={() => this.doSkipStep(3)}
              >
                Skip
              </Button>
            </ButtonGroup>
          </Box>
        );
      case 4:
        return (
          <Box padding="tight">
            <Text style={{ fontWeight: 'bold' }}>There are four options to upload.</Text>
            <ul style={{ margin: "0" }}>
              <li>
                Integration with a marketplace - go to the transaction section
                and choose the button “upload transactions”. You can find guides
                for integration with Amazon, EBay or Shopify
              </li>
              <li>Manual uploading from excel spreadsheets</li>
              <li>
                API integration- normally you don’t need to upload transactions
                – they uploaded in a real-time manner
              </li>
              <li>Uploading via plugins for Magento, Woo-commerce</li>
            </ul>
            <Button
              size='slim'
              disabled={disabled}
              onClick={() =>
                onLinksNavigateClick(
                  user.company.is_active
                    ? "/transactions/import"
                    : "/transactions"
                )
              }
            >
              Upload transactions
            </Button>
          </Box>
        );
      default:
        return "no description";
    }
  };

  getTasksLeft = () => {
    const { userGuide } = this.props;

    return (
      userGuide?.steps?.reduce(
        (acc, curr) => (!curr.complete ? acc + 1 : acc),
        0
      ) || 0
    );
  };

  getMinutesLeft = () => {
    return this.getTasksLeft() * 5;
  };

  renderUserGuideItems = (items) => {
    return (
      <div>
        {items.map((step, ind) => {
          const stepCompleted = step.complete;
          return (
            <Accordion
              content={this.renderDescription(step.step, step.complete)}
              styles={{
                wrapper: {
                  borderRadius: 0,
                },
              }}
              renderCustomHeader={(open, handleToggle) => {
                return (
                  <>
                    <Box display="flex" alignItems="center" gap="5">
                      {open ? (
                        <div style={{ marginTop: -12 }}>
                          <MinusIcon />
                        </div>
                      ) : (
                        <PlusIcon />
                      )}
                      <Text fontWeight='bold' variant='bodyLg'>
                        <span
                          style={{
                            textDecoration: stepCompleted
                              ? "line-through"
                              : "none",
                          }}
                        >
                          {`${step.name}`}
                        </span>
                      </Text>
                    </Box>
                    {stepCompleted && (
                      <img src={CompletedIMG} alt='completed' />
                    )}
                  </>
                );
              }}
            />
          );
        })}
      </div>
    );
  };

  renderUncompletedUserGuideItems = () => {
    const { userGuide } = this.props;
    const uncompletedItems = userGuide.steps
      ? userGuide.steps.filter((item) => !item.complete)
      : [];

    return this.renderUserGuideItems(uncompletedItems);
  };

  renderCompletedUserGuideItems = () => {
    const { userGuide } = this.props;
    const { isPreviousTasksOpened: opened } = this.state;
    const completedItems = userGuide.steps
      ? userGuide.steps.filter((item) => item.complete)
      : [];

    return (
      completedItems.length > 0 && (
        <>
          <Button
            variant={'plain'}
            removeUnderline
            onClick={() => this.setState({ isPreviousTasksOpened: !opened })}
            icon={
              <svg
                width='21'
                height='20'
                viewBox='0 0 21 20'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
                style={{
                  transform: opened ? "rotate(180deg)" : "none",
                  marginTop: 3,
                }}
              >
                <path
                  d='M14.6666 7.64174C14.5104 7.48653 14.2992 7.39941 14.0791 7.39941C13.8589 7.39941 13.6477 7.48653 13.4916 7.64174L10.4999 10.5917L7.54992 7.64174C7.39378 7.48653 7.18257 7.39941 6.96242 7.39941C6.74226 7.39941 6.53105 7.48653 6.37492 7.64174C6.29681 7.71921 6.23481 7.81138 6.19251 7.91293C6.1502 8.01448 6.12842 8.1234 6.12842 8.23341C6.12842 8.34342 6.1502 8.45234 6.19251 8.55389C6.23481 8.65544 6.29681 8.74761 6.37492 8.82507L9.90825 12.3584C9.98572 12.4365 10.0779 12.4985 10.1794 12.5408C10.281 12.5831 10.3899 12.6049 10.4999 12.6049C10.6099 12.6049 10.7188 12.5831 10.8204 12.5408C10.9219 12.4985 11.0141 12.4365 11.0916 12.3584L14.6666 8.82507C14.7447 8.74761 14.8067 8.65544 14.849 8.55389C14.8913 8.45234 14.9131 8.34342 14.9131 8.23341C14.9131 8.1234 14.8913 8.01448 14.849 7.91293C14.8067 7.81138 14.7447 7.71921 14.6666 7.64174Z'
                  fill='#707070'
                />
              </svg>
            }
          >
            <Text color='subdued'>Show previous tasks</Text>
          </Button>

          <Collapsible
            open={opened}
            id='basic-collapsible'
            transition={{ duration: "300ms", timingFunction: "ease-in-out" }}
            expandOnPrint
          >
            {this.renderUserGuideItems(completedItems)}
          </Collapsible>
        </>
      )
    );
  };

  renderCompletedUserGuide = () => {
    const { userGuide } = this.props;

    return (
      <>
        {userGuide && userGuide.progress === 100 && (
          <Box display="flex" alignItems="center" gap="4">
            <img src={SuccessIMG} alt='completed user guide' />
            <Text>Customers who completed these tasks do this next</Text>
            <Button
              variant={'primary'}
              onClick={() => this.props.navigate("/vat-returns/create-return")}
            >
              Create VAT Return
            </Button>
            <Text variant='bodySm' color='subdued' alignment='center'>
              If you need any further help, our help center have a lot of video
              tutorials & personal manager who will be happy to help you out
            </Text>
            <Button variant={'plain'} onClick={() => this.props.navigate("/help")}>
              Open Help Center
            </Button>
          </Box>
        )}
      </>
    );
  };

  renderHeaderText = () =>
    `${this.getTasksLeft()} tasks left (About ${this.getMinutesLeft()} minutes`;

  renderContent = () => {
    const { userGuide, fetchingGuide, customStyles = {} } = this.props;

    return (
      <>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Text as="h1" variant="headingLg">User Guide</Text>
          {!fetchingGuide && userGuide && userGuide.progress !== 100 && (
            <div style={customStyles.headerText}>
              <Text color='subdued'>{this.renderHeaderText()}</Text>
            </div>
          )}
        </Box>
        <br />
        {fetchingGuide && <Spinner />}
        {!fetchingGuide && userGuide && (
          <Box display="flex" flexDirection="column" gap="4" width="100%">
            <Text color='subdued'>
              Here are some tips and setup tasks to help you get started
            </Text>
            <div className='userGuide-progressBarWrap'>
              <div className='userGuide-progressBarWrap-progressBar'>
                <div
                  className='userGuide-progressBarWrap-progressBar-line'
                  style={{ width: `${userGuide.progress}%` }}
                />
              </div>
              <Text fontWeight='bold'>{userGuide.progress}%</Text>
            </div>

            {this.renderCompletedUserGuide()}
            {this.renderUncompletedUserGuideItems()}
            {this.renderCompletedUserGuideItems()}
          </Box>
        )}
      </>
    );
  };

  render() {
    const { userGuide, renderInline } = this.props;

    if (!userGuide.show_user_guide) return null;

    if (renderInline) {
      return this.renderContent();
    }

    return <Card sectioned>{this.renderContent()}</Card>;
  }
}

const mapStateToProps = (state) => ({
  skippingStep: state.user.skippingStep,
  userGuide: state.user.userGuide,
  user: state.user.user,
  fetchingGuide: state.user.fetchingGuide,
});

const mapDispatchToProps = (defaultDispatch) => {
  const dispatch = createCustomDispatch(defaultDispatch);

  return {
    skipStepUserGuide: (params) => dispatch(skipStepUserGuide(params)),
    fetchUserGuide: () => dispatch(fetchUserGuide()),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(UserGuide))
);
