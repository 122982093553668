import { Button, Modal } from '@shopify/polaris'
import './customElement.css'
import addIMG from "img/add-white.svg"
import backIMG from "img/arrow-left-white.svg"
import { useEffect, useState } from 'react'
import CreateNewTransaction from './contentModalStep2/CreateNewTransaction'
import ListTransactions from './contentModalStep2/ListTransactions'

const ModalWindowForTransactionsBuilder = ({handleChange, active, data, changeVatStr}) => {
	const [toggle, setToggle] = useState(false);
	const [dateRange, setDateRange] = useState({date_end: null, date_start: null});
	
	const setDateRangeHandler = (value) => {
		setDateRange(value)
	}

	const toggleModalContentHandler = () => {
		setToggle(!toggle)
	}
	useEffect(() => {
		if (!active) {
			setToggle(false)
		}
	}, [active]);

	return (
		<Modal
			open={active}
			onClose={handleChange}
			title={
				<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h4 >{!toggle ? 'Transactions List' : 'Add Transaction'}</h4 >
					<div style={{ display: 'flex', marginLeft: '20px' }}>
		          <Button
			          variant={"primary"}
			          onClick={toggleModalContentHandler}
		          >
              <span style={{display: 'flex', alignItems: 'center', color: 'white'}}>
                <img
	                src={!toggle ? addIMG : backIMG}
	                alt='add icon'
	                style={{marginRight: '8px', width: '16px', height: '16px'}}
                />
	              {!toggle ? 'Add Transaction Form' : 'Back to Transactions List'}
              </span >
            </Button >

          </div>
        </div>
			}

			size='lg'
			style={{padding: '0'}}
		>
			{
				toggle
					?
					<div className='transaction-create'>
						<CreateNewTransaction
							closeHandler={toggleModalContentHandler}
							data={data}
							changeVatStr={changeVatStr}
							dateRange={dateRange}
						/>
					</div >
					:
					<ListTransactions
						active={active}
						changeVatStr={changeVatStr}
						data={data}
						setDateRangeHandler={setDateRangeHandler}
					/>
			}
    </Modal >
	);
}

export default ModalWindowForTransactionsBuilder