import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import withRouter from "helpers/withRouter";
import {
  Page,
  Layout,
  Card,
  Button,
  Badge,
  FormLayout,
  Select,
  RadioButton,
  TextField,
  ButtonGroup,
  Banner,
  Checkbox,
  Text,
  DataTable, Box,
} from "@shopify/polaris";
import GoBackPageNavigation from '../../components/GoBackPageNavigation/GoBackPageNavigation'
import ArrowLeftMinor from '../../img/arrow-left.svg'
import ArrowRight from '../../img/arrow-right-white.svg'
import {
  addTransaction,
  getTransactionsFields,
  updateTransactionsIntegrationDates,
} from "../../redux/features/transactions/transactionsSlice";
import {
  fetchUserGuide,
  skipStepUserGuide,
} from "../../redux/features/user/userSlice";
import { fetchWebsites } from "../../redux/features/websites/websitesSlice";
import _ from "lodash";
import * as XLSX from "xlsx";
import moment from "moment";
import { formatDate, formatDateForServer, formatTime } from "utils/dates";
import { Link } from "react-router-dom";
import SaveBar from "components/SaveBar/SaveBar";
import Datepicker from "components/Datepicker/Datepicker";
import formatRow from "utils/formatRow";
import PageHelmet from "components/PageHelmet";
import GuideCompletionPopup from "components/GuideCompletionPopup/GuideCompletionPopup";
import { createCustomDispatch } from "helpers/customDispatch";

let importData = [];
let importFileName = "";

const defaultDates = {
  from: moment().startOf("month").toDate(),
  till: moment().toDate(),
};

class AddTransactions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      token: "",
      /*  website: {
          platform: undefined
        },*/
      data: null,
      addingResult: "",
      addingError: "",
      tokenError: "",
      jsonError: "",
      transactionsFileName: "",
      transactionsFile: "",
      importFileRows: {
        first: 2,
        last: 10,
      },
      fields: null,
      thresholds: false,

      month: defaultDates.from.getMonth(),
      year: defaultDates.from.getFullYear(),
      selected: {
        start: defaultDates.from,
        end: defaultDates.till,
      },

      visibilityDatePickerFrom: false,
      visibilityDatePickerTo: false,
    };
    this.topRef = React.createRef()
  }

  componentDidMount() {
    this.props.fetchWebsites();
  }

  fetchFields = () => {
    const params = {
      if_digital: this.state.if_digital === "digital",
      if_vat_calculate: this.state.if_vat_calculate === "need",
    };

    this.props
      .getTransactionsFields(params)
      .then((response) => {
        /* headers for file */
        this.setState({ fields: response });

        /* data template */
        const obj = {};
        response.forEach((item, index) => {
          obj[item.name] = index + 1;
        });
        this.setState({ data: obj });
        setTimeout(() => {
          this.setState({ fetchingError: "timeout" });
        }, 3000);
      })
      .catch((response) => {
        this.setState({ fetchingError: response });
      });
  };

  handleFileSelect = () => {
    if (this.fileUploadInput) {
      this.fileUploadInput.click();
      this.fileUploadInput.onchange = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (ee) => {
          const data = ee.target.result;
          const workbook = XLSX.read(data, { type: "array" });
          const firstsheetname = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstsheetname];
          importData = [];
          const range = XLSX.utils.decode_range(worksheet["!ref"]);
          for (let C = range.s.c; C <= range.e.c; ++C) {
            const ta = new Array(range.e.r - range.s.r + 1);
            for (let R = range.s.r; R <= range.e.r; ++R) {
              const cell = worksheet[XLSX.utils.encode_cell({ r: R, c: C })];
              if (!cell) continue;
              ta[R - range.s.r] = cell.v;
            }
            importData.push(ta);
          }
          this.setState({
            transactionsFile: importData,
          });
        };
        reader.readAsArrayBuffer(file);
        importFileName = file.name;
        this.setState({
          transactionsFileName: importFileName,
        });
      };
    }
  };

  handleUpdateTransactions = () => {
    // recieve transactions from integrated web store
    const params = {
      website_id: this.state.website /*.id*/,
      platform: this.state.websitePlatform,
      min_date: this.state.date_from
        ? formatDateForServer(this.state.selected.start)
        : formatDateForServer(defaultDates.from),
      max_date: this.state.date_till
        ? formatDateForServer(this.state.selected.end)
        : formatDateForServer(defaultDates.till),
    };
    this.setState({ updating: true });
    this.props
      .updateTransactionsIntegrationDates(params)
      .then((resp) => {
        this.setState({ updating: false, import_result: resp });
      })
      .catch(() => {
        this.setState({ updating: false });
      });
  };

  handleMonthChangeData = (month, year) => {
    this.setState({
      month,
      year,
    });
  };

  handleFromDateChange = (startDate) => {
    const { selected } = this.state;
    selected.start = startDate;
    this.setState({
      selected,
      date_from: formatDate(startDate),
    });
  };

  handleTillDateChange = (endDate) => {
    const { selected } = this.state;
    selected.end = endDate;
    this.setState({
      selected,
      date_till: formatDate(endDate),
    });
  };

  resetData = () => {
    this.setState({
      fields: null,
      if_digital: null,
      if_vat_calculate: null,
      data: null,
      website: "",
      thresholds: false,
      addingResult: null,
      addingError: null,
    });
    importData = [];
  };
  
  uploadTransactions = () => {
    const { data } = this.state;
    const transactionsArray = new Array(importData[0].length);

    for (let i = 0; i < importData[0].length; i++) {
      transactionsArray[i] = new Array(importData.length);
    }
    
    let i = 0;

    for (const header in data) {
      if (header && header !== "transaction_id") {
        for (
          let j = this.state.importFileRows.first - 1;
          j < importData[0].length && j < this.state.importFileRows.last;
          j++
        ) {
          let a = importData[data[header] - 1][j];
          
          a = a ? String(a) : a;
          if (header === "transaction_datetime" || header === "payment_date_time") {
            if (a) {
              a = String(a);
            } else {
              a = null;
            }
          }
          
          transactionsArray[j - this.state.importFileRows.first + 1][i] = a;
        }
        i++;
      }
    }

    const fieldsTitles = this.state.fields
      .filter((field) => field.name !== "transaction_id")
      .map((field) => field.name);
    transactionsArray.unshift(fieldsTitles);
    const worksheet = XLSX.utils.aoa_to_sheet(transactionsArray);
    const CSV = XLSX.utils.sheet_to_csv(worksheet);
    const blob = new Blob([CSV], { type: "text/csv;charset=utf-8;" });
    const formData = new FormData();
    formData.append("file", blob, "csvfile");
    this.submitTransaction(formData);
  };


  submitTransaction = (csv) => {
    const { userGuide, t } = this.props;
    this.setState({
      addingError: null,
      jsonError: null,
      tokenError: null,
    });
    this.setState({ updating: true });
    if (!this.state.website) {
      this.setState({ tokenError: t("addTransactions.selectWebsite") });
      return;
    }

    this.props
      .addTransaction({
        id: this.state.website /*.id*/,
        digital: this.state.if_digital === "digital",
        calculate: this.state.if_vat_calculate === "need",
        thresholds: this.state.thresholds,
        data: csv,
      })
      .then(() => {
        if (userGuide && userGuide.show_user_guide) {
          this.props.fetchUserGuide();
        }
        this.setState({
          updating: false,
          addingResult: t("addTransactions.transUploaded"),
          transactionsFile: undefined,
          transactionsFileName: undefined,
          importData: undefined,
          thresholds: false,
        });
      })
      .catch((result) => {
        this.setState({
          updating: false,
          addingError: result,
        });
      });
  };

  renderSiteSelect = () => {
    const { websites, t } = this.props;
    const { website, tokenError } = this.state;
    const options = [
      {
        label: "",
        value: "",
      },
    ];
    websites.map((item) => {
      options.push({
        label: item.website,
        value: item.id.toString(),
      });
    });

    return (
      <>
        <div style={{ paddingBottom: "0.4rem" }}>
          <Box display="flex" alignItems="space-between">
            <Text>{t("addTransactions.website")}</Text>
            {!this.state.fields && this.state.website && (
              <Text variant="bodyMd" color="critical">
                {this.state.dateUpdate &&
                  `${t("addTransactions.lastUpload")} ${
                    this.state.nameWebsite
                  } ${t("addTransactions.was")} ${formatDate(
                    this.state.dateUpdate
                  )} ${formatTime(this.state.dateUpdate)}`}
              </Text>
            )}
          </Box>
        </div>
        <Select
          options={options}
          onChange={(value) => {
            const searchWebsite = websites.find((item) => item.id === +value);
            const websitePlatform = searchWebsite
              ? searchWebsite.platform
              : undefined;
            const dateUpdate = searchWebsite
              ? searchWebsite.transactions_date_update
              : undefined;
            const nameWebsite = searchWebsite
              ? searchWebsite.website
              : undefined;
            const alertMessage = searchWebsite
              ? searchWebsite.alert_message
              : null;
            this.setState({
              website: value,
              websitePlatform,
              tokenError: false,
              dateUpdate,
              nameWebsite,
              alertMessage,
            });
          }}
          value={website}
          errorText={tokenError}
        />
      </>
    );
  };

  renderMarketplaceDetermination() {
    const { selected, websitePlatform, website } = this.state;
    const { t } = this.props;
    return (
      <div>
        {!this.state.updating && this.state.import_result === undefined && (
          <div style={{ display: 'flex', justifyContent: 'stretch' }}>
            <Datepicker
              label={t("addTransactions.validFrom")}
              value={selected.start}
              onChange={this.handleFromDateChange}
              allowRange={false}
            />
            <Datepicker
              label={t("addTransactions.validTill")}
              value={selected.end}
              onChange={this.handleTillDateChange}
              allowRange={false}
            />
          </div>
        )}

        {this.state.updating && (
          <div>
            {websitePlatform !== "amazon" && (
              <p>
                {t("addTransactions.obtData1")} {websitePlatform}
                {t("addTransactions.obtData2")}...
              </p>
            )}
            {websitePlatform === "amazon" && (
              <p>{t("addTransactions.sendReq")}...</p>
            )}
          </div>
        )}
        {this.state.import_result !== undefined && (
          <div>
            {this.state.import_result === 0 && websitePlatform !== "amazon" && (
              <div>{t("addTransactions.transUpTo")}</div>
            )}
            {this.state.import_result === 0 && websitePlatform === "amazon" && (
              <div>
                {t("addTransactions.beReadyUp1")} {websitePlatform}{" "}
                {t("addTransactions.beReadyUp2")}...{" "}
              </div>
            )}
            {this.state.import_result !== 0 && websitePlatform !== "amazon" && (
              <div>
                {this.state.import_result} {t("addTransactions.transImported1")}{" "}
                {websitePlatform} {t("addTransactions.transImported2")}
              </div>
            )}
            {this.state.import_result !== 0 && websitePlatform === "amazon" && (
              <Banner tone='critical'>{this.state.import_result}</Banner>
            )}
            <br />
            <Button
              primary
              disabled={!website && websitePlatform}
              onClick={() => this.props.navigate("/transactions")}
            >
              &nbsp;&nbsp;&nbsp;&nbsp;OK&nbsp;&nbsp;&nbsp;&nbsp;
            </Button>
          </div>
        )}
      </div>
    );
  }

  renderImportParamsDetermination() {
    const { t } = this.props;

    return (
      <FormLayout>
        <p>{t("addTransactions.typeOfSales")}:</p>

        <RadioButton
          label={t("addTransactions.digServ")}
          checked={this.state.if_digital === "digital"}
          id='digital'
          name={"goodsType"}
          onChange={(value, id) => {
            this.setState({ if_digital: id });
          }}
        />
        <RadioButton
          label={t("addTransactions.goods")}
          checked={this.state.if_digital === "phisycal"}
          id='phisycal'
          name={"goodsType"}
          onChange={(value, id) => {
            this.setState({ if_digital: id });
          }}
        />

        <Text>Tax (VAT or Sales Tax) calculation:</Text>
        <RadioButton
          label={t("addTransactions.needCalc")}
          value={this.state.if_vat_calculate === "need"}
          id='need'
          name={"calculation"}
          onChange={(value, id) => {
            this.setState({ if_vat_calculate: id });
          }}
        />
        <RadioButton
          label={t("addTransactions.haveAll")}
          value={this.state.if_vat_calculate === "do_not_need"}
          id='do_not_need'
          name={"calculation"}
          onChange={(value, id) => {
            this.setState({ if_vat_calculate: id });
          }}
        />
      </FormLayout>
    );
  }

  renderRowsSelect() {
    const { t } = this.props;

    return (
      <Card sectioned>
        <FormLayout>
          <FormLayout.Group>
            <TextField
              id='firstRow'
              label={`${t("addTransactions.firstDataRow")}:`}
              type='number'
              min='1'
              max={this.state.importFileRows.last}
              value={`${this.state.importFileRows.first}`}
              onChange={(value) => {
                const importFileRows = { ...this.state.importFileRows };
                importFileRows.first = +value;
                this.setState({ importFileRows });
              }}
            />
            <TextField
              id='lastRow'
              label={`${t("addTransactions.lastDataRow")}:`}
              type='number'
              min={this.state.importFileRows.first}
              max='1000'
              value={`${this.state.importFileRows.last}`}
              onChange={(value) => {
                const importFileRows = { ...this.state.importFileRows };
                importFileRows.last = +value;
                this.setState({ importFileRows });
              }}
            />
          </FormLayout.Group>
        </FormLayout>
      </Card>
    );
  }

  renderColumnsMatching() {
    const headers = this.state.fields;
    const { data, transactionsFile } = this.state;

    const columns = [
      {
        property: "title",
        header: {
          label: "Parameters",
        },
      },
      {
        property: "name",
        header: {
          label: "File Column Headers",
        },
        cell: {
          formatters: [
            (value, { rowData, index }) => (
              <div style={{ width: "8rem" }}>
                <TextField
                  id={"field" + index}
                  type='number'
                  min='1'
                  max={transactionsFile ? transactionsFile.length : 1000}
                  disabled={!transactionsFile}
                  value={data ? `${data[rowData.name]}` : `${index + 1}`}
                  onChange={(value) => {
                    data[rowData.name] = value; // ~ headersMatching
                    this.setState({ data });
                    /*  headersMatching[title] = value;*/
                  }}
                />
              </div>
            ),
          ],
        },
      },
      {
        property: "description",
        header: {
          label: "Transaction Preview",
        },
        cell: {
          formatters: [
            (value, { rowData }) => {
              const cellValue = headers && data && importData[data[rowData.name] - 1]
                ? importData[data[rowData.name] - 1][this.state.importFileRows.first - 1]
                : "-";
              
              // Проверка, является ли значение числом, чтобы определить, если оно в формате Excel
              if (!isNaN(cellValue) && cellValue > 25569) {
                // Преобразование даты из формата Excel
                const excelDate = moment(new Date((cellValue - 25569) * 86400 * 1000));
                return excelDate.isValid() ? excelDate.format("YYYY-MM-DD HH:mm") : cellValue;
              }
              
              return cellValue;
            },
          ],
        },
      },
    ];

    const rows = formatRow(headers, columns);

    return (
      <DataTable
        columnContentTypes={["text", "text", "text"]}
        rows={rows}
        headings={columns.map(({ header }) => (
          <Text fontWeight='semibold' variant='bodyMd'>
            {header.label}
          </Text>
        ))}
        hideScrollIndicator
        increasedTableDensity
      />
    );
  }

  renderFileAdjustment() {
    const { t } = this.props;

    return (
      <Layout>
        <Layout.AnnotatedSection
          title={
            <Text variant='headingLg' as='p'>
              {t("addTransactions.chooseFile")}
            </Text>
          }
          description={
            <Text variant="bodyMd" color="critical">
              {t("addTransactions.validFormats")}: *.xlsx, *.xls
            </Text>
          }
        >
          <Card sectioned>
            {_.isObject(this.state.addingError) && (
              <div>
                <Text color='critical'>{this.state.addingError.error}</Text>
                <br />
              </div>
            )}
            {this.state.addingResult && (
              <div>
                <Banner
                  title={this.state.addingResult}
                  tone='success'
                  onDismiss={this.resetData}
                >
                  {t("addTransactions.goTrans1")}{" "}
                  <Link className='Polaris-Link' to='/transactions'>
                    {t("addTransactions.goTrans2")}
                  </Link>{" "}
                  {t("addTransactions.goTrans3")}
                </Banner>
                <br />
              </div>
            )}

            {/*     <Button
             onClick={ () => { this.uploadTransactions(); }}
             loading={this.state.updating}
             disabled={!(this.state.transactionsFileName && this.state.website)}
           >Upload transactions</Button>*/}
            
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
              <Button
                onClick={this.handleFileSelect}
                loading={this.state.updating}
                size={'micro'}
              >
                {t("addTransactions.addFile")}
                <input
                  type='file'
                  accept='application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                  style={{display: "none"}}
                  ref={(input) => (this.fileUploadInput = input)}
                />
              </Button >
              {this.state.transactionsFile && (
                <Badge >
                  {t("addTransactions.fileName")}:{" "}
                  {this.state.transactionsFileName}
                </Badge >
              )}
              <Text
                variant='bodyMd'
                color='critical'
              >
                {t("addTransactions.chooseFiles")}
              </Text >
            </div>
          </Card >
        </Layout.AnnotatedSection >
        <Layout.AnnotatedSection
          title={
            <Text variant='headingLg' as='p'>
              {t("addTransactions.selectRange")}
            </Text>
          }
          description={
            <Text variant="bodyMd" color="critical">
              {t("addTransactions.chooseRows")}
            </Text>
          }
        >
          {this.renderRowsSelect()}
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection
          title={
            <Text variant='headingLg' as='p'>
              {t("addTransactions.colMatch")}
            </Text>
          }
          description={
            <p>
              <Text variant="bodyMd" color="critical">
                {t("addTransactions.matchEach")}
              </Text>
              <br />
              <Link className='Polaris-Link' to={"#"}>
                {t("addTransactions.learnMore")}
              </Link>
            </p>
          }
        >
          <Card /* sectioned*/>
            {this.renderColumnsMatching()}
            {this.state.if_digital === "phisycal" && (
              <div style={{ padding: "2rem" }}>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Checkbox
                    label={t("addTransactions.consThresh")}
                    checked={this.state.thresholds}
                    onChange={(thresholds) => this.setState({ thresholds })}
                  />
                </div>
              </div>
            )}
          </Card>
        </Layout.AnnotatedSection>
      </Layout>
    );
  }

  render() {
    const { userGuide, t } = this.props;
    const { alertMessage } = this.state;
    const platform = this.state.website
      ? this.state.websitePlatform
      : undefined;
    
    
    
    return (
      <div>
        <Page
          separator
          fullWidth
          title={
          <GoBackPageNavigation content={
            <Text variant='heading3xl' as='span'>
              {!this.state.fields
                ? t("addTransactions.addTrans")
                : t("addTransactions.importTrans")}
            </Text>
          }/>
          }
          subtitle={
            <Text variant='bodyLg' as='span' color='subdued'>
              {t("addTransactions.hereYouCanAdd")}
            </Text>
          }
        >
          <PageHelmet title={"Add transactions"} />

          <GuideCompletionPopup
            title={t("userGuide.completed4")}
            description={t("userGuide.completed4_desc")}
            visible={
              userGuide &&
              userGuide.show_user_guide &&
              userGuide.steps[3].show_banner
            }
            step={4}
          />

          {!this.state.fields && (
            <Layout>
              <Layout.AnnotatedSection
                title={`${t("addTransactions.chooseImportParams")}:`}
              >
                <div style={{ position: 'relative', overflow: 'visible', background: 'white', padding: '30px' }}>
                  {!this.state.fields &&
                    !this.state.updating &&
                    this.state.import_result === undefined &&
                    this.renderSiteSelect()}
                  <br />
                  {!this.state.fields &&
                    this.state.website &&
                    !platform && // own website
                    this.renderImportParamsDetermination()}

                  {!this.state.fields &&
                    this.state.website &&
                    platform && // platform
                    this.renderMarketplaceDetermination()}
                  {alertMessage && (
                    <div>
                      <br />
                      <Banner title='Info' status='info'>
                        <p>
                          Transactions can be requested from your Amazon
                          business account if you generated the Sales Tax Report
                          for this period in the Tax Document Library in Seller
                          Central.
                        </p>
                        <a
                          className='Polaris-Link'
                          href={
                            "https://sellercentral-europe.amazon.com/gp/tax/tax-library.html/ref=xx_taxlib_dnav_xx"
                          }
                          target='_blank'
                        >
                          Amazon reports
                        </a>
                      </Banner>
                    </div>
                  )}
                </div>
              </Layout.AnnotatedSection>
            </Layout>
          )}
          <div style={{ marginBottom: 102 }}>
            {this.state.fields && this.renderFileAdjustment()}
          </div>
        </Page>
        <SaveBar title={t("addTransactions.addTrans")}>
          {!this.state.fields && (
            <ButtonGroup>
              <Button
                onClick={() => this.setState({ website: "" })}
                size={'micro'}
                disabled={
                  this.state.updating ||
                  this.state.import_result ||
                  this.state.import_result === 0
                }
              >
                {t("addTransactions.discard")}
              </Button>
              {!(!this.state.fields && this.state.website && !platform) &&
                !(!this.state.fields && this.state.website && platform) && (
                  <Button size={'micro'} variant={'primary'} disabled>
                    {t("addTransactions.next")}<img src={ArrowRight} alt='arrow' style={{width: 10, height:10 , marginLeft:8}}/>
                  </Button>
                )}

              {!this.state.fields &&
                this.state.website &&
                !platform && ( // own website
                  <Button
                    variant={'primary'}
                    size={'micro'}
                    disabled={
                      !(
                        this.state.website &&
                        ((this.state.if_digital &&
                          this.state.if_vat_calculate) ||
                          this.state.websitePlatform)
                      )
                    }
                    onClick={() => {
                      this.fetchFields();
                    }}
                  >
                    {t("addTransactions.next")}<img src={ArrowRight} alt='arrow' style={{width: 10, height:10 , marginLeft:8}}/>
                  </Button>
                )}

              {!this.state.fields &&
                this.state.website &&
                platform && ( // platform
                  <Button
                    variant={'primary'}
                    size={'micro'}
                    disabled={
                      (!this.state.website && this.state.websitePlatform) ||
                      this.state.updating ||
                      this.state.import_result ||
                      this.state.import_result === 0
                    }
                    onClick={() => this.handleUpdateTransactions()}
                  >
                    {t("addTransactions.next")}<img src={ArrowRight} alt='arrow' style={{width: 10, height:10 , marginLeft:8}}/>
                  </Button>
                )}
            </ButtonGroup>
          )}
          {this.state.fields && (
            <ButtonGroup>
              <Button size={'micro'} onClick={this.resetData}>
                {t("addTransactions.discard")}
              </Button>
              <Button
                variant={'primary'}
                size={'micro'}
                onClick={() => {
                  this.uploadTransactions();
                  if (this.topRef.current) {
                    this.topRef.current.scrollIntoView();
                  } else {
                    console.warn('topRef.current is null');
                  }
                }}
                loading={this.state.updating}
                disabled={
                  !(this.state.transactionsFileName && this.state.website)
                }
              >
                {t("addTransactions.uploadTrans")}
              </Button>
            </ButtonGroup>
          )}
        </SaveBar>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userGuide: state.user.userGuide,
  websites: state.websites.websites,
  isAdding: state.transactions.adding,
  defaultLanguage: state.user.defaultLanguage,
});

const mapDispatchToProps = (defaultDispatch) => {
  const dispatch = createCustomDispatch(defaultDispatch);

  return {
    fetchWebsites: () => dispatch(fetchWebsites()),
    addTransaction: (params) => dispatch(addTransaction(params)),
    getTransactionsFields: (params) => dispatch(getTransactionsFields(params)),
    updateTransactionsIntegrationDates: (params) =>
      dispatch(updateTransactionsIntegrationDates(params)),
    fetchUserGuide: () => dispatch(fetchUserGuide()),
    skipStepUserGuide: () => dispatch(skipStepUserGuide()),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(AddTransactions))
);
