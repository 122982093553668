import { AlphaStack, Box, Button, Spinner, Text } from '@shopify/polaris'
import useAppDispatch from 'hooks/useAppDispatch'
import Checkmark from "img/check-circle.svg"
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { deleteCurrentPlan, fetchCurrentPlan } from '../../../../redux/features/subscription/subscriptionSlice'
import CancelSubscriptionModalSteps from './CancelSubscriptionSteps/CancelSubscriptionModalSteps'
import './CancelSubscriptionSteps/styleCancelSubscriptionModal.scss'

const CancelSubscriptionBuilderNew = () => {
	const {fetching} = useSelector((state) => state.user)
	const {currentPlan, deletingPlanError, deletingPlan = false} = useSelector((state) => state.subscription)
	const [showModalWithSteps, setShowModalWithSteps] = useState(false)
	const [countSteps, setCountSteps] = useState(1)
	const [changeQuestion, setChangeQuestion] = useState(1)
	const [writeYourReasonValue, setWriteYourReasonValue] = useState('Too expensive')
	const [subsForDel, setSubsForDel] = useState([])
	const [subsForDelAddons, setSubsForDelAddons] = useState([])
	const [subsList, setSubsList] = useState([])
	const [showSuccess, setShowSuccess] = useState('')
	const dispatch = useAppDispatch()
	
	useEffect(() => {
		showSuccess !== '' && setTimeout(() => setShowSuccess(''), 2000)
	}, [showSuccess])
	
	const resetData = () => {
		if (!showModalWithSteps) {
			setCountSteps(1)
			setSubsForDel([])
			setSubsForDelAddons([])
			setChangeQuestion(1)
			setWriteYourReasonValue('Too expensive')
		}
	}
	
	const toggleModalWithSteps = () => {
		setShowModalWithSteps(!showModalWithSteps)
		resetData()
	}
	
	const saveChangeQuestionHandler = (id,
		question) => {
		setChangeQuestion(id)
		setWriteYourReasonValue(question)
	}
	
	const checkedAllAddonsSubscription = (subscriptionValue,
		status) => {
		const subscription = subsList.find((item) => item.value === subscriptionValue)
		if (!subscription || !subscription.addons) return
		const updatedAddons = subscription.addons.map((addon) => ({
			value: addon.addons_id,
			checked: status,
			body: addon
		}))
		
		setSubsForDelAddons((prev) => {
			if (!status) {
				return prev.filter((item) => item.subscription !== subscriptionValue)
			}
			
			const existingSubscription = prev.find(
				(item) => item.subscription === subscriptionValue
			)
			
			if (existingSubscription) {
				return prev.map((item) =>
					item.subscription === subscriptionValue
						? {...item, addons: updatedAddons}
						: item
				)
			}
			
			return [...prev, {subscription: subscriptionValue, addons: updatedAddons}]
		})
	}
	
	
	const saveSetSubsForDel = (value, status) => {
		if (subsList.length > 0 && subsForDel.length === 0) {
			const initialSubs = subsList.map((sub) => ({
				value: sub.value,
				checked: false,
			}));
			setSubsForDel(initialSubs);
		}
		
		setSubsForDel((prev) =>
			prev.map((sub) =>
				sub.value === value ? { ...sub, checked: !sub.checked } : sub
			)
		);
		
		setSubsForDel((prev) => {
			const subscriptionItems = prev.filter((item) => item.value === value);
			
			if (subscriptionItems.every((item) => !item.checked)) {
				return prev.map((item) =>
					item.value === value ? { ...item, checked: false } : item
				);
			}
			
			return prev;
		});
		
		checkedAllAddonsSubscription(value, status);
	};
	
	const saveSetSubsForDelAddons = ({ subscription, addon }) => {
		setSubsForDelAddons((prevState) => {
			const existingSubscription = prevState.find(
				(item) => item.subscription === subscription
			);
			
			if (existingSubscription) {
				const updatedAddons = existingSubscription.addons?.map((item) => {
					if (
						item.value === addon.value &&
						(item.type_id === addon.type_id || addon.type_id === undefined)
					) {
						return { ...item, checked: addon.checked, body: addon.body };
					}
					return item;
				}) || [];
				
				if (!existingSubscription.addons?.some((item) => item.value === addon.value && item.type_id === addon.type_id)) {
					updatedAddons.push({ value: addon.value, type_id: addon.type_id, checked: addon.checked, body: addon.body });
				}
				
				return prevState.map((item) =>
					item.subscription === subscription
						? { ...item, addons: updatedAddons }
						: item
				);
			}
			
			return [
				...prevState,
				{
					subscription,
					addons: [{ value: addon.value, type_id: addon.type_id, checked: addon.checked, body: addon.body }],
				},
			];
		});
		
		setSubsForDel((prev) => {
			const existingSubscription = prev.find(
				(item) => item.value === subscription
			);
			
			if (existingSubscription) {
				const hasCheckedAddons = !!existingSubscription.addons?.some((addon) => addon.checked);
				return prev.map((item) => {
					return item.value === subscription
						? { ...item, checked: hasCheckedAddons }
						: item;
				});
			}
			return prev;
		});
	};
	
	const renderCancelSubscription = () => {
		const updatedSubsList = []
		if (currentPlan?.is_active && !currentPlan?.subscriptions?.is_canceled) {
			updatedSubsList.push({
				label: `Tariff plan VAT: ${currentPlan?.subscriptions?.tariff_plan?.name}`,
				value: 'vat',
				addons: currentPlan?.subscriptions?.addons,
				currency: currentPlan?.subscriptions?.currency_id,
				finishDate: currentPlan?.your_next_billing_date
			})
		}
		if (currentPlan?.is_active_sales_tax && !currentPlan?.subscriptions_sales_tax?.is_canceled) {
			updatedSubsList.push({
				label: `Tariff plan SALES TAX: ${currentPlan?.subscriptions_sales_tax?.tariff_plan?.name}`,
				value: 'sales_tax',
				addons: currentPlan?.subscriptions_sales_tax?.addons,
				currency: currentPlan?.subscriptions_sales_tax?.currency_id,
				finishDate: currentPlan?.your_next_billing_date
			})
		}
		if (currentPlan?.is_active_epr && !currentPlan?.subscriptions_epr?.is_canceled) {
			updatedSubsList.push({
				label: `Tariff plan EPR: ${currentPlan?.subscriptions_epr?.tariff_plan?.name}`,
				value: 'epr',
				addons: currentPlan?.subscriptions_epr?.addons,
				currency: currentPlan?.subscriptions_epr?.currency_id,
				finishDate: currentPlan?.your_next_billing_date
			})
		}
		if (currentPlan?.is_active_omp && !currentPlan?.subscriptions_omp?.is_canceled) {
			updatedSubsList.push({
				label: `Tariff plan OMP: ${currentPlan?.subscriptions_omp?.tariff_plan?.name}`,
				value: 'omp',
				addons: currentPlan?.subscriptions_omp?.addons,
				currency: currentPlan?.subscriptions_omp?.currency_id,
				finishDate: currentPlan?.your_next_billing_date
			})
		}
		setSubsList(updatedSubsList)
	}
	
	useEffect(() => {
		renderCancelSubscription()
	}, [currentPlan])
	
	const cancelSubscriptionHandler = async () => {
		const tehDataArray = subsList.map((subscription) => {
			const sub = subsForDel.find((item) => item.value === subscription.value);
			
			const addonsData = subsForDelAddons.find(
				(item) => item.subscription === subscription.value
			);
			
			const addons = addonsData?.addons
				.filter((addon) => addon.checked)
				.map((addon) => ({
					addons_id: addon.body.addons_id,
					value: addon.value,
					checked: addon.checked,
					count: addon.body.count,
					name: addon.body.name,
					type: addon.body.type,
					type_id: addon.body.type_id ? addon.body.type_id : null,
				})) || [];
			
			return {
				subs: subscription.value,
				delete_subs: sub ? sub.checked : false,
				addons: addons,
				reason: writeYourReasonValue,
			};
		});
	
		try {
			const req = await dispatch(deleteCurrentPlan({data: tehDataArray}))
			if (req.status === 200) {
				setShowSuccess(req.message)
				setShowModalWithSteps(false)
				await dispatch(fetchCurrentPlan())
			}
			if (req?.error) console.error(req.error)
		} catch (e) {
			console.error("Error canceling subscription:", e)
		}
	}

	return (
		<div className='cancelSubscriptionBuilder'>
      {fetching ? (
	      <Spinner />
      ) : (
	      <>
          {showModalWithSteps && (
	          <CancelSubscriptionModalSteps
		          visible={showModalWithSteps}
		          onClose={toggleModalWithSteps}
		          countSteps={countSteps}
		          nextStep={() => setCountSteps((prev) => Math.min(prev + 1, 3))}
		          prevStep={() => setCountSteps((prev) => Math.max(prev - 1, 1))}
		          saveChangeQuestion={saveChangeQuestionHandler}
		          changeQuestion={changeQuestion}
		          writeYourReasonValue={writeYourReasonValue}
		          subsList={subsList}
		          subsForDel={subsForDel}
		          saveSetSubsForDel={saveSetSubsForDel}
		          subsForDelAddons={subsForDelAddons}
		          saveSetSubsForDelAddons={saveSetSubsForDelAddons}
		          cancelSubscriptionHandler={cancelSubscriptionHandler}
		          deletingPlan={deletingPlan}
		          deletingPlanError={deletingPlanError}
	          />
          )}
		      
		      {
			      subsList.length > 0
			      &&
			      <Box
				      display="flex"
				      flexDirection="column"
				      gap="2"
			      >
	            <Text variant='headingMd'>
	              Want to{' '}
		            <Button
			            variant={'plain'}
			            onClick={toggleModalWithSteps}
		            >
			             <span style={{color: 'rgba(240, 68, 56, 1)'}}>cancel</span>
	              </Button >{' '}
	              your subscription?
            </Text >
            </Box >
		      }
        </>
      )}
			{showSuccess !== ''
				&&
			    <div
				    style={{
					    display: 'flex',
					    justifyContent: 'center',
					    alignItems: 'center',
					    position: 'fixed',
					    top: 0,
					    bottom: 0,
					    left: 0,
					    right: 0,
					    backgroundColor: 'rgba(0, 0, 0, 0.3)',
					    zIndex: 201
				    }}
			    >
			      <div
				      style={{
					      display: 'flex',
					      justifyContent: 'center',
					      alignItems: 'center',
					      width: '120px',
					      height: '120px',
					      borderRadius: '50%',
					      backgroundColor: 'white',
				      }}
			      >
			        <img
				        src={Checkmark}
				        style={{width: '60%', height: '60%'}}
				        alt='checkmark icon'
			        />
			      </div >
			    </div >
			}
    </div >
	)
}

export default CancelSubscriptionBuilderNew
