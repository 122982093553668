import report1099 from "./1099";
import dac7 from "./dac7";
import epr from "./epr";

const en = {
  login: {
    signIn: "Sign In",
    signOut: "Sign Out",
    or: "or",
    createAcc: "create an account",
    email: "Email",
    password: "Password",
    rememberMe: "Remember me",
    forgotPass: "Forgot your password?",
    err: "Error",
    invalidEmail: "Invalid email",
    enterPassAndEmail: "Please enter your password and email",
  },
  menu: {
    home: "Home",
    stores: "Stores",
    transactions: "Transactions",
    vatRet: "VAT",
    taxSettings: "Tax Settings",
    vatReg: "VAT Registration",
    taxAudit: "Tax Audit",
    taxAcademy: "Tax Academy",
    invite: "Invite & earn 15€",
    support: "Support",
    billing: "Billing",
    logOut: "Log out",
    dashboard: "Dashboard",
    salesTax: "Sales TAX",
    eprReporting: "EPR",
    reports: "Reports",
    messages: "Messages",
    helpCenter: "Help center",
    clients: "Clients",
  },
  menuEinvoice: {
    lookUp: "Look up",
    documents: 'documents',
    edocuments: 'e-documents',
    customers: 'customers',
    settings: 'settings',
    integrations: 'integrations',
  },
  dash: {
    hello: "Hello",
    how: "Here’s how your company is doing",
    lastTransUp: "Last transaction uploaded",
    addTrans: "Add Transactions",
    viewAll: "View all",
    title: {
      yourDD: "Your due dates",
      news: "News",
      payInfo: "Payment info",
      vatRet: "VAT returns",
      salesTaxRet: "Sales tax returns",
      eprRet: "EPR returns",
    },
    visitBl: "Visit blog",
    nextBilling: "Next billing date",
    month: "month",
    annually: "annually",
    plan: "Plan",
    overPlan: "Your tariff plan is over",
    dot: ".",
    selectPlan: "select plan",
    canCont: "You can continue using LOVAT software when you subscribe",
    notDataDD:
      "When you get your VAT numbers your due dates for the submission of your VAT returns will be set here",
    noDataVR: `You don't have filled VAT return`,
    revenue: "Revenue",
    threshold: "Threshold",
    warning: "Warning",
    yVAT: "Yearly VAT",
    qVAT: "Quarterly VAT",
    mVAT: "Monthly VAT",
    yEPR: "Yearly EPR",
    qEPR: "Quarterly EPR",
    mEPR: "Monthly EPR",
    ySalexTax: "Yearly Sales tax",
    qSalesTax: "Quarterly Sales tax",
    mSalesTax: "Monthly Sales tax",
    revByStore: "Revenue by store",
    revByCntr: "Revenue by country",
    fillVR: "Fill Return",
    balance: "Balance",
    deposit: "deposit",
    statuses: {
      new: "new",
      pending: "pending",
      submitted: "submitted",
      declined: "declined",
      draft: "draft",
      autoSub: "auto-submitted",
    },
    vatSub: "VAT subscription",
    taxSub: "Sales tax subscription",
  },
  profile: {
    pp: "Privacy Policy",
    wel: {
      title: "Welcome to LОVAT!",
      p1: "With this software you can do VAT compliance in different countries. The best place to start. We’re sure you’re excited to dive in.",
      p2: "The first thing we recommend you do is fill your profile.",
      heading: "Need a helping hand?",
      p3: `To learn more about how to start with Lovat, check out our video.`,
      skip: "Skip",
    },
    hereYouCanEditYourCompany:
      "Here you can edit your company and personal details and preferences",
    logOut: "Log out",
    companySettings: "Company settings",
    personalSettingsLabel: "Personal settings",
    referralProgram: "Referral program",
    personalSettings: {
      personalDetails: "Personal details",
      manageProfileDets: "Here you can manage your profile details",
      security: "Security",
      hereYouCanChange: "Here you can change your password",
      notifications: "Notifications",
      manageCommunication: "Manage your communication with us",
      monthlyNewsletter: "Monthly newsletter",
      supportUpdates: "Support updates",
    },
    yes: "Yes",
    no: "No",
    sureLogout: "Are you sure you want to log out from your LOVAT account?",
  },
  profileComp: {
    accountInfo: "Account information",
    desc: "Company details will be changed after moderation",
    companyDet: "Company details",
    editCompany: "Edit company details",
    companyName: "Company Name",
    merchID: "Merchant ID",
    address: "Address",
    companyEmail: "Company’s E-mail",
    contactPeson: "Person responsible for reporting",
    phone: "Phone",
    vatNum: "VAT number",
    companyStatus: "Company status",
    planOver: "Your tarrif plan is over",
    contUsing: "You can continue using LOVAT software when you subscribe.",
    selectPlan: "select plan",
    dot: ".",
    warning: "Warning",
    change: {
      paragraph: `To change your company name, you'll need to provide a copy of the document that shows your new name, such as`,
      step1: "Extract from trade register",
      step2: "Confirmation statement",
      step3:
        "Updated registration certificate etc. under the current laws of county of incorporation",
    },
    chooseFile: "Choose file",
    postcode: "Postcode",
    country: "Country",
    cityTown: "City/Town",
    strAddress: "Street Address",
    housenum: "House number",
    apt: "Apartment",
    saveChanges: "Save changes",
    discard: "Discard",
    regCode: "Registration code",
    billingContact: "Billing contact",
    email: "Email",
    typeTaxNumber: "Type Tax Number",
    editBillingContact: "Edit billing contact",
    taxNumber: "Tax Number",
    err: {
      data: "Please enter data",
      country: "Please enter country",
      companyName: "Please enter company name",
      emailInvalid: "Invalid email",
      email: "Please enter email",
      contactPerson: "Please enter contact person",
      vatNum: "Please enter default VAT number",
      regCode: "Please enter reg. code",
      phoneInvalid:
        'The phone number must start with "+" and consist of digits',
      phone: "Please enter phone",
    },
    mess: {
      companyName: "Company name will be changed after moderation",
    },
  },
  profileMemb: {
    sbMode: "Sandbox mode",
    turnOffSB: "Turn off Sandbox Mode",
    turnOnSB: "Turn on Sandbox Mode",
    deleteSBData: "Delete sandbox data",
    markSBData: "Please mark sandbox data you would like to remove",
    vatReg: "VAT Returns",
    trans: "Transactions",
    stores: "Stores",
    cancelSub: "Cancel subscription",
    confirm: "Confirm",
    goBack: "Go back",
    weAreSorry: `We're sorry to see you go.`,
    confirmTheCanc:
      "To confirm the cancellation of this subscription click the button below. You will receive an e-mail confirming this cancellation.",
    tariffPlan: "Tariff plan",
    tariffPlanTrail: "Tariff plan: Trial",
    trialModeAv: "Trial mode avalible till",
    changeSub: "Change subscription",
    membIs: "Your membership is",
    month: "month",
    annually: "annually",
    addCountry: "additional country",
    addNumTrans: "additional number of transactions (per a thousand)",
    addValueTrans: "additional value for each 100.000 transactions",
    nextBillDate: "Your next bill date is",
    membUntil: "Your membership is valid until",
    thanks1: "Thanks for being a member since",
    thanks2: "",
    renewNow: "Renew now",
    compPlan1: "Compare plans",
    compPlan2: "with different features and rates.",
    membDetails: "Membership details",
    planDetails: "Plan details",
    paymentMethod: "Payment method",
    cardDetails: "Card details",
    exp: "exp",
    type: "type",
    change: "Change",
    payMethodInFuture: "There will be payment methods here in the future",
    back: "Back",
    priorityCurrency: "Priority currency",
    eur: "EUR",
    usd: "USD",
    gbp: "GBP",
    thresholdsDetails: "Thresholds details",
    calcThresholds: "Calculate distance sales thresholds for all countries",
    lang: "Language",
    on: "On",
    off: "Off",
    buyAddons: "Buy addons",
    delete: "Delete",
    edit: "Edit",
  },
  profileUsers: {
    users: "Users",
    desc: "Here you can manage your staff accounts",
    userList: "User list",
    addUsers: "Add users",
    edit: "Edit",
    editUser: "Edit user",
    del: "Delete",
    delUser: "Delete user",
    sureDel: `You can't restore it in future. But you can create user with the same name.`,
    changePass: "Change password",
    oldPass: "Old password",
    newPass: "New password",
    repeatPass: "Repeat password",
    err: {
      pass: "Please enter password",
      passNotEq: "Given passwords are not equal!",
      passLeast: "Your password must be at least 7 characters",
      userName: "Please enter user name",
      userSurname: "Please enter user surname",
      email: "Please enter email",
      emailInvalid: "Invalid email",
    },
    mess: {
      passLeast: "Password must be at least 7 characters",
    },
    addUser: "Add user",
    cancel: "Cancel",
    name: "Name",
    surname: "Surname",
    email: "Email",
    password: "Password",
    planOver: "Your tarrif plan is over",
    contUsing: "You can continue using LOVAT software when you subscribe.",
    selectPlan: "select plan",
    dot: ".",
    warning: "Warning",
  },
  due: {
    dueDates: "Due dates",
    yourDD: "Your due dates",
    dashboard: "Dashboard",
    typeOfRep: "Type of report",
    country: "Country",
    periodType: "Period type",
    yVat: "Yearly VAT",
    qVat: "Quarterly VAT",
    mVat: "Monthly VAT",
    period: "Period",
    expDate: "Expect date",
    notFilled: `You don't have filled VAT return.`,
  },
  news: {
    dashboard: "Dashboard",
    blog: "Blog",
    show: "Show more",
    del: "Delete",
    sure: "Are you sure you want to delete the news",
    add: "Add news",
    news: "News",
  },
  reports: {
    reports: "Reports",
    taxAudit: "Tax Audit",
    titles: {
      vatLiab: "VAT liabilities",
      vatLiabDesc:
        "If you like to check you VAT liabilities. Please note that this information are available not for every country. Please see FAQ section for more information.",
      vatObl: "View future VAT obligations",
      taxAudit: "Tax Audit report",
      taxAuditDesc:
        "Company must keep tax records for 10 years. If you uploaded transactions in LOVAT database they are available here for tax audit purposes during 10 years. We use special algorithms aligned with tax rules to determine customer location. If you need additional explanation you can contact our support center. Our tax experts would be pleased to help.",
    },
    country: "Country",
    from: "From",
    till: "Till",
    tillLC: "till",
    vatNumber: "VAT number",
    noTrans: "There are no transactions for this country in this period",
    buttons: {
      vatStatus: "Check VAT status",
      vatObl: "Check VAT obligations",
      taxAudit: "Create the tax audit report",
    },
    connectHMRC: "Please connect your HMRC account to submit declaration",
    connect: "Connect",
    close: "Close",
    showing: "Showing",
    of: "of",
    results: "results",
    transDate: "Transaction Date, Time",
    operator: "Operator",
    status: "Status",
    sum: "Amount",
    currency: "Currency",
    vat: "Vat",
    vatRate: "VAT Rate",
    vatType: "VAT type",
    custIP: "Customer IP",
    billingAddress: "Billing Address",
    payMethCountry: "Payment method country",
    custPhone: "Customer Phone Number",
    taxAuditTrans: "Tax Audit Transactions",
    allCountries: "All countries",
    forThePeriod: "for the period from",
    taxPeriod: "Tax Period",
    dueDate: "Due date",
    origAmount: "Original Amount (GBP)",
    outAmount: "Outstanding Amount (GBP)",
    period: "Period",
    periodKey: "Period Key",
    futureVatObl: "Future VAT obligations report",
    vlReport: "VAT liabilities report",
    hmrcInt: "HMRC integration",
    save: "Save",
    delete: "Delete",
    print: "Print",
    cancel: "Cancel",
    selectPlan: "Select plan",
  },
  taxSettings: {
    taxSettings: "Tax Settings",
    vatReg: "VAT",
    salesTax: "Sales tax",
    salesTaxPer: "Sales tax permits",
    permitBusinessName: "State Registered Business Name",
    nexusEstablished: "Nexus Established",
    stateAssignedPayment: "State Assigned Reporting Schedule",
    salesTaxPerDesc:
      "You may need to obtain a sales tax permit in states where you have economic nexus",
    addSalesTaxPer: "Add sales tax permit",
    calcThresh: "Calculate thresholds for economic nexus for all states",
    noData: "There is no data",
    exemp: "Exemptions",
    myExemp: "My exemptions",
    acceptRT: "Accept real-time customer-based exemptions",
    addExemp: "Add new exemption",
    addExempDoc: "Add new exemption document",
    waivForMP: "Waivers for Marketplace",
    addWaiv: "Add new waiver document",
    next: "Next",
    back: "Back",
    warning: "Warning",
    willLose: "You will lose all entered data.",
    state: "State",
    file: "file",
    taxExSert: "Tax exemption certificate",
    validFrom: "Valid from",
    validTill: "Valid till",
    active: "Active",
    chooseFile: "Choose file",
    salesTaxID: "Sales Tax ID",
    certType: "Certificate type",
    cancel: "Cancel",
    certNumber: "Certificate number",
    addFile: "Add file",
    autopilot: "Autopilot",
    autopilotDetails: "Autopilot (details)",
    vatReturnsAndSalesTax:
      "Here you will find returns settings",
    eprSettings: "EPR Settings",
    deleteSalesTaxPermit: "Delete sales tax permit",
    deleteExemption: "Delete exemption",
    deleteWaiver: "Delete waiver",
    salesTaxPermitNoData: "Add a Sales tax permit to see data",
    exemptionNoData: "Add a new exemption to see data",
    waiverNoData: "Add a new waiver to see data",
    countries: "Countries",
    country: "Country",
    stores: "Stores",
    store: "Store",
    autoSubSaved: "Auto-Submit settings have been saved",
    typeOfReport: "Type of report",
    saveAll: "Save all",
    save: "Save",
  },
  vatReg: {
    country: "Country",
    reasonForReg: "Reason for registration",
    vatNum: "VAT number",
    from: "From",
    till: "Till",
    active: "Active",
    edit: "Edit",
    thresh: "Threshold",
    vatRepr: "VAT representative",
    actions: "Actions",
    continue: "Continue",
    pay: "Pay",
    addVatReg: "Add a VAT",
    orderVatReg: "Order a VAT",
    enterVatNum: "Please enter VAT number",
    enterStNum: "Please enter Steuernummer",
    invalidVal: "Invalid value",
    editVatReg: "Edit VAT registration number",
    for: "for",
    in: "in",
    federalSt: "Federal state",
    stNum: "Steuernummer",
    numberValidTill: "Number valid till",
    lovatTaxRep: "LOVAT is my tax representative",
    calcThresh: {
      yes: "calculate threshold",
      no: "don`t calculate threshold",
    },
    vatRegPayment: "VAT registration payment",
    vatReg: "VAT registration",
    payment: "Payment",
    thankForReq:
      "Thank you for your request! You will be emailed with the invoice and further instructions within several hours.",
    validFrom: "Valid from",
    validTill: "Valid till",
    appLovat: "I'd like to appoint Lovat as my tax representative",
    taxRepThroughLovat:
      "The number will be not used for tax reporting through LOVAT",
    contactManager1: "Please, contact your personal manager",
    contactManager2: ", if you want to delete VAT number",
    vatRegOf: "Vat registration of",
    vatRegIn1: "VAT registration in",
    vatRegIn2: ", ",
    vatRegIn3: "",
    addRegInfo: "Add registration info",
    cancel: "Cancel",
    delete: "Delete",
    delVatREg: "Delete VAT registration order",
    wantDelFor:
      "Do you want to delete this not finished registration request for",
    err: "Error",
    yes: "Yes",
    no: "No",
    notFinished: "Not finished",
    calcThresholds: "Calculate distance sales thresholds for all countries",
    chooseFile: "Choose file",
    province: "Province",
    tin: "TIN number",
  },
  payment: {
    desc: "Description",
    unitPrice: "Unit price",
    qty: "Qty",
    total: "Total",
    promo: "Promo-code Discount",
    apply: "Apply",
    promoApplied: "Promo-code has been applied.",
    invalidePromo: "Invalide promo-code.",
    orderInv: "order an invoice",
    reqOfFunds: "Request of funds",
  },
  orderReg: {
    country: "Country",
    reasonForReg: "Reason for registration",
    lovatMyTaxRepr: "I want LOVAT to be my tax representative",
    steps: {
      intro: "Introduction",
      persInfo: "Personal information",
      business: "Business details",
      docs: "Documents",
      submit: "Submit",
    },
    discard: "Discard",
    next: "Next",
    back: "Back",
    vatReg: "VAT registration",
    orderVatReg: "Order VAT registration",
    vatRegIn1: "VAT registration in",
    vatRegIn2: "",
    vatRegIn1p: "VAT registration in",
    vatRegIn2p: ", ",
    vatRegIn3p: "",
    haveNotFinished: "You have not finnished registration request",
    createNewReq: "Create new request",
    cont: "Continue",
    wantCont: "Do you want to continue?",
    yourName: "Your name",
    prevName: "Previuos name",
    changeName: "Did you change the name? If yes - previuos name",
    dateOfBirth: "Date of birth",
    origCountry: "Country of origin",
    contacts: "Contacts",
    persAddress: "Personal address",
    postPersAddress: "Postcode and personal address (last 3 years)",
    yourEmail: "Your e-mail",
    phoneNum: "Phone number",
    ifAppl: "If applicable",
    nin: "National Insurance Number",
    utr: "Unique Taxpayer Reference",
    bussInfo: "Business information",
    crn: "Company Registration Number (CRN)",
    dateOfInc: "Date of incorporation",
    nameOfCorp: "Name of corporate body",
    compContacts: "Company contacts",
    compEmail: "Company e-mail",
    compPhoneNum: "Company phone number",
    compAddress: "Company address",
    orderPlaced: "Your order has been placed.",
    invWillBeSent: "The invoice for payment will be sent to your email soon.",
    await5wd: "We are awaiting for the payment within 5 working days.",
    uploadFiles: "Upload files",
    vatRegPayment: "VAT registration payment",
    payment: "Payment",
    thankForReq:
      "Thank you for your request! You will be emailed with the invoice and further instructions within several hours.",
    stepDocs: {
      attachDocs: "Please attach the following documents for the director",
      whichCopy:
        "One piece of government issued photographic identification, which can be a copy of:",
      passport: "passport",
      photo: "photocard driving licence",
      idCard: "national identity card",
      attachFiles: "Attach files:",
      secEvid:
        "Along with two pieces of secondary evidence which can be copies of:",
      mortState: "a mortgage statement",
      agree: "a lease/rental agreement",
      birthCertif: "a birth certificate",
      partCertif: "a marriage or civil partnership certificate",
      dissCertif: "a decree absolute of decree of dissolution certificate",
      attachFirst: "Attach the first piece files:",
      attachSecond: "Attach the second piece files:",
    },
    countryWantReg: "Country where you want to get registered",
    que: "Question",
    answ: "Answer",
    submitInfo: "Submit information",
    yes: "Yes",
    no: "No",
  },
  vatReturns: {
    title: "VAT Returns",
    statuses: {
      new: "new",
      pending: "pending",
      submitted: "submitted",
      declined: "declined",
      draft: "draft",
      autoSub: "auto-submitted",
    },
    q1: "1 quarter",
    q2: "2 quarter",
    q3: "3 quarter",
    q4: "4 quarter",
    decl: "VAT return",
    edit: "Edit",
    taxReturn: "Tax return",
    statusChanged: "Status has been successfully changed",
    selectStatus: "Please select the status to change",
    payVat: "Pay your VAT",
    next: "Next",
    pay: "Pay",
    country: "Country",
    stNum: "Steuernummer",
    amount: "Amount",
    ref: "Reference",
    paymentComp: "Payment completed!",
    iban: "IBAN",
    bic: "BIC (Bank Identification Code)",
    bankName: "Bank name",
    accName: "Account name",
    warning: "Warning",
    planOver: "Your tarrif plan is over",
    contUsing: "You can continue using LOVAT software when you subscribe.",
    selectPlan: "select plan",
    trialPeriod: "Trial period",
    notAvailable: "This function is not available in trial period",
    vatRetDetails: "VAT Return Details",
    ls: "List of sales",
    vatNumber: "VAT Number",
    salesType: "Sales type",
    forPeriod1: "For the period",
    forPeriod2: "for",
    submitDecl: "Submit declaration",
    submitLs: "Submit list of sales",
    submit: "Submit",
    wantSubmit1: "Do you want to submit declaration for",
    wantSubmit2: "for",
    wantSubmit3: "",
    clickObSubmit: `By clicking on the 'Submit' button, you agree to the following: When you submit this VAT information you are making a legal declaration that the information is true and complete. A false declaration can result in prosecution`,
    hmrcInt: "HMRC integration",
    connect: "Connect",
    connectHmrc: "Please connect your HMRC account to submit declaration",
    uploadFile: "Upload file",
    fileAdded: "File has been successfully added",
    addFile: "Add file",
    chooseFile: "Choose *pdf file to upload",
    period: "Period",
    nameDoc: "Name of doc",
    vatAmount: "VAT amount",
    currency: "Currency",
    fillDate: "Filling date",
    time: "Time",
    downloadFile: "Download file",
    view: "View",
    status: "Status",
    allCntr: "All Countries",
    fromQ: "From (Quarter)",
    fromY: "From (Year)",
    tillQ: "Till (Quarter)",
    tillY: "Till (Year)",
    fillVR: "Fill VAT Return",
    orderVP: "Order VAT payment",
    total: "Total",
    delete: "Delete",
    cancel: "Cancel",
    continue: "Continue",
    hereYouCanFill: "Here you can fill your VAT returns",
    close: "Close",
  },
  salesTaxReturns: {
    salesTax: "Sales TAX Returns",
    fillSalesTax: "Here you can fill your Sales TAX Returns",
    fillTaxReturn: "Fill TAX Return",
    createSalesTaxReturn: "Create Sales Tax return",
    pleaseAddYourTax:
      "Please add your TAX number for state in 'Sales Tax' section",
    clickObSubmit: `By clicking on the 'Submit' button, you agree to the following: When you submit this Sales Tax information you are making a legal declaration that the information is true and complete. A false declaration can result in prosecution`,
  },
  createReturns: {
    fillReturnParams: "Fill your return parameters",
    addVatNumber: `Please add your VAT number for country in 'VAT Registration' section`,
    reasonReg: "Reason for registration",
    discard: "Discard",
    next: "Next",
    vatReturn: "VAT Return",
    salesFromPrem: "Sales from premises",
    createVR: "Create VAT return",
    createSalesList: "Create Sales List",
    vatRetCreated: "Your VAT return was created (country code: ",
    totalSum: ", total amount: ",
    warning: "Warning",
    country: "Country",
    period: "Period",
    year: "Year",
    q1: "1 quarter",
    q2: "2 quarter",
    q3: "3 quarter",
    q4: "4 quarter",
    jan: "January",
    feb: "February",
    mar: "March",
    apr: "April",
    may: "May",
    june: "June",
    july: "July",
    aug: "August",
    sept: "September",
    oct: "October",
    nov: "November",
    dec: "December",
    distSales: "Distant sales",
    revExVat: "Revenue (excluding VAT)",
    vatSum: "VAT amount",
    rates: {
      standard: "Standard rate",
      reduced: "Reduced rate",
      nonstandard: "Nonstandard rate",
    },
    back: "Back",
    sum: "Amount",
    yes: "Yes",
    no: "No",
    buyFromSup1: "Did you buy your goods from supplier in",
    buyFromSup2: "",
    importOut: "Did you import your goods from outside the EU",
    purchIn1: "Purchases in",
    purchIn2: "",
    import: "Import",
    buyFromEU1: "Did you buy your goods from EU supplier outside of",
    buyFromEU2: "",
    supplyB2b: "Did you supply goods to VAT–registered B2B customers?",
    intraCommAc: "Intra community acquisitions",
    ifYouFill: `You have several transactions with zero amount. Please add the amount for these intra-community transactions. Please note that transactions with zero amount wouldn't be included in your VAT return`,
    vatNumCust: "VAT Number of customer",
    valueOfPurchOut: "Value of purchases (without VAT)",
    valueOfPurch: "Value of purchases",
    goodsOrServ: "Goods or Services",
    noB2bAvail: "No b2b data available",
    addManually: "Add transactions manually",
    addFromFile: "Add transactions from file",
    orderSL: "Order Sales list report",
    upgradeForSL: "Please upgrade the subscription to order Sales list report.",
    checkForm:
      "Please check all the figures if you are agree you can fill in the form",
    salesList: "Sales list",
    forThePeriod: "For the period",
    for: " for",
    fillTheForm: "FILL THE FORM",
    delete: "Delete",
    date: "Date",
    negNumberNot: "Negative number is not allowed",
    planOver: "Your tarrif plan is over",
    contUsing: "You can continue using LOVAT software when you subscribe.",
    selectPlan: "select plan",
    addRecord: "Add record",
    toTheFirst: "To the first step",
    willLose: "You will lose all entered data.",
    wantComeBack: "Do you want come back to the first step anyway?",
    countryCode: "country code",
    receivedReq: `We have well received your request of VAT return preparation.
    The VAT return will be in your account within 24 hours. You will be notified by email.`,
    changeSubPlan: "Change your subscription plan",
    returnParams: "Return parameters",
    outputVat: "Output VAT",
    inputVat: "Input VAT",
    advPayments: "Advance payments",
    intraComm: "Intra community",
    vatDue: "VAT due",
    numOfCust: "N of customs declaration",
    vatSumPayed: "VAT amount payed at customs",
    numOfInv: "Number of invoice",
    vatNumSupp: "VAT Number of supplier",
    rate: "Rate",
    dataEntered:
      "Data for this country and the tax rate have already been entered",
    vatDueSum: "Vat due summa",
    vatNumber: "VAT Number",
    vatReturnDetails: "VAT Return Details",
    amount: "Amount",
    boxOfUK: "Box # of UK VAT return",
    salesType: "Sales type",
    total: "Total",
    taxDue: "Tax due",
    err: "Error",
    addFile: "Add file",
    chooseFiles: "choose *.xlsx, *.xls files to upload",
    fileName: "file name",
    firstDataRow: "First data row number",
    lastDataRow: "Last data row number",
    chooseRows: "Choose first data and last data row",
    matchEach:
      "Match each column in your import file with system headers. Showing first data row from your file",
    uploadTrans: "Upload transactions",
    typeOfRep: "Type of report",
    currency: "Currency",
    description: "Description",
  },
  stores: {
    store: "Store",
    stores: "Stores",
    addStore: "Add store",
    removedTest:
      "You are about to end the trial phase of your website. This means all test transactions of the website will be removed and all new transactions will be billed. Are you sure you are ready to proceed",
    cancel: "Cancel",
    reset: "Reset",
    reseting: "Reseting",
    status: "Status",
    update: "Update",
    privPol: "Privacy Policy",
    domestic: "Domestic Country",
    contact: "Please, contact our",
    supportTeam: "support team",
    dot: ".",
    resetToken: "Are you sure want to reset token",
    oldToken:
      "New transactions with old token will not be accepted, be sure to change it as soon as possible",
    activating: "Activating",
    warning: "Warning",
    del: "Delete",
    activate: "Activate",
    setting: "Setting",
    needAddStore: "You need to add your site, to start work with Lovat.",
    aware: "I am aware that all transactions of this store will be deleted.",
    specStore: "The specified store",
    exists: "already exists.",
    planOver: "Your tarrif plan is over",
    contUsing: "You can continue using LOVAT software when you subscribe.",
    selectPlan: "select plan",
    sureDel1: "Are you sure you want to delete the store?",
    sureDel2: "Are you sure you want to delete the project?",
    token: "Access Token",
    addStoreError: "Error adding store",
    err: {
      emailInvalid: "Invalid email",
    },
    hereYouCanManage:
      "Here you can manage all your stores. You can add new ones or remove old ones and make changes to configurations.",
    typesOfGoods: "Types of goods or services",
    typesOfGoodsNotSelected: "Types of goods or services not selected",
    youCanAddTypesOfGoods: "You can add types of goods or services",
    tableOfGoods: "Table of goods and services",
    select: "Select",
    selectTypesOfGoods: "Select types of goods and services",
    addNewGood: "Add new good or service",
    delete: "Delete",
    addNew: "Add new",
    projectName: "Project name:",
    storeName: "Store name:",
    projectUrl: "Project url",
    storeUrl: "Store url",
    integrationWasSuccessfull: "Integration was successfull!",
    connectProject: "Connect project",
    connectStore: "Connect store",
    cancelIntegration: "Cancel integration",
    discard: "Discard",
    saveChanges: "Save changes",
    editSettings: "Edit settings",
    project: "Project",
    approvalOfProject:
      "The approval of the project may take up to 2 working days.",
    approvalOfStore: "The approval of the store may take up to 2 working days.",
    addProject: "Add project",
    specProject: "The specified project",
    needAddProject: "You need to add your project, to start work with Lovat.",
    selectStatus: "Please select the status to change",
    change: "Change",
    aware2: "I am aware that all transactions of this project will be deleted.",
    departureState: "Default departure state:",
    departureZip: "Domestic departure zip:",
    dataSuccessfullyChanged: "Data has been successfully changed",
    platform: "Platform",
    defaultWarehouse: "Default warehouse country:",
    integration: "Integration",
    currency: "Currency",
    token: "Token",
    sellerCentralCountry: "Seller Central Country",
    apiKey: "Api key",
    accessSecret: "Access secret",
    accessToken: "Access token",
    clientSecret: "Client secret",
    clientKey: "Client key",
    clientId: "Client id",
    type: "Type",
    code: "Code",
    desc: "Description",
    add: "Add",
    yourAmazonRegEmail: "Your Amazon Registered Email",
  },
  addStore: {
    stores: "Stores",
    addStore: "Add store",
    enterName: "Please enter store name",
    enterCountry: "Please enter country",
    enterCurrency: "Please enter currency",
    doYouSell: "Do you sell via marketplace?",
    doYouSellDesc:
      "You can connect you marketplace account or add your website manually.",
    yesIsell: "Yes, I sell through Marketplace",
    noIhave: "No, I have custom store",
    chooseInt: "Choose integration",
    chooseIntDesc1:
      "You can connect webservice you already use to integrate you orders and transactions data for automative VAT calculation.",
    chooseIntDesc2:
      "Marketplace and shopping carts integration is in development. If the system you use is not represented here, you can contact our ",
    chooseIntDescST: "support team.",
    availableWS: "Available web services",
    inDev: "Services in development",
    shopify: {
      title: "Enter your Shopify store URL",
      step1:
        "Enter your Shopify store URL or install directly at Shopify app store",
      step2: "Log in your Shopify account",
      step3: "Click on “Add App” button",
    },
    amazon: {
      title: "Connect your Amazon account",
      connect: "Connect Amazon",
    },
    ebay: {
      title: "Enter your eBay store name",
    },
    etsy: {
      title: "Enter your Etsy store name",
      desc1: `The term 'Etsy' is a trademark of Etsy, Inc.`,
      desc2:
        "This application uses the Etsy API but is not endorsed or certified by Etsy, Inc.",
    },
    storeURL: "Store URL",
    storeName: "Store name",
    connectStore: "Connect store",
    provideWith: "Please provide with site parameters",
    inModeration:
      "Your submission is in moderation. We’ll notify you when all done.",
    typesOf: "Types of services",
    submitStore: "Submit store",
    dmCountry: "Domestic Country",
    dmCurrency: "Default Currency",
    discard: "Discard",
    err: {
      emailInvalid: "Invalid email",
    },
    enterWooComm: "Enter your WooCommerce data",
    hereYouCanConnect: "Here you can connect your store",
  },
  transactions: {
    transactions: "Transactions",
    save: "Save",
    print: "Print",
    fillReturn: "Fill the return",
    addTrans: "Add Transactions",
    allWbs: "All Websites",
    allCntr: "All Countries",
    all: "All",
    showing: "Showing",
    of: "of",
    results: "results",
    confDel: "Confirm Delete",
    areYouSureDel1: "Are you sure you want to delete",
    areYouSureDel2: "",
    del: "Delete",
    close: "Close",
    warning: "Warning",
    planOver: "Your tarrif plan is over",
    contUsing: "You can continue using LOVAT software when you subscribe.",
    selectPlan: "select plan",
    transID: "Transaction ID",
    date: "Date",
    time: "Time",
    status: "Status",
    store: "Store",
    country: "Country",
    currency: "Currency",
    sum: "Amount",
    vatRate: "VAT Rate",
    vat: "VAT",
    from: "From",
    till: "Till",
    mess: {
      err: {
        part1: "An error occurred while importing transactions from",
        part2: "for the period from",
        part3: "to",
        part4: "by",
        part5: "",
      },
      in: {
        part1: "transaction data for the period from",
        part2: "to",
        part3: "by",
        part4: "during the upgrade process",
      },
      ready: {
        part1: "transaction data for the period from",
        part2: "to",
        part3: "by",
        part4: "has been successfully updated",
      },
    },
    onThisPage:
      "On this page you will find the history of all your transactions",
  },
  addTransactions: {
    transactions: "Transactions",
    addTrans: "Add Transactions",
    website: "Website",
    selectWebsite: "Please select a website",
    transUploaded: "Transaction are successfully uploaded",
    validFrom: "Valid from",
    validTill: "Valid till",
    obtData1: "Obtaining data from",
    obtData2: "",
    sendReq: "Sending request",
    transUpTo: "Transactions data is up to date",
    beReadyUp1: "Be ready",
    beReadyUp2:
      "update can take 5 - 30 minutes - it depends on number of transactions",
    typeOfSales: "Type of sales",
    transImported1: "transactions were successfully imported from",
    transImported2: "",
    digServ: "Digital services",
    goods: "Goods",
    needCalc: "I need to calculate tax for these transactions",
    haveAll: "I have all tax calculated",
    firstDataRow: "First data row number",
    lastDataRow: "Last data row number",
    validFormats: "Valid file formats",
    goTrans1: "Go to the",
    goTrans2: "transactions section",
    goTrans3: "and set your period to see the uploaded transactions",
    addFile: "Add file",
    fileName: "file name",
    chooseFiles: "choose *.xlsx, *.xls files to upload",
    chooseRows: "Choose first data and last data row",
    matchEach:
      "Match each column in your import file with system headers. Showing first data row from your file",
    learnMore: "Learn more about adding transactions",
    params: "Parameters",
    fileHeaders: "File Column Headers",
    transPrev: "Transaction Preview",
    consThresh: "Consider thresholds for VAT calculation",
    lastUpload: "Last uploading from",
    was: "was",
    howUpload: "How to Upload",
    chooseImportParams: "Choose import parameters",
    discard: "Discard",
    next: "Next",
    uploadTrans: "Upload transactions",
    importTrans: "Import transactions",
    chooseFile: "Choose file",
    selectRange: "Select range",
    colMatch: "Columns matching",
    hereYouCanAdd: "Here you can add your transactions",
  },
  referral: {
    refProgrram: "Referral program",
    refAFr: "Refer-A-Friend",
    inviteFr: "Invite Friends & Get 15€ for their Order",
    copy: "Copy your personal invite link",
    or: "or",
    inviteEmail: "Invite via email",
    invite: "Invite",
    balance: "Balance",
    yourBalance: "Your balance is",
    spend: "You can spend it to pay for LOVAT services",
    invitations: "Your Invitations",
    email: "Email",
    status: "Status",
    invSent: "Your invitation was sent successfully",
    noRef: `You don't have any referrals`,
  },
  eprSettings: {
    regNum: "Registration number",
    typeOfReg: "Type of registration",
    sysOperator: "System operator",
    regDate: "Registration date",
    status: "Status",
    licPaid: "License paid",
    licPaidFrom: "License paid from",
    licPaidTill: "License paid till",
    addTransToSee: "Add transactions to see data",
    addTrans: "Add transactions",
    addNewEPR: "Add new EPR",
  },
  subscription: {
    subPlans: "Subscription plans",
    selectPlan: "Select plan",
    addons: "Addons",
    addonName: "Addon name",
    from: "From",
    till: "Till",
    country: "Country",
    subs: "Subscribe",
    orderInv: "Order an invoice",
    iAgree: "I agree the payment will be forwarded to my Shopify account.",
    plan: "Plan",
    price: "Price",
    quantity: "Quantity",
    total: "Total",
    subTotal: "Total",
    promoDisc: "Promo-code Discount",
    enterPromo: "Enter promo-code",
    apply: "Apply",
    promoApplied: "Promo-code has been applied.",
    promoInvalid: "Invalid promo-code",
    payment: "Payment",
    thanksForReq:
      "Thank you for your request! You will be emailed with the invoice and further instructions within several hours.",
    planPrices: {
      whenAnnually: "Monthly price when billed annually",
      monthly: "Monthly price",
      integ: "Integrations",
      totalTrans: "Total value of transactions per month",
      numberOfTrans: "Number of transactions per month",
      threshold: "Threshold calculation",
      list: "ES list of sales",
      intrastat: "Intrastat",
      support: "Consultations support",
      countries: "Countries",
      packaging: "Packaging",
    },
    detailsPlan: "Details of the selected plan",
    detailsAddons: "Details of the selected addons",
    commentForOrder: "Comment for the order",
    included: "included",
    add: {
      addCountry: "Each additional country",
      addTrans: "Additional number of transactions (per 5 000)",
      addForEach: "Additional value for each 100.000",
      list: "Sales list per country",
      support: "Consultations support per hour",
      taxRepr: "Tax representative",
      countries: "Selected countries",
    },
    jan: "January",
    feb: "February",
    mar: "March",
    apr: "April",
    may: "May",
    june: "June",
    july: "July",
    aug: "August",
    sept: "September",
    oct: "October",
    nov: "November",
    dec: "December",
    janSh: "Jan",
    febSh: "Feb",
    marSh: "Mar",
    aprSh: "Apr",
    maySh: "May",
    juneSh: "Jun",
    julySh: "Jul",
    augSh: "Aug",
    septSh: "Sep",
    octSh: "Oct",
    novSh: "Nov",
    decSh: "Dec",
    annually: "Annually",
    monthly: "Monthly",
    required: "required",
    hoursPer1: "",
    hoursPer2: "hours per month",
    mostPopular: "MOST POPULAR",
    addInput: {
      addCountry: "Additional country",
      addTrans: "Additional transactions",
      addRev: "Additional revenue",
      list: "List of sales",
      support: "Consultations support",
      taxRepr: "Tax rep",
    },
  },
  addons: {
    buyAddons: "Buy addons",
    from: "From",
    till: "Till",
    country: "Country",
    annually: "Annually",
    monthly: "Monthly",
    subs: "Subscribe",
    orderInv: "Order an invoice",
    iAgree: "I agree the payment will be forwarded to my Shopify account.",
    plan: "Plan",
    price: "Price",
    quantity: "Quantity",
    total: "Total",
    subTotal: "Sub.total",
    promoDisc: "Promo-code Discount",
    enterPromo: "Enter promo-code",
    apply: "Apply",
    promoApplied: "Promo-code has been applied.",
    promoInvalid: "Invalid promo-code",
    payment: "Payment",
    thanksForReq:
      "Thank you for your request! You will be emailed with the invoice and further instructions within several hours.",
    add: {
      addCountry: "Each additional country",
      addTrans: "Additional number of transactions (per 5 000)",
      addForEach: "Additional value for each 100.000",
      list: "Sales list per country",
      support: "Consultations support per hour",
      taxRepr: "Tax representative",
    },
    addInput: {
      addCountry: "Additional country",
      addTrans: "Additional transactions",
      addRev: "Additional revenue",
      list: "List of sales",
      intrastat: "Intrastat",
      support: "Consultations support",
      taxRepr: "Tax rep",
    },
    jan: "January",
    feb: "February",
    mar: "March",
    apr: "April",
    may: "May",
    june: "June",
    july: "July",
    aug: "August",
    sept: "September",
    oct: "October",
    nov: "November",
    dec: "December",
    janSh: "Jan",
    febSh: "Feb",
    marSh: "Mar",
    aprSh: "Apr",
    maySh: "May",
    juneSh: "Jun",
    julySh: "Jul",
    augSh: "Aug",
    septSh: "Sep",
    octSh: "Oct",
    novSh: "Nov",
    decSh: "Dec",
    currentPlan: "Current plan",
    infoCurrent: "Information about the current tariff plan",
    tariffPlan: "Tariff plan",
    yourMemb1: "Your membership is",
    yourMemb2: " / month",
    addons: "Addons",
    selectAddons: "You can select additional addons here.",
    detailsAddons: "Details of the selected addons",
    fillInDetails: "Please fill in details of the selected addons",
    notSelected: "No addons are selected",
    addonName: "Addon name",
    required: "required",
  },
  signUp: {
    signIn: "Sign In",
    connectErr: "Connection error",
    invalidEmail: "Invalid email",
    enterName: "Please enter name",
    enterSurname: "Please enter surname",
    enterCompany: "Please enter company name",
    enterPhone: "Please enter phone",
    enterCountry: "Please enter country",
    phoneMust: 'The phone number must start with "+" and consist of digits',
    email: "Email",
    country: "Country",
    name: "Name",
    surname: "Surname",
    companyName: "Company Name",
    companyEmail: "Company Email",
    sameAsLogin: "Same as Login",
    phone: "Phone",
    signUp: "Sign Up",
    finishReg: "Finish Registration",
    weSent1:
      "We’ve sent you email with instruction to follow. If you didn’t recieve it, we can",
    weSent2: "resend a new email",
    haveAcc: "Have an account?",
  },
  forgotPassword: {
    signIn: "Sign In",
    email: "Email",
    password: "Password",
    invalidEmail: "Invalid email",
    enterEmail: "Please enter email",
    connectErr: "Connection error",
    passNotMatch: `Passwords don't match`,
    enterPass: "Please enter password",
    repeatPass: "Please repeat the password",
    passRecovery: "Password Recovery",
    backTo: "Back to",
    recover: "Recover",
    weSent1:
      "We’we sent you email with instruction to follow. If you didn’t recieve it, we can",
    weSent2: "resend a new email",
    finishPassRec: "Finish Password Recovery",
    newPass: "New password",
    repPass: "Repeat password",
    recLinkInvalid: "Password recovery link is invalid or expired.",
    passChanged: "Password has been successfully changed",
    passLeast: "Your password must be at least 7 characters",
  },
  activation: {
    signIn: "Sign In",
    userActivation: "User Activation",
    activationInProcess: "Please wait. Activation is in process.",
    linkInvalid: "Activation link is invalid or expired.",
  },
  billing: {
    title: "Billing history",
    dateTime: "Date",
    action: "Action",
    number: "Number",
    amount: "Amount",
    showing: "Showing",
    of: "of",
    results: "results",
    pay: "Pay",
    cancel: "Cancel",
    status: "Status",
    delete: "Delete",
    edit: "Edit",
    description: "Here you will find all your invoices",
    addDocument: "Add document",
    addPayment: "Add payment",
    addInvoice: "Add invoice",
    updateXero: "Update Xero",
    invoices: "Invoices",
    payments: "Payments",
    xeroInvoicesUpdated: "Xero invoices has been successfully updated",
    xeroInvoicesError: "Xero invoices update error",
    xeroPaymentsUpdated: "Xero payments has been successfully updated",
    xeroPaymentsError: "Xero payments update error",
    xeroInvoicesUpdating: "Xero invoices updating",
    xeroPaymentsUpdating: "Xero payments updating",
    noInvoices: "You don't have any invoices yet",
    addFile: "Add file",
    choosePdf: "Choose *pdf file to upload",
    currency: "Currency",
    sum: "Amount",
    invoiceNumber: "Invoice number",
    editInvoice: "Edit invoice",
    doYouWantToPayCharge: "Do you want to pay Charge invoice from",
    for: "for",
    confirmDelete: "Confirm Delete",
    areYouSureDel: "Are you sure you want to delete",
    company_name: "Company name",
    client: "Client",
  },
  help: {
    support: "Support",
    merch: "Merchants Questionnaire",
    licence: "Licence agreement",
    yourMess: "Your messages",
    goToSup: "Go to support messages",
    supportTeam: "Merchant support team",
    supportTeamDesc: "If you have any questions please contact us via",
    phone: "Phone",
    email: "Email",
    hours: "Office hours: weekdays 9am",
    fill: "Fill the subject",
    openCase: "Open case",
    pm: "Personal Manager",
    name: "Name",
    skype: "Skype",
  },
  supportMessages: {
    supportMess: "Support messages",
    support: "Support",
    caseNum: "Case number",
    desc: "Short description",
    status: "Status",
    dateUp: "Date updated",
    openCase: "Open case",
    info: "info",
    actionR: "action required",
    pendingLovat: "pending lovat action",
    answered: "answered",
  },
  dialog: {
    supportMess: "Support messages",
    case: "Case",
    info: "info",
    actionR: "action required",
    pendingLovat: "pending lovat action",
    answered: "answered",
    addFile: "Add file",
    addCommMess: "Please add a comment",
    addComm: "Add comment",
    chooseFiles: "Choose *jpg, *jpeg, *png files to upload",
  },
  case: {
    supportMess: "Support messages",
    openCase: "Open case",
    fillIn: "Please fill in your case",
    desc1:
      "To ensure prompt response, fill out the form completely and as accurately as possible.",
    desc2:
      "Lovat support team makes every effort possible to respond to customer issues in a timely manner.",
    caseGroup: "Case group",
    desc: "Short description",
    status: "Status",
    comments: "Comments",
    addFile: "Add file",
    chooseFiles: "Choose *jpg, *jpeg, *png files to upload",
    errFillCase: "Fill the case title",
    errFillDesc: "Fill the description",
    themes: {
      stores: "Stores",
      trans: "Transactions",
      vatRet: "VAT Returns",
      vatReg: "VAT Registration",
      documents: "Documents",
      taxAudit: "Tax Audit",
      other: "Other",
    },
    caseOpened: "Case has been opened",
  },
  threshold: {
    dashboard: "Dashboard",
    notFilled: `You don't have filled TAX return.`,
    thresholdsFor1: "Thresholds for ",
    thresholdsFor2: " year",
    us: "US",
    eu: "EU",
    row: "ROW",
    fed: "Federal",
    rowFull: "Rest of world",
    calc: "Calculate",
    janSh: "Jan",
    febSh: "Feb",
    marSh: "Mar",
    aprSh: "Apr",
    maySh: "May",
    juneSh: "Jun",
    julySh: "Jul",
    augSh: "Aug",
    septSh: "Sep",
    octSh: "Oct",
    novSh: "Nov",
    decSh: "Dec",
    state: "State",
    country: "Country",
    countryReg: "Country (region)",
    thresholdSum: "Threshold amount",
    factSum: "Fact amount",
    thresholdTrans: "Threshold transaction",
    factTrans: "Fact transaction",
    type: "Type",
  },
  vatPayment: {
    q1: "1 quarter",
    q2: "2 quarter",
    q3: "3 quarter",
    q4: "4 quarter",
    jan: "January",
    feb: "February",
    mar: "March",
    apr: "April",
    may: "May",
    june: "June",
    july: "July",
    aug: "August",
    sept: "September",
    oct: "October",
    nov: "November",
    dec: "December",
    eur: "EUR",
    usd: "USD",
    gbr: "GBP",
    vatRet: "VAT Returns",
    orderVP: "Order VAT payment",
    vatPaymentForm: "VAT Payment form",
    fillParam:
      "Fill your payment parameters a commission for payment is 50 Eur. Upon submitted form you will receive a Request of funds. If requested currency changes from currency of chosen country additional charges may apply.",
    addVatRegData: "Please add your VAT registration data",
    here: "here",
    paymentCurr: "Payment currency",
    paymentCurrLC: "payment currency",
    payment: "Payment",
    ok: "Ok",
    thankForReq:
      "Thank you for your request! You will be emailed with the invoice and further instructions within several hours.",
    country: "Country",
    year: "Year",
    vatSum: "VAT amount",
    period: "Period",
    order: "Order",
    wantReq: "I want a Request of funds in another currency",
    vatPayment: "VAT payment",
  },
  messages: {
    messages: "Messages",
    description:
      "Here you can see important messages from tax authorities and Lovat team.",
    addMessage: "Add message",
    downloadAttached: "Download attached files",
    addToMarkers: "Add to markers",
    viewDetails: "View details for",
    cancel: "Cancel",
    delete: "Delete",
    doYouWantToDel: "Do you want to delete message",
    deleteMessage: "Delete message",
    noMessages: "There are no messages yet",
    youWillBeInformed: "You will be informed when any appear",
    nothingFound: "Nothing found",
    tryToChange: "Try to change your filters",
    findMessages: "Find messages",
    all: "All",
    new: "New",
    marked: "Marked",
    filter: "Filter",
    backToMessages: "Back to messages",
    messageFrom: "Message from",
  },
  noDataMessage: {
    nothingToShowHere: "Nothing to show here yet!",
  },
  datepicker: {
    dateRange: "Date range:",
    cancel: "Cancel",
    apply: "Apply",
  },
  userGuide: {
    goToUserGuide: "Go to user guide",
    completed1: "1 of 4 tasks completed!",
    completed1_desc:
      "That’s it! Your profile is filled & ready. Go to user guide to continue setting everything up!",
    completed2: "2 of 4 tasks completed!!",
    completed2_desc:
      "That’s it! Your store is successfully added. Go to user guide to continue setting everything up! ",
    completed3: "3 of 4 tasks completed!!",
    completed3_desc:
      "That’s it! Your sales tax permit is successfully added. Go to user guide to finish setting everything up!",
    completed4: "4 of 4 tasks completed!!",
    completed4_desc:
      "That’s it! Transaction is successfully added. Congratulations on settings everything up!",
  },
  addClient: {
    companyName: "Company Name",
    address: "Address",
    companyEmail: "Company’s E-mail",
    phone: "Phone",
    phoneMust: 'The phone number must start with "+" and consist of digits',
    vatNum: "VAT number",
    postcode: "Postcode",
    country: "Country",
    cityTown: "City/Town",
    strAddress: "Street Address",
    housenum: "House number",
    apt: "Apartment",
    chooseFile: "Choose file",
    name: "Name",
    surname: "Surname",
    addUser: "Add user",
    email: "Email",
    password: "Password",
    cancel: "Cancel",
    err: {
      pass: "Por favor, introduzca contraseña",
      passLeast: "Your password must be at least 7 characters",
      emailInvalid: "Invalid email",
    },
  },
  modalWelcomeToLovat: {
    title: 'Welcome to LOVAT',
    textOneLine: 'Congratulations on joining LOVAT, your gateway to stress-free tax compliance for online selling.',
    textTwoLine: 'We\'re thrilled to have you on board! 🎉',
    textThereLine: 'Book a demo with a Lovat expert to see how the platform works and get all your questions answered',
    textThereLineLink: 'here.',
    textFourLine: 'Experience a 14-day free trial or secure your subscription plan today.',
    buttonPrimary: 'Try free for 14 days',
    or: 'or',
    purchaseNow: 'purchase now',
  },
  eprReporting: epr,
  dac7: dac7,
  report1099,
};

export default en;
