	import { AppProvider } from "@shopify/polaris";
	import React from "react";
	import ReactDOM from "react-dom/client";
	import { Provider } from "react-redux";
	import { BrowserRouter, Link as ReactRouterLink } from "react-router-dom";
	import { Elements } from '@stripe/react-stripe-js'
	import App from "./App";
	import "./i18n";
	import "@shopify/polaris/build/esm/styles.css";
	import "rc-pagination/assets/index.css";
	import { store } from "./redux";
	import reportWebVitals from "./reportWebVitals";
	import "./index.scss";
	import '@shopify/polaris/build/esm/styles.css';
	import { loadStripe } from "@stripe/stripe-js"
	const stripePromise = loadStripe("your-publishable-key")
	
	const root = ReactDOM.createRoot(document.getElementById("root"));
	
	root.render(
		<Elements stripe={stripePromise}>
			<Provider store={store}>
		    <BrowserRouter basename='/'>
		      <AppProvider linkComponent={Link}>
		        <App />
		      </AppProvider >
		    </BrowserRouter >
		  </Provider >
		</Elements>
	);
	
	const IS_EXTERNAL_LINK_REGEX = /^(?:[a-z][a-z\d+.-]*:|\/\/)/;
	
	function Link({children, url = "", external, ref, ...rest}) {
		if (external || IS_EXTERNAL_LINK_REGEX.test(url)) {
			rest.target = "_blank";
			rest.rel = "noopener noreferrer";
			return (
				<a href={url} {...rest}>
	        {children}
	      </a >
			);
		}
		
		return (
			<ReactRouterLink to={url} {...rest}>
	      {children}
	    </ReactRouterLink >
		);
	}
	reportWebVitals();
