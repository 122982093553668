import React from "react";
import PropTypes from "prop-types";
import "./index.scss";
import { Box, Button, Text } from "@shopify/polaris";

const Segmented = ({ segments, activeSegment, onSegmentClick, label }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="1"
      width="100%"
    >
      {label && <Text>{label}</Text>}
      <div className='segmented'>
        {segments.map((segment) => (
          <div
            key={segment.value}
            className={`segmented-button ${
              activeSegment === segment.value ? "active" : ""
            }`}
            onClick={() => onSegmentClick(segment.value)}
          >
            <Button variant={'plain'} removeUnderline>
              {segment.label}
            </Button>
          </div>
        ))}
      </div>
    </Box>
  );
};

Segmented.propTypes = {
  segments: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  activeSegment: PropTypes.string,
  onSegmentClick: PropTypes.func,
  label: PropTypes.string,
};

export default Segmented;
