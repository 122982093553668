import { Banner, Box, Button, ButtonGroup, FormLayout, Inline, Select, Text, } from "@shopify/polaris"
import Modal from "components/Modal"
import useAppDispatch from "hooks/useAppDispatch"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { formatDateForServer } from "utils/dates"
import formatRow from 'utils/formatRow'
import paginate from 'utils/paginate'
import { sendingEinvoices } from '../../../../redux/features/documents/documentsSlice'
import StepChangeDate from "./StepChangeDate"
import './sendEIvoicesStyle.scss'
import StepChangeMultiple from './StepChangeMultiple'
import columnsFormSendEInvoices from './TablForStepChangeMultiple'

const methodsData = [
	{value: "1", label: "Date"},
	{value: "2", label: "Multiple"},
]

const SendFormEInvoicesBuilder = (props) => {
	const [fromDate, setFromDate] = useState("")
	const [toDate, setToDate] = useState("")
	const [changeMethod, setChangeMethod] = useState("1")
	const {
		sendingEinvoicesError,
		sendingEinvoicesLoading
	} = useSelector((state) => state.documents)
	const {onClose, afterUpload, isOpen} = props
	const navigate = useNavigate()
	const dispatch = useAppDispatch()
	
	const changeMethodHandler = (value) => {
		setChangeMethod(value)
		setFromDate('')
		setToDate('')
	}
	
	useEffect(() => {
		setFromDate('')
		setToDate('')
		setChangeMethod('1')
	}, [onClose])
	
	const onUploadClick = () => {
		const tehData = {
			date_from: changeMethod === '1' ? formatDateForServer(fromDate || new Date()) : '',
			date_till: changeMethod === '1' ?formatDateForServer(toDate || new Date()) : '',
			invoices_ids: changeMethod === '1' ? [] : props.selectedRows,
		}
		// console.log('tehData ===', tehData)
		try {
			const res = dispatch(sendingEinvoices(tehData))
			if (res.status === 200) {
				afterUpload()
				onClose()
				props.handleSelectAll([])
			}
		} catch (e) {
		
		}
	}
	
	const onlyChangeRows = props.documents.filter(row => props.selectedRows.find(id => id === row.id))
	
	const columns = columnsFormSendEInvoices(props.selectedRows, props.handleSelectAll, props.handleRowSelect, props.query, props.getBadgeStatus, props.renderActions, props.invoiceTypes)
	
	const sortedRows = formatRow(onlyChangeRows, columns)
	const paginatedRows = paginate(props.pagination)(sortedRows)
	const {rows} = paginatedRows
	return (
		<div className='sendEinvoices'>
			<Modal
				title='Sending E-invoices?'
				description='Here you can select multiple Sending Einvoices. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium, natus.'
				visible={isOpen}
				onClose={onClose}
				content={
					<div className='sendEinvoices-content'>
	          <FormLayout >
	            <Select
		            label={"Change Method"}
		            options={methodsData}
		            value={changeMethod}
		            onChange={(value) => changeMethodHandler(value)}
	            />
	          </FormLayout >
						<div className='sendEinvoices-wrapper'>
							{changeMethod === "1"
								&&
								(
									<StepChangeDate
										fromDate={fromDate}
										setFromDate={setFromDate}
										toDate={toDate}
										setToDate={setToDate}
									/>
								)}
							{changeMethod === "2"
								&&
								(
									<StepChangeMultiple
										rows={rows}
										columns={columns}
									/>
								)
							}
						</div >
	        </div >
				}
				footer={
					<>
	          {sendingEinvoicesError?.message === "no_integration" && (
		          <>
	              <Banner tone='critical'>
	                <Box display="flex" alignItems="space-between">
	                  <Text >You have no integration with Xero!</Text >
	                  <Button
		                  variant={'plain'}
		                  onClick={() => navigate("/stores")}
	                  >
	                    Go to stores
	                  </Button >
	                </Box >
	              </Banner >
	              <br />
	            </>
	          )}
						<ButtonGroup fullWidth>
	            <Button
		            size='large'
		            onClick={onClose}
	            >
	              Cancel
	            </Button >
	            <Button
		            size='large'
		            onClick={onUploadClick}
		            loading={sendingEinvoicesLoading}
		            variant={'primary'}
		            disabled={changeMethod === '1' ? fromDate === '' : props.selectedRows.length === 0}
	            >
	              Upload
	            </Button >
	          </ButtonGroup >
	        </>
				}
			/>
		</div >
	)
}

export default SendFormEInvoicesBuilder
