import { t } from 'i18next'
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import {
  Page,
  Layout,
  Card,
  TextField,
  Button,
  Select,
  Text,
} from "@shopify/polaris";
import { connect } from "react-redux";
import withRouter from "helpers/withRouter";
import moment from "moment";
import { formatDateForServer } from "utils/dates";
import PageHelmet from "components/PageHelmet";
import Datepicker from "components/Datepicker/Datepicker";
import { createCustomDispatch } from "helpers/customDispatch";
import GoBackPageNavigation from '../../../components/GoBackPageNavigation/GoBackPageNavigation'
import { createNews } from '../../../redux/features/dashboard/dashboardSlice'

const defaultDates = {
  date: moment().toDate(),
};

class AddNewsItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      description: "",
      text: "",
      title: "",

      month: new Date().getMonth(),
      year: new Date().getFullYear(),
      selected: defaultDates.date,
      lang: "en",
    };
  }

  doAddNews = () => {
    const { text, description, title, selected, lang } = this.state;
    if (!text || !description || !title) {
      return;
    }
    const formData = {
      date: formatDateForServer(selected),
      title,
      description,
      text,
      lang,
    };
    this.props
      .createNews({ ...formData })
      .then(() => {
        this.props.navigate("/dashboard/news");
      })
      .catch((err) => {
        this.setState({ errorAddNews: err.error });
      });
  };

  render() {
    const { title, text, description, lang } = this.state;

    return (
      <Page
        fullWidth
        separator
        title={
          <GoBackPageNavigation content={
            <Text variant='heading3xl' as='span'>
            Add News
          </Text>
          } />
        }
      >
        <PageHelmet title={`Add news`} />
        <br />
        <Layout>
          <Layout.Section>
            <Card sectioned>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Datepicker
                  allowRange={false}
                  label='Date:'
                  value={this.state.selected}
                  onChange={(date) => {
                    this.setState({
                      selected: date,
                    });
                  }}
                />

                <Select
                  label='Language'
                  options={[
                    { label: "English", value: "en" },
                    { label: "Spanish", value: "es" },
                  ]}
                  onChange={(value) => this.setState({ lang: value })}
                  value={lang}
                />
              </div>
              <br />
              <TextField
                label='Title'
                value={title}
                onChange={(value) => this.setState({ title: value })}
              />
              <br />
              <TextField
                label='Short description'
                value={description}
                onChange={(value) => this.setState({ description: value })}
                multiline={4}
              />
              <br />
              <TextField
                label='Text'
                value={text}
                onChange={(value) => this.setState({ text: value })}
                multiline={10}
              />
              <br />
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                <br />
                <Button
                  disabled={!title || !text || !description}
                  variant={'primary'}
                  onClick={this.doAddNews}
                >
                  Add news
                </Button>
              </div>
            </Card>
          </Layout.Section>
        </Layout>
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = (defaultDispatch) => {
  const dispatch = createCustomDispatch(defaultDispatch);

  return {
    createNews: (data) => dispatch(createNews(data)),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(AddNewsItem))
);
