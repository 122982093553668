import React, { useEffect, useState } from "react";
import {
  Banner,
  Button,
  ButtonGroup,
  Card,
  FormLayout,
  Layout,
  Text,
  TextField,
} from "@shopify/polaris";
import { useSelector } from "react-redux";
import { isValidPassword } from "utils/validation";
import { useTranslation } from "react-i18next";
import ToggleSwitch from "components/ToggleSwitch/ToggleSwitch";
import iosQrIMG from "img/ios-qr.png";
import androidQrIMG from "img/android-qr.png";
import useAppDispatch from "hooks/useAppDispatch";
import { changeOwnPassword, toggleTwoFaAuth } from '../../../../redux/features/user/userSlice'

const Security = () => {
  const { t } = useTranslation();
  const { is_two_factor_authentication } = useSelector(
    ({ user }) => user.user.personal_details
  );
  const dispatch = useAppDispatch();
  const [{ newPassword, repeatNewPassword, currentPassword }, setFields] =
    useState({
      newPassword: "",
      repeatNewPassword: "",
      currentPassword: "",
    });
  const [{ isSuccess, isError }, setStatus] = useState({
    isSuccess: false,
    isError: false,
  });
  const [passwordErrors, setPasswordErrors] = useState({});
  const [twoFactorEnabled, setTwoFactorEnabled] = useState(false);

  useEffect(() => {
    setTwoFactorEnabled(is_two_factor_authentication);
  }, [is_two_factor_authentication]); // initial fetch

  const onEditFields = (key, value) => {
    setFields((state) => {
      return {
        ...state,
        [key]: value,
      };
    });

    setPasswordErrors((state) => {
      return {
        ...state,
        [`${key}Error`]: false,
      };
    });
  };

  const doChangePassword = () => {
    dispatch(
      changeOwnPassword({
        old_password: currentPassword,
        new_password: newPassword,
      })
    )
      .then(() => setStatus((state) => ({ ...state, isSuccess: true })))
      .catch(({ error }) =>
        setStatus((state) => ({ ...state, isError: error }))
      );
  };

  const validatePassword = (password, errorName) => {
    if (password.length > 0 && password.length < 7) {
      setPasswordErrors((state) => ({
        ...state,
        [errorName]: t("profileUsers.err.passLeast"),
      }));
    } else if (password.length === 0) {
      setPasswordErrors((state) => ({
        ...state,
        [errorName]: t("profileUsers.err.pass"),
      }));
    } else if (!isValidPassword(password)) {
      setPasswordErrors((state) => ({
        ...state,
        [errorName]: "Use the Latin alphabet and special characters!",
      }));
    } else {
      setPasswordErrors((state) => ({
        ...state,
        [errorName]: false,
      }));
    }
  };

  const validatePasswordEqual = () => {
    if (newPassword !== repeatNewPassword) {
      setPasswordErrors((state) => ({
        ...state,
        repeatNewPasswordError: t("profileUsers.err.passNotEq"),
      }));
    }
  };

  const handleTwoFactorAuth = () => {
    try {
      setTwoFactorEnabled((enabled) => !enabled);
      dispatch(toggleTwoFaAuth());
    } catch (e) {
      console.error("Error while trying to toggle two-fa auth", e);
    }
  };

  return (
    <Layout.AnnotatedSection
      title={
        <Text variant='headingLg' as='p'>
          {t("profile.personalSettings.security")}
        </Text>
      }
      description={
        <Text variant={'bodySm'}>
          {t("profile.personalSettings.hereYouCanChange")}
        </Text>
      }
    >
      <Card sectioned>
        <FormLayout>
          <Text variant={'bodySm'}>
            With two-factor authentication (2FA) turned on, logging in requires
            verification using a Lovat mobile app.
          </Text>
           <div style={{display: "flex", justifyContent: "flex-start", alignItems: "center", marginTop: 5}}>
              <ToggleSwitch
                checked={twoFactorEnabled}
                onChange={() => handleTwoFactorAuth()}
              />
              <Text>Two-factor authentication</Text>
            </div>
          <Text variant={'bodySm'}>
            If you haven't already installed Lovat app, you can use your phone's
            camera app to scan the QR code and download the latest version of
            the app.
          </Text>
          <div style={{ display: "flex" }}>
            <img src={iosQrIMG} alt='ios qr code' style={{ maxWidth: 200 }} />
            <img
              src={androidQrIMG}
              alt='android qr code'
              style={{ maxWidth: 200 }}
            />
          </div>

          {isSuccess && (
            <Banner
              title='Personal details were successfully changed!'
              tone='success'
              onDismiss={() =>
                setStatus((state) => ({ ...state, isSuccess: false }))
              }
            />
          )}
          {isError && (
            <Banner
              title={isError}
              tone='critical'
              onDismiss={() =>
                setStatus((state) => ({ ...state, isError: false }))
              }
            />
          )}
          <FormLayout.Group>
            <TextField
              fullWidth
              label={t("profileUsers.newPass")}
              type='password'
              error={passwordErrors.newPasswordError}
              autoComplete='off'
              onChange={(value) => onEditFields("newPassword", value)}
              value={newPassword}
              onBlur={() => validatePassword(newPassword, "newPasswordError")}
            />
            <TextField
              fullWidth
              label={t("profileUsers.repeatPass")}
              type='password'
              autoComplete='off'
              onChange={(value) => onEditFields("repeatNewPassword", value)}
              value={repeatNewPassword}
              error={passwordErrors.repeatNewPasswordError}
              onBlur={() => validatePasswordEqual()}
            />
          </FormLayout.Group>
          <FormLayout.Group>
            <TextField
              fullWidth
              label={t("profileUsers.oldPass")}
              type='password'
              autoComplete='off'
              onChange={(value) => onEditFields("currentPassword", value)}
              error={passwordErrors.currentPasswordError}
              value={currentPassword}
              onBlur={() =>
                validatePassword(currentPassword, "currentPasswordError")
              }
            />
            <br />
          </FormLayout.Group>
          <ButtonGroup>
            <Button
              variant={'primary'}
              size={'micro'}
              onClick={doChangePassword}
              disabled={
                !currentPassword ||
                !newPassword ||
                !repeatNewPassword ||
                Object.values(passwordErrors).some((err) => err)
              }
            >
              {t("reports.save")}
            </Button>
          </ButtonGroup>
        </FormLayout>
      </Card>
    </Layout.AnnotatedSection>
  );
};

export default Security;
