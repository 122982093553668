import { Button, Card, FormLayout, Layout, Select, Text, TextField } from '@shopify/polaris'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import SearchingCountry from '../../components/SearchingCountry/SearchingCountry'
import useAppDispatch from '../../hooks/useAppDispatch'
import { createDac7Report, setDataForDac7Report } from '../../redux/features/declarations/declarationsSlice'
import { fetchDac7Countries } from '../../redux/features/other/otherSlice'
import { getYears } from '../../utils/years'

const years = getYears({yearsBefore: 1, yearsAfter: 0})
const dac7Reports = [
	{value: 'DAC7_reporting', label: 'DAC7 reporting'},
	{value: 'DAC7_immovable', label: 'DAC7 immovable property reporting'}
]

const Dac7Reporting = ({
	renderDac7ReportsTable,
	handleActionDialogsOpen
}) => {
	const {countriesDac7} = useSelector((state) => state.other)
	const {creatingDac7Report = false} = useSelector((state) => state.declarations)
	const [reportType, setReportType] = useState('DAC7_reporting')
	const [businessName, setBusinessName] = useState('')
	const [period, setPeriod] = useState('')
	const [changeCountry, setChangeCountry] = useState('')
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	
	useEffect(() => {
		const reqDac7CountriesList = async () => await dispatch(fetchDac7Countries())
		reqDac7CountriesList()
	}, [])
	
	const getAllCountriesForSelect = () => {
		const optionsCountries = [
			{
				key: "",
				label: "",
				value: "",
			},
		]
		countriesDac7.map((country) => {
			optionsCountries.push({
				key: country.code,
				label: country.name,
				value: country.code,
			})
		})
		return optionsCountries
	}
	
	const handleFillClick = async () => {
		const tehData = {
			business_name: businessName,
			period: +period,
			country: changeCountry,
			is_immovable: reportType === 'DAC7_reporting' ? false : true
		}
		try {
			await dispatch(setDataForDac7Report(tehData))
			await dispatch(createDac7Report(tehData))
			reportType === 'DAC7_reporting' ? navigate("/dac7-reporting") : navigate('/dac7-immovable-property')
		} catch (e) {
			handleActionDialogsOpen("subs", {e})
		}
	}
	
	const fillButtonDisabled = [businessName, period, changeCountry].some((val) => !Boolean(val))
	
	return (
		<Layout.AnnotatedSection
			title={
				<Text
					variant='headingLg'
					as='p'
				>
					{reportType === 'DAC7_reporting' ? t("dac7.dac7Reporting") : 'DAC7 immovable property reporting'}
          </Text >
			}
		>
			{
				renderDac7ReportsTable({is_immovable: reportType === 'DAC7_reporting' ? false : true})
			}
			<Card sectioned>
          <FormLayout >
            <FormLayout.Group >
	            <Select
		            label={'Type DAC7'}
		            options={dac7Reports}
		            onChange={(type) => {
			            // console.log('type', type)
			            setReportType(type)
		            }}
		            value={reportType}
	            />
              <TextField
	              label={t("dac7.businessName")}
	              value={businessName}
	              onChange={(value) => setBusinessName(value)}
              />
              <Select
	              label={t("dac7.reportingPeriod")}
	              options={years}
	              onChange={(period) => {
		              setPeriod(period)
	              }}
	              value={period}
              />
              <SearchingCountry
	              title={`${t("reports.country")}:`}
	              placeholder={'All'}
	              onChange={(country) => setChangeCountry(country)}
	              dataCountriesList={[
		              {label: "All", value: "all"},
		              ...getAllCountriesForSelect(),
	              ]}
	              changeCountry={changeCountry}
              />
            </FormLayout.Group >
          </FormLayout >
          <br />
				<div>
					 <Button
						 variant={'primary'}
						 size={'micro'}
						 onClick={handleFillClick}
						 disabled={fillButtonDisabled}
						 loading={creatingDac7Report}
					 >
	          {reportType === 'DAC7_reporting' ? t("dac7.fillDac7Report") : 'Fill DAC7 immovable property reporting'}
          </Button >
				</div>
        
        </Card >
      </Layout.AnnotatedSection >
	)
}

export default Dac7Reporting