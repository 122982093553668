import React from "react";
import { useTranslation } from 'react-i18next'
import { Link } from "react-router-dom";
import { ButtonGroup, Button, Text } from "@shopify/polaris";
import Modal from "../Modal";

import ParrotIMG from "img/userGuideParrot.svg";

import "./index.scss";

const Welcome = ({ onClose, navigate, visible }) => {
  const {t} = useTranslation()
  const onPurchaseNow = () => {
    navigate("/subscription");
    onClose();
  };
  return (
    <Modal
      onClose={onClose}
      title={t("modalWelcomeToLovat.title")}
      infoIconVisible={false}
      visible={visible}
      content={
        <>
          <img src={ParrotIMG} alt='parrot' className='parrot-image' />
          <div style={{ maxWidth: "400px" }}>
            <Text color='subdued' variant='bodyMd'>
              {t("modalWelcomeToLovat.textOneLine")}
              <br />
              <br />
              {t("modalWelcomeToLovat.textTwoLine")}
              
              <br />
              <br />
            </Text>
          </div>
          <Text color='subdued' variant='bodyMd'>
            {t("modalWelcomeToLovat.textThereLine")} {" "}
             {" "}
            <a
              href='https://meetings.hubspot.com/lovat-compliance?uuid=36faa068-fcb0-41e5-96db-dc04ff5fb5c5'
              target='_blank'
              rel='noopener noreferrer'
              className='Polaris-Link'
            >{t("modalWelcomeToLovat.textThereLineLink")}
            </a >
          </Text >
        </>
      }
      footer={
        <>
          <Text >
            {t("modalWelcomeToLovat.textFourLine")}
          </Text>
          <br />
          <ButtonGroup>
            <Button variant={'primary'} onClick={onClose}>
              {t("modalWelcomeToLovat.buttonPrimary")}
            </Button>
            <p>{t("modalWelcomeToLovat.or")}</p>
            <Button variant={'plain'} onClick={onPurchaseNow}>
              {t("modalWelcomeToLovat.purchaseNow")}
            </Button>
          </ButtonGroup>
        </>
      }
    />
  );
};

export default Welcome;
