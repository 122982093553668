import { Button, ButtonGroup, Card, FormLayout, Layout, Page, Text, TextField, } from '@shopify/polaris'
import Datepicker from 'components/Datepicker'
import PageHelmet from 'components/PageHelmet'
import SaveBar from 'components/SaveBar/SaveBar'
import useAppDispatch from 'hooks/useAppDispatch'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import GoBackPageNavigation from '../../../../components/GoBackPageNavigation/GoBackPageNavigation'
import ArrowLeftBack from '../../../../img/arrow-left-for-button-back.svg'
import {
	fetchParametersForAddExemptionCertificates,
	postDataAddExemptionCertificates
} from '../../../../redux/features/documents/documentsSlice'
import AddTableItems from './AddTableItems'
import ModalForGoBack from './ModalForGoBack'
import SelectComponent from './SelectComponent'

const initialTemplateData = {
	line_items: [],
};

const AddExemption = () => {
	const {parametersForAddExemptionCertificates} = useSelector(state => state.documents)
	const [templateData, setTemplateData] = useState(initialTemplateData);
	const [customers, setCustomers] = useState([])
	const [countries, setCountries] = useState([])
	const [exemptionCertificateTypes, setExemptionCertificateTypes] = useState([])
	const [typeOfExemption, setTypeOfExemption] = useState([])
	const [usaStates, setUsaStates] = useState([])
	const [expirationDate, setExpirationDate] = useState('')
	const [certificateID, setCertificateID] = useState('')
	const [descriptionOfGoodsOrServices, setDescriptionOfGoodsOrServices] = useState('')
	const [taxRegistrationNumber, setTaxRegistrationNumber] = useState('')
	const [data, setData] = useState({})
	const [goBackModalStatus, setGoBackModalStatus] = useState(false)
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const {state} = useLocation();
	
	console.log('state', state)
	console.log('exemptionCertificateTypes', exemptionCertificateTypes)
	console.log('data', data)
	
	useEffect(() => {
		if (state && Object.keys(state).length > 0) {
			setData(state);
		}
	}, [state]);
	
	useEffect(() => {
		const fetchData = async () => {
			try {
				await dispatch(fetchParametersForAddExemptionCertificates())
			} catch (e) {
				console.error(e)
			}
		}
		fetchData()
	}, [])
	
	useEffect(() => {
		if (parametersForAddExemptionCertificates?.customers) {
			const res = [
				{value: '', label: ''},
				...parametersForAddExemptionCertificates.customers.map(item => ({
					value: item?.[0],
					label: item?.[1],
				})),
			]
			setCustomers(res)
		}
		if (parametersForAddExemptionCertificates?.countries) {
			const res = [
				{value: '', label: ''},
				...parametersForAddExemptionCertificates.countries.map((item,
					index) => ({
					value: index + 1,
					label: item,
				})),
			]
			setCountries(res)
		}
		if (parametersForAddExemptionCertificates?.us_states) {
			const res = [
				{value: '', label: ''},
				...parametersForAddExemptionCertificates.us_states.map((item,
					index) => ({
					value: index + 1,
					label: item,
				})),
			]
			setUsaStates(res)
		}
		if (parametersForAddExemptionCertificates?.excemption_certificate_types) {
			const res = [
				{value: '', label: ''},
				...parametersForAddExemptionCertificates.excemption_certificate_types
					.flat()
					.map((item,
						index) => ({
						value: index + 1,
						label: item,
					})),
			]
			setExemptionCertificateTypes(res)
		}
		if (parametersForAddExemptionCertificates?.type_of_excemption) {
			const res = [
				{value: '', label: ''},
				...parametersForAddExemptionCertificates.type_of_excemption
					.flat()
					.map((item,
						index) => ({
						value: index + 1,
						label: item,
					})),
			]
			setTypeOfExemption(res)
		}
	}, [parametersForAddExemptionCertificates])
	
	const toggleGoBackModalStatus = () => {
		setGoBackModalStatus(!goBackModalStatus)
	}
	
	const goBack = () => {
		if (goBackModalStatus) {
			navigate(-1)
		}
	}
	
	const handlerChangeValueForData = (field,
		value) => setData({...data, [field]: value})
	
	useEffect(() => {
		setData({
			...data,
			expiration_date: expirationDate || state?.end_date || new Date(),
			certificate_id: certificateID || state?.cert_number,
			description_of_goods_or_services: descriptionOfGoodsOrServices,
			tax_registration_number: taxRegistrationNumber || state?.tax_registration_number,
		})
	}, [expirationDate, certificateID, descriptionOfGoodsOrServices, taxRegistrationNumber])
	
	
	const onChangeHandler = async () => {
		const countryTehData = countries?.find(item => Number(item.value) === Number(data.country))
		const customerTehData = customers?.find(item => Number(item.value) === Number(data.customer))
		
		const directionTehData = exemptionCertificateTypes?.find(item => Number(item.value) === Number(data.exemption_certificate_types))
		const typeExemptionTehData = typeOfExemption?.find(item => Number(item.value) === Number(data.type_of_exemption))
		const usStateTehData = usaStates?.find(item => Number(item.value) === Number(data.us_state))
		const tempData = templateData?.line_items?.map(({tax, ...rest}) => rest);
		const params = {
			...data,
			country: countryTehData?.label,
			customer: customerTehData?.label,
			exemption_certificate_types: directionTehData?.label,
			type_of_exemption: typeExemptionTehData?.label,
			us_state: usStateTehData?.label,
			customer_id: customerTehData?.value,
			table: tempData
		}
		
		try {
			const res = await dispatch(postDataAddExemptionCertificates(({params})))
			if (res.pdf_url) navigate(-1)
		} catch (err) {
			console.error(err)
		}
	}
	
	const areRequiredFieldsFilled = () => {
		return (
			data?.country &&
			data?.customer &&
			certificateID &&
			taxRegistrationNumber)
		return true
	}
	
	return (
		<Page
			separator
			fullWidth
			title={
				<GoBackPageNavigation
					content={
						<Text
							variant='heading3xl'
							as='span'
						>
              Create Exemption Certificate
            </Text >
					}
				/>
			}
		>
			{
				goBackModalStatus
				&&
				<ModalForGoBack
					goBack={goBack}
					goBackModalStatus={goBackModalStatus}
					toggleGoBackModalStatus={toggleGoBackModalStatus}
				/>
			}
			<PageHelmet title={"VAT Invoice Template"} />

      <div >
        <Layout.Section >
	        <div >
	          <FormLayout >
			        <FormLayout.Group >
			          <Card sectioned>
			            <div
				            style={{
					            display: 'flex',
					            alignItems: 'flex-start',
				            }}
			            >
				            <div
					            style={{
						            marginRight: 30,
						            display: 'flex',
						            alignItems: 'center',
						            justifyContent: 'space-between',
						            flexDirection: 'column'
					            }}
				            >
					            <SelectComponent
						            data={data}
						            line={'customer'}
						            label={'Customer:'}
						            handler={handlerChangeValueForData}
						            arr={customers}
					            />
				            
				            <SelectComponent
					            data={data}
					            line={'country'}
					            label={'Country:'}
					            handler={handlerChangeValueForData}
					            arr={countries}
				            />
				            
				            <SelectComponent
					            data={data}
					            line={'exemption_certificate_types'}
					            label={'Direction:'}
					            handler={handlerChangeValueForData}
					            arr={exemptionCertificateTypes}
				            />
				            
				            <SelectComponent
					            data={data}
					            line={'type_of_exemption'}
					            label={'Type of Exemption:'}
					            handler={handlerChangeValueForData}
					            arr={typeOfExemption}
				            />
				            
				            <SelectComponent
					            data={data}
					            line={'us_state'}
					            label={'Exempt region:'}
					            handler={handlerChangeValueForData}
					            arr={usaStates}
				            />
				            </div >
				            <div >
					            <div style={{marginTop: 15, width: 300}}>
						            <Datepicker
							            label={
								            <span >
															Expiration date <span style={{color: 'red', marginLeft: 4}}>*</span >
														</span >
							            }
							            placeholder={'Change Expiration date'}
							            allowRange={false}
							            value={expirationDate ? expirationDate.toISOString().split('T')[0] : ''}
							            onChange={(value) => setExpirationDate(new Date(value))}
							            autoComplete='off'
						            />
					            </div >
					            <div style={{marginTop: 15, width: 300}}>
						            <TextField
							            id='certificate_id'
							            type='text'
							            inputMode='text'
							            value={certificateID}
							            onChange={(newValue) => setCertificateID(newValue)}
							            autoComplete='off'
							            label={
								            <span >
															Certificate ID <span style={{color: 'red', marginLeft: 4}}>*</span >
														</span >
							            }
						            />
											</div >
					            <div style={{marginTop: 15, width: 300}}>
						            <TextField
							            id='taxRegistrationNumber'
							            type='text'
							            inputMode='text'
							            value={taxRegistrationNumber}
							            onChange={(newValue) => setTaxRegistrationNumber(newValue)}
							            autoComplete='off'
							            label={
								            <span >
															Tax Registration number<span style={{color: 'red', marginLeft: 4}}>*</span >
														</span >
							            }
						            />
												</div >
					            <div style={{marginTop: 15, width: 300}}>
						            <TextField
							            id='description'
							            label={'Description of Goods or Services'}
							            value={descriptionOfGoodsOrServices}
							            onChange={(value) => setDescriptionOfGoodsOrServices(value)}
							            multiline={5}
						            />
											</div >
										</div >
			            </div >
				          {
					          data?.exemption_certificate_types === '1'
					          &&
					          <div style={{marginTop: 30}}>
					            <h2 style={{fontWeight: 'bold', fontSize: 18}}>Items</h2 >
                       <AddTableItems
	                       data={templateData}
	                       setData={setTemplateData}
                       />
                    </div >
				          }
				          
			          </Card >
			        </FormLayout.Group >
			      </FormLayout >
		        <SaveBar >
				      <ButtonGroup >
				        <Button
					        size={'micro'}
					        onClick={toggleGoBackModalStatus}
				        >
				           <img
					           src={ArrowLeftBack}
					           alt='arrow'
					           style={{width: 10, height: 10, marginRight: 8}}
				           />Back
				        </Button >
				        <Button
					        variant={'primary'}
					        size={'micro'}
					        onClick={onChangeHandler}
					        // loading=''
					        disabled={!areRequiredFieldsFilled()}
				        >
				          Save
				        </Button >
				      </ButtonGroup >
				    </SaveBar >
	        </div >
        </Layout.Section >
      </div >
		</Page >
	)
}

export default AddExemption