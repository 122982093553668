import { Button } from '@shopify/polaris'
import React from 'react'
import parrotIMG from '../../img/parrot.svg'
import Modal from '../Modal'

const SubscriptionModal = ({title, visible, onClose, text}) => {
	
	const navigationSelectPlan = () => {
		window.location.href = "/subscription"
	}
	
	return (
		<Modal
			iconType={"warning"}
			title={title || ""}
			visible={visible}
			onClose={onClose}
			content={
				<>
          <div
            style={{
	            display: "flex",
	            alignItems: "center",
	            justifyContent: 'flex-start',
	            maxWidth: "400px",
            }}
          >
            <p >
             {text}
            </p >
            <img
              src={parrotIMG}
              alt='lovat_logo'
              width='20%'
            />
          </div >
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
              <Button
                variant={'secondary'}
                size={'large'}
                fullWidth
                onClick={onClose}
              >
                Close
              </Button >
              <span style={{width: 10}}></span >
              <Button
                variant={'primary'}
                size={'large'}
                fullWidth
                onClick={navigationSelectPlan}
              >
                Select Plan
              </Button >
            </div >
          </>
			}
		/>
	)
}

export default SubscriptionModal