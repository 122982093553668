import { Badge, Checkbox } from '@shopify/polaris'
import { getCurrencySymbol } from 'utils/currency'
import { formatDate } from 'utils/dates'
import { formatMoney } from 'utils/numbers'

export default function columnsFormSendEInvoices(
	selectedRows,
	handleSelectAll,
	handleRowSelect,
	query,
	getBadgeStatus) {
	return [
		{
			property: "checkbox",
			header: {
				// label: (
				// 	<Checkbox
				// 		label=''
				// 		checked={selectedRows.length > 0 && selectedRows.length === documents.length}
				// 		onChange={handleSelectAll}
				// 	/>
				// ),
			},
			cell: {
				formatters: [
					(value,
						{rowData}) =>
						<Checkbox
							label=''
							checked={selectedRows.includes(rowData.id)}
							onChange={() => handleRowSelect(rowData.id)}
						/>
				],
			},
		},
		// {
		// 	property: "created_date",
		// 	header: { label: "Created date" },
		// 	cell: { formatters: [(value) => formatDate(value)] },
		// },
		{
			property: "due_date",
			header: {label: "Due date"},
			cell: {formatters: [(value) => formatDate(value)]},
		},
		{
			property: "company",
			header: {label: "Company"},
			cell: {
				formatters: [(value,
					{rowData}) => rowData?.company || "-"]
			},
		},
		{
			property: "inv_number",
			header: {label: "Invoice Number"},
		},
		// {
		// 	property: "invoice_type",
		// 	header: { label: "Invoice Type" },
		// },
		// {
		// 	property: "tax_number",
		// 	header: { label: `${query?.type === 'invoice-sales-tax' ? 'Tax Number' : 'VAT Number'}` },
		// 	cell: { formatters: [(value, { rowData }) => rowData.tax_number || "-"] },
		// },
		// {
		// 	property: "supplier_country",
		// 	header: { label: "Country" },
		// 	cell: { formatters: [(value, { rowData }) => rowData?.country?.name || "Unknown Country"] },
		// },
		{
			property: "total",
			header: {label: "Total"},
			cell: {
				formatters: [(value,
					{rowData}) => `${getCurrencySymbol(rowData.currency)} ${formatMoney(value)}`]
			},
		},
		{
			property: "status",
			header: {label: "Status"},
			cell: {formatters: [(value) => <Badge tone={getBadgeStatus(value)}>{value}</Badge >]},
		},
	];
}
