import React, { useEffect } from "react";
import { fetchAllCountries } from '../../redux/features/other/otherSlice'
import { addUserCompany } from '../../redux/features/user/userSlice'
import BannerCustom from '../Banners/BannerCustom'
import Modal from "../Modal";
import {
  Box,
  Button,
  ButtonGroup,
  Select,
  Text,
  TextField,
} from "@shopify/polaris";
import { t } from "i18next";
import _ from "lodash";
import { useSelector } from "react-redux";
import { isLatin } from "utils/validation";
import fetchDefaultCountry from "utils/defaultCountry";

import suitcaseIMG from "img/suitcase.svg";
import useAppDispatch from "hooks/useAppDispatch";

const AddCompanyModal = ({ visible, onClose }) => {
  const dispatch = useAppDispatch();
  const { countriesAll } = useSelector((state) => state.other);
  const { addingUserCompany, addingUserCompanyError } = useSelector(
    (state) => state.user
  );

  const [companyData, setCompanyData] = React.useState({
    company_name: "",
    country: "",
  });

  const [errors, setErrors] = React.useState({
    company_name: null,
    country: null,
  });

  const hasErrors = Object.values(errors).some(Boolean);
  const someValueFalsy = !Object.values(companyData).every(Boolean);

  const isAddButtonDisabled = hasErrors || someValueFalsy;

  const handleCompanyDataChange = React.useCallback((field, value) => {
    setCompanyData((prevData) => ({
      ...prevData,
      [field]: value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: null,
    }));

    switch (field) {
      case "country":
        validateCountry(value);
        break;
      default:
        validateText(value, field);
    }
  }, []);

  const validateCountry = (country) => {
    if (country.length === 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        country: t("signUp.enterCountry"),
      }));
    }
  };

  const validateText = (value, field) => {
    if (value.length > 0 && isLatin(value)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: null,
      }));
    } else if (value.length > 0 && !isLatin(value)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: "Use the Latin alphabet!",
      }));
    }
  };

  const onAddCompanyClick = () => {
    dispatch(addUserCompany(companyData)).then(onClose);
  };

  useEffect(() => {
    const fetchData = async () => {
      const defaultCountry = await fetchDefaultCountry();
      handleCompanyDataChange("country", defaultCountry);

      dispatch(fetchAllCountries());
    };

    if (visible) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, handleCompanyDataChange]);

  return (
    <Modal
      title={"Add Company Account"}
      visible={visible}
      onClose={onClose}
      description={
        <div style={{ maxWidth: "500px" }}>
          To add a new company, please fill in the following fields
        </div>
      }
      content={
        <>
          <br />
          <Box spacing='2' fullWidth>
            <TextField
              id='companyName'
              type='text'
              prefix={
                <img
                  src={suitcaseIMG}
                  alt='company name'
                  style={{ marginTop: 6 }}
                />
              }
              label={
                <Text color='subdued' variant='bodySm'>
                  {t("signUp.companyName")}
                </Text>
              }
              placeholder={t("signUp.companyName")}
              value={companyData.company_name}
              onChange={(value) =>
                handleCompanyDataChange("company_name", value)
              }
              error={errors.company_name}
            />
            <Select
              label={
                <Text color='subdued' variant='bodySm'>
                  {t("signUp.country")}
                </Text>
              }
              placeholder={t("signUp.country")}
              options={[
                {
                  key: "0",
                  label: "",
                  value: "",
                },
                ..._.map(countriesAll, (country, ind) => ({
                  key: `${ind}c`,
                  label: country.name_no_article,
                  value: country.code,
                })),
              ]}
              onChange={(value) => handleCompanyDataChange("country", value)}
              value={companyData.country}
              error={errors.country}
            />
          </Box>
          {addingUserCompanyError && (
            <>
              <br />
              {/*<Banner tone='critical'>{addingUserCompanyError}</Banner>*/}
              <BannerCustom
                status={'critical'}
                message={addingUserCompanyError}
              />
            </>
          )}
        </>
      }
      footer={
        <ButtonGroup fullWidth>
          <Button size='large' onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant={'primary'}
            size='large'
            onClick={onAddCompanyClick}
            loading={addingUserCompany}
            disabled={isAddButtonDisabled}
          >
            Add Company
          </Button>
        </ButtonGroup>
      }
    />
  );
};

export default AddCompanyModal;
