import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import callApi from "helpers/callApi";
import handleErrorForThunk from "helpers/handleErrorForThunk";
import { parseServerError } from "utils/errors";

export const fetchTransactionsAuditListReports = createAsyncThunk(
  "transactions/fetchTransactionsAuditListReports",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi(
        "/transactions/list_tax_audit_reports",
        "GET"
      );
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const fetchReportTypes = createAsyncThunk(
  "transactions/fetchReportTypes",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi(
        "/transactions/tax_audit_report_types",
        "GET"
      );
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const createAuditReport = createAsyncThunk(
  "transactions/createAuditReport",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi(
        "/transactions/tax_audit_report_order",
        "POST",
        data
      );
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const fetchSalesListFields = createAsyncThunk(
  "transactions/fetchSalesListFields",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi("/import/sales_list_fields", "GET");
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const addSalesList = createAsyncThunk(
  "transactions/addSalesList",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await callApi(
        `/import/sales_list?country_id=${id}`,
        "POST",
        data,
        { contentType: "multipart/form-data" }
      );
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const deleteSalesListById = createAsyncThunk(
  "transactions/deleteSalesListById",
  async (id, { rejectWithValue }) => {
    try {
      const response = await callApi(
        `/transactions/sales_list/${id}`,
        "DELETE"
      );
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const updateTransactionsIntegrationDates = createAsyncThunk(
  "transactions/updateTransactionsIntegrationDates",
  async ({ website_id, platform, min_date, max_date }, { rejectWithValue }) => {
    try {
      const response = await callApi(
        `/update_by_date?website_id=${website_id}&platform=${platform}&min_date=${min_date}&max_date=${max_date}`,
        "POST"
      );
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const fetchTransactions = createAsyncThunk(
  "transactions/fetchTransactions",
  async (params = { limit: 50, offset: 0 }, { rejectWithValue }) => {
    try {
      const response = await callApi("/transactions", "GET", params);
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const fetchTransactionsParams = createAsyncThunk(
  "transactions/fetchTransactionsParams",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi("/transactions/params", "GET");
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const fetchTransactionsToPrint = createAsyncThunk(
  "transactions/fetchTransactionsToPrint",
  async (params, { rejectWithValue }) => {
    try {
      const response = await callApi("/transactions", "GET", {
        ...params,
        limit: 10000,
      });
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const fetchTransactionsMessages = createAsyncThunk(
  "transactions/fetchTransactionsMessages",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi("/update/status", "GET");
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const downloadFile = createAsyncThunk(
  "transactions/downloadFile",
  async (params, { rejectWithValue }) => {
    try {
      const response = await callApi("/transactions", "GET", params, {
        accept: `application/${params.format}`,
        responseType: "blob",
      });
      if (!response.ok) {
        throw new Error(response.status);
      }
      const blob = await response.blob();
      return {
        link: window.URL.createObjectURL(blob),
        filename: params.filename,
      };
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const deleteTransaction = createAsyncThunk(
  "transactions/deleteTransaction",
  async (transactionId, { rejectWithValue }) => {
    try {
      const response = await callApi(
        `/transactions/${transactionId}`,
        "DELETE"
      );
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const transactionIsVoid = createAsyncThunk(
  "transactions/transactionIsVoid",
  async (transactionId, { rejectWithValue }) => {
    try {
      const response = await callApi(
        `/transactions/is_void/${transactionId}`,
        "PUT"
      );
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const addTransaction = createAsyncThunk(
  "transactions/addTransaction",
  async ({ id, digital, calculate, thresholds, data }, { rejectWithValue }) => {
    try {
      const response = await callApi(
        `/import/${id}?if_digital=${digital}&if_vat_calculate=${calculate}&is_thresholds=${thresholds}`,
        "POST",
        data,
        { contentType: "multipart/form-data" }
      );
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText ?? "Server error!");
      }
      return response.json();
    } catch (err) {
      return rejectWithValue(handleErrorForThunk(err));
    }
  }
);

export const fetchTransactionsFields = createAsyncThunk(
  "transactions/fetchTransactionsFields",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi("/import/transactions_fields", "GET");
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const updateTransactionsIntegration = createAsyncThunk(
  "transactions/updateTransactionsIntegration",
  async (params, { rejectWithValue }) => {
    try {
      const response = await callApi("/update", "POST", params);
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const deleteAuditReport = createAsyncThunk(
  "transactions/deleteAuditReport",
  async (params, { rejectWithValue }) => {
    try {
      const response = await callApi(
        "/transactions/delete_tax_audit_report",
        "DELETE",
        params
      );
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const deleteTaxAuditReport = createAsyncThunk(
  "transactions/deleteTaxAuditReport",
  async (params, { rejectWithValue }) => {
    try {
      const response = await callApi(
        "/transactions/delete_tax_audit_report",
        "DELETE",
        params
      );
      return response.json();
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const getTransactionsFields = createAsyncThunk(
  "transactions/getTransactionsFields",
  async (params, { rejectWithValue }) => {
    try {
      const response = await callApi(
        `/import/transactions_fields`,
        "GET",
        params
      );
      return response.json();
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const clearDeleteTransactionErrors = createAction(
  "transactions/clearDeleteTransactionErrors"
);

export const isVoidTransaction = createAsyncThunk(
  "transactions/isVoidTransaction",
  async ({ id}, { rejectWithValue }) => {
    try {
      const response = await callApi(
        `/transactions/is_void/${id}`,
        "PUT",
      );
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText ?? "Server error!");
      }
      return response.json();
    } catch (err) {
      return rejectWithValue(handleErrorForThunk(err));
    }
  }
);

export const isEditDeclarationTransaction = createAsyncThunk(
  "transactions/isEditDeclarationTransaction",
  async ({id, data}, { rejectWithValue }) => {
    try {
      const response = await callApi(
        `/returns/declarations/transaction/${id}`,
        "PUT",
        data
      );
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText ?? "Server error!");
      }
      return response.json();
    } catch (err) {
      return rejectWithValue(handleErrorForThunk(err));
    }
  }
);
export const isCreateDeclarationTransaction = createAsyncThunk(
  "transactions/isCreateDeclarationTransaction",
  async (body, { rejectWithValue }) => {
    try {
      const response = await callApi(
        `/returns/declarations/transaction/`,
        "POST",
        body
      );
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText ?? "Server error!");
      }
      return response.json();
    } catch (err) {
      return rejectWithValue(handleErrorForThunk(err));
    }
  }
);

const initialState = {
  transactions: { items: [] },
  transactionsMessages: [],
  transactionsParams: {},
  toPrint: { items: [] },
  fields: [],
  toDownload: null,
  listReports: [],
  salesListFields: [],
  salesListImport: {},
  reportTypes: [],
  fetchingCreateDeclarationError: null
};

const transactionsSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {
    clearDeleteTransactionErrors: (state) => {
      state.deleting = false;
      state.deletingError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTransactions.pending, (state) => {
        state.fetching = true;
      })
      .addCase(fetchTransactions.fulfilled, (state, action) => {
        state.fetching = false;
        state.fetchingError = null;
        state.transactions = action.payload;
      })
      .addCase(fetchTransactions.rejected, (state, action) => {
        state.fetching = false;
        state.fetchingError = parseServerError(action.payload);
      })
      .addCase(fetchTransactionsMessages.pending, (state) => {
        state.fetchingTrMessages = true;
      })
      .addCase(fetchTransactionsMessages.fulfilled, (state, action) => {
        state.fetchingTrMessages = false;
        state.fetchingTrMessagesError = null;
        state.transactionsMessages = action.payload;
      })
      .addCase(fetchTransactionsMessages.rejected, (state, action) => {
        state.fetchingTrMessages = false;
        state.fetchingTrMessagesError = parseServerError(action.payload);
      })
      .addCase(fetchTransactionsParams.pending, (state) => {
        state.fetching = true;
      })
      .addCase(fetchTransactionsParams.fulfilled, (state, action) => {
        state.fetching = false;
        state.fetchingError = null;
        state.transactionsParams = action.payload;
      })
      .addCase(fetchTransactionsParams.rejected, (state, action) => {
        state.fetching = false;
        state.fetchingError = parseServerError(action.payload);
      })
      .addCase(fetchTransactionsToPrint.pending, (state) => {
        state.fetching = true;
      })
      .addCase(fetchTransactionsToPrint.fulfilled, (state, action) => {
        state.fetching = false;
        state.fetchingError = null;
        state.toPrint = action.payload;
      })
      .addCase(fetchTransactionsToPrint.rejected, (state, action) => {
        state.fetching = false;
        state.fetchingError = parseServerError(action.payload);
      })
      .addCase(downloadFile.pending, (state) => {
        state.downloading = true;
      })
      .addCase(downloadFile.fulfilled, (state, action) => {
        state.downloading = false;
        state.downloadingError = null;
        state.toDownload = action.payload;
      })
      .addCase(downloadFile.rejected, (state, action) => {
        state.downloading = false;
        state.downloadingError = parseServerError(action.payload);
      })
      .addCase(deleteTransaction.pending, (state) => {
        state.deleting = true;
      })
      .addCase(deleteTransaction.fulfilled, (state) => {
        state.deleting = false;
        state.deletingError = null;
      })
      .addCase(deleteTransaction.rejected, (state, action) => {
        state.deleting = false;
        state.deletingError = parseServerError(action.payload);
      })
      .addCase(transactionIsVoid.pending, (state) => {
        state.voiding = true;
      })
      .addCase(transactionIsVoid.fulfilled, (state) => {
        state.voiding = false;
        state.voidingError = null;
      })
      .addCase(transactionIsVoid.rejected, (state, action) => {
        state.voiding = false;
        state.voidingError = parseServerError(action.payload);
      })
      .addCase(addTransaction.pending, (state) => {
        state.adding = true;
      })
      .addCase(addTransaction.fulfilled, (state, action) => {
        state.adding = false;
        state.addingError = null;
        state.toDownload = action.payload;
      })
      .addCase(addTransaction.rejected, (state, action) => {
        state.adding = false;
        state.addingError = parseServerError(action.payload);
      })
      .addCase(fetchTransactionsFields.pending, (state) => {
        state.fetching = true;
      })
      .addCase(fetchTransactionsFields.fulfilled, (state, action) => {
        state.fetching = false;
        state.fetchingError = null;
        state.fields = action.payload;
      })
      .addCase(fetchTransactionsFields.rejected, (state, action) => {
        state.fetching = false;
        state.fetchingError = parseServerError(action.payload);
      })
      .addCase(updateTransactionsIntegration.pending, (state) => {
        state.updating = true;
      })
      .addCase(updateTransactionsIntegration.fulfilled, (state, action) => {
        state.updating = false;
        state.updatingError = null;
      })
      .addCase(updateTransactionsIntegration.rejected, (state, action) => {
        state.updating = false;
        state.updatingError = parseServerError(action.payload);
      })
      .addCase(deleteAuditReport.pending, (state) => {
        state.deletingAuditRep = true;
      })
      .addCase(deleteAuditReport.fulfilled, (state, action) => {
        state.deletingAuditRep = false;
        state.deletingAuditRepError = null;
      })
      .addCase(deleteAuditReport.rejected, (state, action) => {
        state.deletingAuditRep = false;
        state.deletingAuditRepError = parseServerError(action.payload);
      })
      .addCase(createAuditReport.pending, (state) => {
        state.creatingAuditRep = true;
      })
      .addCase(createAuditReport.fulfilled, (state, action) => {
        state.creatingAuditRep = false;
        state.creatingAuditRepError = null;
      })
      .addCase(createAuditReport.rejected, (state, action) => {
        state.creatingAuditRep = false;
        state.creatingAuditRepError = parseServerError(action.payload);
      })
      .addCase(fetchReportTypes.pending, (state) => {
        state.fetchingTypes = true;
      })
      .addCase(fetchReportTypes.fulfilled, (state, action) => {
        state.fetchingTypes = false;
        state.fetchingTypesError = null;
        state.reportTypes = action.payload;
      })
      .addCase(fetchReportTypes.rejected, (state, action) => {
        state.fetchingTypes = false;
        state.fetchingTypesError = parseServerError(action.payload);
      })
      .addCase(fetchTransactionsAuditListReports.pending, (state) => {
        state.fetchingList = true;
      })
      .addCase(fetchTransactionsAuditListReports.fulfilled, (state, action) => {
        state.fetchingList = false;
        state.fetchingListError = null;
        state.listReports = action.payload;
      })
      .addCase(fetchTransactionsAuditListReports.rejected, (state, action) => {
        state.fetchingList = false;
        state.fetchingListError = parseServerError(action.payload);
      })
      .addCase(fetchSalesListFields.pending, (state) => {
        state.fetchingFields = true;
      })
      .addCase(fetchSalesListFields.fulfilled, (state, action) => {
        state.fetchingFields = false;
        state.fetchingFieldsError = null;
        state.salesListFields = action.payload;
      })
      .addCase(fetchSalesListFields.rejected, (state, action) => {
        state.fetchingFields = false;
        state.fetchingFieldsError = parseServerError(action.payload);
      })
      .addCase(addSalesList.pending, (state) => {
        state.addingSL = true;
      })
      .addCase(addSalesList.fulfilled, (state, action) => {
        state.addingSL = false;
        state.addingSLError = null;
        state.salesListImport = action.payload;
      })
      .addCase(addSalesList.rejected, (state, action) => {
        state.addingSL = false;
        state.addingSLError = parseServerError(action.payload);
      })
      .addCase(deleteSalesListById.pending, (state) => {
        state.deletingSalesListId = true;
      })
      .addCase(deleteSalesListById.fulfilled, (state, action) => {
        state.deletingSalesListId = false;
        state.deletingSalesListIdError = null;
      })
      .addCase(deleteSalesListById.rejected, (state, action) => {
        state.deletingSalesListId = false;
        state.deletingSalesListIdError = parseServerError(action.payload);
      })
      .addCase(deleteTaxAuditReport.pending, (state) => {
        state.deletingAuditRep = true;
      })
      .addCase(deleteTaxAuditReport.fulfilled, (state, action) => {
        state.deletingAuditRep = false;
        state.deletingAuditRepError = null;
      })
      .addCase(deleteTaxAuditReport.rejected, (state, action) => {
        state.deletingAuditRep = false;
        state.deletingAuditRepError = action.payload;
      })
      .addCase(getTransactionsFields.pending, (state) => {
        state.fetchingFields = true;
      })
      .addCase(getTransactionsFields.fulfilled, (state, action) => {
        state.fetchingFields = false;
        state.fetchingFieldsError = null;
        state.fields = action.payload;
      })
      .addCase(getTransactionsFields.rejected, (state, action) => {
        state.fetchingFields = false;
        state.fetchingFieldsError = action.payload;
      })
      .addCase(isVoidTransaction.pending, (state) => {
        state.fetchingIsVoid = true;
      })
      .addCase(isVoidTransaction.fulfilled, (state, action) => {
        state.fetchingIsVoid = false;
        state.fetchingIsVoidError = null;
      })
      .addCase(isVoidTransaction.rejected, (state, action) => {
        state.fetchingIsVoid = false;
        state.fetchingIsVoidError = action.payload;
      })
      .addCase(isEditDeclarationTransaction.pending, (state) => {
        state.fetchingEditDeclaration = true;
      })
      .addCase(isEditDeclarationTransaction.fulfilled, (state, action) => {
        state.fetchingEditDeclaration = false;
        state.fetchingEditDeclarationError = null;
      })
      .addCase(isEditDeclarationTransaction.rejected, (state, action) => {
        state.fetchingEditDeclaration = false;
        state.fetchingEditDeclarationError = action.payload;
      })
      .addCase(isCreateDeclarationTransaction.pending, (state) => {
        state.fetchingCreateDeclaration = true;
      })
      .addCase(isCreateDeclarationTransaction.fulfilled, (state, action) => {
        state.fetchingCreateDeclaration = false;
        state.fetchingCreateDeclarationError = null;
      })
      .addCase(isCreateDeclarationTransaction.rejected, (state, action) => {
        state.fetchingCreateDeclaration = false;
        state.fetchingCreateDeclarationError = action.payload;
      })
  },
});

export default transactionsSlice.reducer;
