import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { parseServerError } from "utils/errors";
import callApi from "helpers/callApi";
import { baseURL } from "constants";

export const createInvoice = createAsyncThunk(
  "documents/createInvoice",
  async ({ params, attachments = [] }, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append("data", JSON.stringify(params));
      for (let i = 0; i < attachments.length; i++) {
        formData.append(
          "attachments",
          attachments[i].file,
          attachments[i].file.name
        );
      }
      const response = await callApi("/documents/invoices", "POST", formData, {
        contentType: "multipart/form-data",
      });
      if (response.ok) {
        return await response.json();
      } else {
        return rejectWithValue(response.status);
      }
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const createExemption = createAsyncThunk(
  "documents/createExemption",
  async ({ params, attachments = [] }, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append("data", JSON.stringify(params));
      for (let i = 0; i < attachments.length; i++) {
        formData.append(
          "attachments",
          attachments[i].file,
          attachments[i].file.name
        );
      }
      const response = await callApi(
        "/documents/resale_certificate",
        "POST",
        formData,
        {
          contentType: "multipart/form-data",
        }
      );
      if (response.ok) {
        return await response.json();
      } else {
        return rejectWithValue(response.status);
      }
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const getCustomersList = createAsyncThunk(
  "documents/getCustomersList",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi("/documents/customers", "GET");
      if (response.ok) {
        return await response.json();
      } else {
        return rejectWithValue(response.status);
      }
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const addCustomerToList = createAsyncThunk(
  "documents/addCustomerToList",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi("/documents/customer", "POST", data);
      if (response.ok) {
        return await response.json();
      } else {
        return rejectWithValue(response.status);
      }
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);
export const sendingEinvoices = createAsyncThunk(
  "documents/sendingEinvoices",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi("/peppol/sending_einvoices", "POST", data);
      if (response.ok) {
        return await response.json();
      } else {
        return rejectWithValue(response.status);
      }
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const editCustomerInList = createAsyncThunk(
  "documents/editCustomerInList",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi("/documents/customer", "PUT", data);
      if (response.ok) {
        return await response.json();
      } else {
        return rejectWithValue(response.status);
      }
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const getBanksList = createAsyncThunk(
  "documents/getBanksList",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi("/documents/bank", "GET");
      if (response.ok) {
        return await response.json();
      } else {
        return rejectWithValue(response.status);
      }
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const addBankToList = createAsyncThunk(
  "documents/addBankToList",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi("/documents/bank", "POST", data);
      if (response.ok) {
        return await response.json();
      } else {
        return rejectWithValue(response.status);
      }
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const editBankInList = createAsyncThunk(
  "documents/editBankInList",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi("/documents/bank", "PUT", data);
      if (response.ok) {
        return await response.json();
      } else {
        return rejectWithValue(response.status);
      }
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const getTaxList = createAsyncThunk(
  "documents/getTaxList",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi("/documents/tax", "GET");
      if (response.ok) {
        return await response.json();
      } else {
        return rejectWithValue(response.status);
      }
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const addTaxToList = createAsyncThunk(
  "documents/addTaxToList",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi("/documents/tax", "POST", data);
      if (response.ok) {
        return await response.json();
      } else {
        return rejectWithValue(response.status);
      }
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const editTaxInList = createAsyncThunk(
  "documents/editTaxInList",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi("/documents/tax", "PUT", data);
      if (response.ok) {
        return await response.json();
      } else {
        return rejectWithValue(response.status);
      }
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const fetchDocuments = createAsyncThunk(
  "documents/fetchDocuments",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi(
        `/documents?type=${data.type}&invoice_type=${data.invoice_type}&offset=${data.offset}&limit=${data.limit}`,
        "GET"
      );
      if (response.ok) {
        return await response.json();
      } else {
        return rejectWithValue(response.status);
      }
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const fetchDocumentsExemptionCertificates = createAsyncThunk(
  "documents/fetchDocumentsExemptionCertificates",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi(
        `/documents/cert`,
        "GET"
      );
      if (response.ok) {
        return await response.json();
      } else {
        return rejectWithValue(response.status);
      }
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const fetchParametersForAddExemptionCertificates = createAsyncThunk(
  "documents/fetchParametersForAddExemptionCertificates",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi(
        `/documents/get_data_for_cert`,
        "GET"
      );
      if (response.ok) {
        return await response.json();
      } else {
        return rejectWithValue(response.status);
      }
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const postDataAddExemptionCertificates = createAsyncThunk(
  "documents/postDataAddExemptionCertificates",
  async ({ params, attachments = [] }, { rejectWithValue }) => {
    try {
      const paramsString = JSON.stringify(params);
      const response = await callApi("/documents/post_data_for_cert", "POST", paramsString);
      if (response.ok) {
        return await response.json();
      } else {
        return rejectWithValue(response.status);
      }
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const deleteVatInvoice = createAsyncThunk(
  "documents/deleteVatInvoice",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi(`/documents/${data.id}`, "DELETE");
      if (response.ok) {
        return await response.json();
      } else {
        return rejectWithValue(response.status);
      }
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);
export const deleteVatInvoiceBulk = createAsyncThunk(
  "documents/deleteVatInvoiceBulk",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi(`/documents/`,"DELETE", data);
      if (response.ok) {
        return await response.json();
      } else {
        return rejectWithValue(response.status);
      }
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const getDataForExemptionCertificate = createAsyncThunk(
  "documents/getDataForExemptionCertificate",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi(
        `/documents/get_data_for_exemption_certificate`,
        "GET"
      );
      if (response.ok) {
        return await response.json();
      } else {
        return rejectWithValue(response.status);
      }
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const getInvoicesFromXero = createAsyncThunk(
  "documents/getInvoicesFromXero",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi(
        `/documents/xero?from_date=${data.from_date}&to_date=${data.to_date}&is_pdf=${data.is_pdf}`,
        "GET"
      );
      if (response.ok) {
        return await response.json();
      } else {
        const error = await response.text();
        const parsedError = JSON.parse(error);

        return rejectWithValue(parsedError);
      }
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const convertInvoicesToTransaction = createAsyncThunk(
  "documents/convertInvoicesToTransaction",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi(
        `/documents/invoices_to_transaction?from_date=${data.from_date}&to_date=${data.to_date}`,
        "POST"
      );
      if (response.ok) {
        return await response.json();
      } else {
        const error = await response.text();
        const parsedError = JSON.parse(error);

        return rejectWithValue(parsedError);
      }
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const uploadDocuments = createAsyncThunk(
  "documents/uploadDocuments",
  async (files, { rejectWithValue }) => {
    try {
      const response = await callApi(`/documents/upload`, "POST", files, {
        contentType: "multipart/form-data",
      });
      if (response.ok) {
        return await response.json();
      } else {
        return rejectWithValue(response.status);
      }
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const fetchDocumentsMessages = createAsyncThunk(
  "payment/fetchDocumentsMessages",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi(
        "/notify/get_system_message_active?models_name=documents",
        "GET"
      );
      if (response.ok) {
        return await response.json();
      } else {
        return rejectWithValue(response.status);
      }
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

const documentsSlice = createSlice({
  name: "documents",
  initialState: {
    invoice: {
      loading: {},
      error: null,
      previewUrl: "",
    },
    exemption: {
      loading: {},
      error: null,
      previewUrl: "",
    },
    customers: [],
    banks: [],
    taxes: [],
    documents: [],
    documentsExemptionCertificates: [],
    exemptionData: {},
    messages: [],
    countDocuments: 0,
    countDocumentsExemption: 0,
    parametersForAddExemptionCertificates: {}
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createInvoice.pending, (state, action) => {
        const { action: type } = action.meta.arg.params;
        state.invoice.loading = {
          [type]: true,
        };
        state.invoice.error = null;
      })
      .addCase(createInvoice.fulfilled, (state, action) => {
        state.invoice.loading = {};
        state.invoice.error = null;
        state.invoice.previewUrl = `${baseURL}/${action.payload.pdf_url}`;
      })
      .addCase(createInvoice.rejected, (state, action) => {
        state.invoice.loading = {};
        state.invoice.error = parseServerError(action.payload);
      })
      .addCase(createExemption.pending, (state, action) => {
        const { action: type } = action.meta.arg.params;
        state.exemption.loading = {
          [type]: true,
        };
        state.exemption.error = null;
      })
      .addCase(createExemption.fulfilled, (state, action) => {
        state.exemption.loading = {};
        state.exemption.error = null;
        state.exemption.previewUrl = `${baseURL}/${action.payload.pdf_url}`;
      })
      .addCase(createExemption.rejected, (state, action) => {
        state.exemption.loading = {};
        state.exemption.error = parseServerError(action.payload);
      })
      .addCase(getCustomersList.pending, (state) => {
        state.getCustomersLoading = true;
        state.getCustomersError = null;
      })
      .addCase(getCustomersList.fulfilled, (state, action) => {
        state.getCustomersLoading = false;
        state.customers = action.payload;
      })
      .addCase(getCustomersList.rejected, (state, action) => {
        state.getCustomersLoading = false;
        state.getCustomersError = parseServerError(action.payload);
      })
      .addCase(addCustomerToList.pending, (state) => {
        state.addingCustomerLoading = true;
        state.addingCustomerError = null;
      })
      .addCase(addCustomerToList.fulfilled, (state, action) => {
        state.addingCustomerLoading = false;
        state.addingCustomerError = null;
        state.customers.push(action.payload.customer);
      })
      .addCase(addCustomerToList.rejected, (state, action) => {
        state.addingCustomerLoading = false;
        state.addingCustomerError = parseServerError(action.payload);
      })
      .addCase(sendingEinvoices.pending, (state) => {
        state.sendingEinvoicesLoading = true;
        state.sendingEinvoicesError = null;
      })
      .addCase(sendingEinvoices.fulfilled, (state, action) => {
        state.sendingEinvoicesLoading = false;
        state.sendingEinvoicesError = null;
        // state.customers.push(action.payload.customer);
      })
      .addCase(sendingEinvoices.rejected, (state, action) => {
        state.sendingEinvoicesLoading = false;
        state.sendingEinvoicesError = parseServerError(action.payload);
      })
      .addCase(editCustomerInList.pending, (state) => {
        state.editingCustomerLoading = true;
        state.editingCustomerError = null;
      })
      .addCase(editCustomerInList.fulfilled, (state, action) => {
        state.editingCustomerLoading = false;
        state.editingCustomerError = null;
        const index = state.customers.findIndex(
          (customer) => customer.id === action.payload.customer.id
        );
        if (index !== -1) {
          state.customers[index] = action.payload.customer;
        }
      })
      .addCase(editCustomerInList.rejected, (state, action) => {
        state.editingCustomerLoading = false;
        state.editingCustomerError = parseServerError(action.payload);
      })
      .addCase(getBanksList.pending, (state) => {
        state.getBanksLoading = true;
        state.getBanksError = null;
      })
      .addCase(getBanksList.fulfilled, (state, action) => {
        state.getBanksLoading = false;
        state.banks = action.payload;
      })
      .addCase(getBanksList.rejected, (state, action) => {
        state.getBanksLoading = false;
        state.getBanksError = parseServerError(action.payload);
      })
      .addCase(addBankToList.pending, (state) => {
        state.addingBankLoading = true;
        state.addingBankError = null;
      })
      .addCase(addBankToList.fulfilled, (state, action) => {
        state.addingBankLoading = false;
        state.addingBankError = null;
        state.banks.push(action.payload.bank);
      })
      .addCase(addBankToList.rejected, (state, action) => {
        state.addingBankLoading = false;
        state.addingBankError = parseServerError(action.payload);
      })
      .addCase(editBankInList.pending, (state) => {
        state.editingBankLoading = true;
        state.editingBankError = null;
      })
      .addCase(editBankInList.fulfilled, (state, action) => {
        state.editingBankLoading = false;
        state.editingBankError = null;
        const index = state.banks.findIndex(
          (bank) => bank.id === action.payload.bank.id
        );
        if (index !== -1) {
          state.banks[index] = action.payload.bank;
        }
      })
      .addCase(editBankInList.rejected, (state, action) => {
        state.editingBankLoading = false;
        state.editingBankError = parseServerError(action.payload);
      })
      .addCase(getTaxList.pending, (state) => {
        state.getTaxLoading = true;
        state.getTaxError = null;
      })
      .addCase(getTaxList.fulfilled, (state, action) => {
        state.getTaxLoading = false;
        state.taxes = action.payload;
      })
      .addCase(getTaxList.rejected, (state, action) => {
        state.getTaxLoading = false;
        state.getTaxError = parseServerError(action.payload);
      })
      .addCase(addTaxToList.pending, (state) => {
        state.addingTaxLoading = true;
        state.addingTaxError = null;
      })
      .addCase(addTaxToList.fulfilled, (state, action) => {
        state.addingTaxLoading = false;
        state.addingTaxError = null;
        state.taxes.push(action.payload.tax);
      })
      .addCase(addTaxToList.rejected, (state, action) => {
        state.addingTaxLoading = false;
        state.addingTaxError = parseServerError(action.payload);
      })
      .addCase(editTaxInList.pending, (state) => {
        state.editingTaxLoading = true;
        state.editingTaxError = null;
      })
      .addCase(editTaxInList.fulfilled, (state, action) => {
        state.editingTaxLoading = false;
        state.editingTaxError = null;
        const index = state.taxes.findIndex(
          (tax) => tax.id === action.payload.tax.id
        );
        if (index !== -1) {
          state.taxes[index] = action.payload.tax;
        }
      })
      .addCase(editTaxInList.rejected, (state, action) => {
        state.editingTaxLoading = false;
        state.editingTaxError = parseServerError(action.payload);
      })
      .addCase(fetchDocuments.fulfilled, (state, action) => {
        state.fetchingDocuments = false;
        state.fetchingDocumentsError = null;
        state.documents = action.payload.items;
        state.countDocuments = action.payload.count;
      })
      .addCase(fetchDocuments.rejected, (state, action) => {
        state.fetchingDocuments = false;
        state.fetchingDocumentsError = parseServerError(action.payload);
      })
      .addCase(fetchDocuments.pending, (state) => {
        state.fetchingDocuments = true;
        state.fetchingDocumentsError = null;
      })
      .addCase(fetchDocumentsExemptionCertificates.fulfilled, (state, action) => {
        state.fetchingDocuments = false;
        state.fetchingDocumentsError = null;
        state.documentsExemptionCertificates = action.payload.items;
        state.countDocumentsExemption = action.payload.count;
      })
      .addCase(fetchDocumentsExemptionCertificates.rejected, (state, action) => {
        state.fetchingDocuments = false;
        state.fetchingDocumentsError = parseServerError(action.payload);
      })
      .addCase(fetchDocumentsExemptionCertificates.pending, (state) => {
        state.fetchingDocuments = true;
        state.fetchingDocumentsError = null;
      })
      .addCase(fetchParametersForAddExemptionCertificates.fulfilled, (state, action) => {
        state.fetchParametersForAddExemptionCertificates = false;
        state.fetchParametersForAddExemptionCertificatesError = null;
        // console.log('action.payload', action.payload)
        state.parametersForAddExemptionCertificates = action.payload.result;
      })
      .addCase(fetchParametersForAddExemptionCertificates.rejected, (state, action) => {
        state.fetchParametersForAddExemptionCertificates = false;
        state.fetchParametersForAddExemptionCertificatesError = parseServerError(action.payload);
      })
      .addCase(fetchParametersForAddExemptionCertificates.pending, (state) => {
        state.fetchParametersForAddExemptionCertificates = true;
        state.fetchParametersForAddExemptionCertificatesError = null;
      })
      .addCase(postDataAddExemptionCertificates.fulfilled, (state, action) => {
        state.postDataAddExemptionCertificates = false;
        state.postDataAddExemptionCertificatesError = null;
      })
      .addCase(postDataAddExemptionCertificates.rejected, (state, action) => {
        state.postDataAddExemptionCertificates = false;
        state.postDataAddExemptionCertificatesError = parseServerError(action.payload);
      })
      .addCase(postDataAddExemptionCertificates.pending, (state) => {
        state.postDataAddExemptionCertificates = true;
        state.postDataAddExemptionCertificatesError = null;
      })
      .addCase(deleteVatInvoice.fulfilled, (state, action) => {
        state.deleteVatInvoice = false;
        state.deleteVatInvoiceError = null;
      })
      .addCase(deleteVatInvoice.rejected, (state, action) => {
        state.deleteVatInvoice = false;
        state.deleteVatInvoiceError = parseServerError(action.payload);
      })
      .addCase(deleteVatInvoice.pending, (state) => {
        state.deleteVatInvoice = true;
        state.deleteVatInvoiceError = null;
      })
      .addCase(deleteVatInvoiceBulk.fulfilled, (state, action) => {
        state.deleteVatInvoiceBulk = false;
        state.deleteVatInvoiceBulkError = null;
      })
      .addCase(deleteVatInvoiceBulk.rejected, (state, action) => {
        state.deleteVatInvoiceBulk = false;
        state.deleteVatInvoiceBulkError = parseServerError(action.payload);
      })
      .addCase(deleteVatInvoiceBulk.pending, (state) => {
        state.deleteVatInvoiceBulk = true;
        state.deleteVatInvoiceBulkError = null;
      })
      .addCase(getDataForExemptionCertificate.fulfilled, (state, action) => {
        state.fetchingExemptionData = false;
        state.fetchingExemptionDataError = null;
        state.exemptionData = action.payload;
      })
      .addCase(getDataForExemptionCertificate.rejected, (state, action) => {
        state.fetchingExemptionData = false;
        state.fetchingExemptionDataError = parseServerError(action.payload);
      })
      .addCase(getDataForExemptionCertificate.pending, (state) => {
        state.fetchingExemptionData = true;
        state.fetchingExemptionDataError = null;
      })
      .addCase(getInvoicesFromXero.fulfilled, (state, action) => {
        state.fetchingInvoicesFromXero = false;
        state.fetchingInvoicesFromXeroError = null;
      })
      .addCase(getInvoicesFromXero.rejected, (state, action) => {
        state.fetchingInvoicesFromXero = false;
        state.fetchingInvoicesFromXeroError = action.payload;
      })
      .addCase(getInvoicesFromXero.pending, (state) => {
        state.fetchingInvoicesFromXero = true;
        state.fetchingInvoicesFromXeroError = null;
      })
      .addCase(convertInvoicesToTransaction.fulfilled, (state, action) => {
        state.fetchingConvertInvoicesToTransactionLoading = false;
        state.fetchingConvertInvoicesToTransactionLoadingError = null;
      })
      .addCase(convertInvoicesToTransaction.rejected, (state, action) => {
        state.fetchingConvertInvoicesToTransactionLoading = false;
        state.fetchingConvertInvoicesToTransactionLoadingError = action.payload;
      })
      .addCase(convertInvoicesToTransaction.pending, (state) => {
        state.fetchingConvertInvoicesToTransactionLoading = true;
        state.fetchingConvertInvoicesToTransactionLoadingError = null;
      })
      .addCase(uploadDocuments.fulfilled, (state, action) => {
        state.uploadingDocuments = false;
        state.uploadingDocumentsError = null;
      })
      .addCase(uploadDocuments.rejected, (state, action) => {
        state.uploadingDocuments = false;
        state.uploadingDocumentsError = action.payload;
      })
      .addCase(uploadDocuments.pending, (state) => {
        state.uploadingDocuments = true;
        state.uploadingDocumentsError = null;
      })
      .addCase(fetchDocumentsMessages.fulfilled, (state, action) => {
        state.fetchDocumentsMessages = false;
        state.fetchDocumentsMessagesError = null;
        state.messages = action.payload;
      })
      .addCase(fetchDocumentsMessages.rejected, (state, action) => {
        state.fetchDocumentsMessages = false;
        state.fetchDocumentsMessagesError = action.payload;
      })
      .addCase(fetchDocumentsMessages.pending, (state) => {
        state.fetchDocumentsMessages = true;
        state.fetchDocumentsMessagesError = null;
      });
  },
});

export default documentsSlice.reducer;
