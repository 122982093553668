import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import callApi from "helpers/callApi";
import handleErrorForThunk from "helpers/handleErrorForThunk";
import { parseServerError } from "utils/errors";

export const orderPayment = createAsyncThunk(
  "payment/orderPayment",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi(
        "/payment/order_subscription",
        "POST",
        data
      );
      if (response.ok) {
        return await response.json();
      } else {
        return rejectWithValue(response.status);
      }
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const checkPromoCode = createAsyncThunk(
  "payment/checkPromoCode",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi("/payment/check_promo_code", "POST", data);
      if (response.ok) {
        return await response.json();
      } else {
        return rejectWithValue(response.status);
      }
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const orderVatRegRedirect = createAsyncThunk(
  "payment/orderVatRegRedirect",
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await callApi(
        "/payment/order_vat_registration_button_redirect",
        "POST",
        data
      );
      if (response.ok) {
        return await response.json();
      } else {
        return rejectWithValue(response.status);
      }
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const orderSalesTaxRedirect = createAsyncThunk(
  "payment/orderSalesTaxRedirect",
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await callApi(
        "/payment/order_sales_tax_return_button_redirect",
        "POST",
        data
      );
      if (response.ok) {
        return await response.json();
      } else {
        const errorText = await response.text();
        throw new Error(errorText ?? "Server error!");
      }
    } catch (err) {
      return rejectWithValue(handleErrorForThunk(err));
    }
  }
);

export const changeCardRedirect = createAsyncThunk(
  "payment/changeCardRedirect",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi("/payment/change_card", "POST");
      if (response.ok) {
        return await response.json();
      } else {
        return rejectWithValue(response.status);
      }
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const inviteByEmail = createAsyncThunk(
  "payment/inviteByEmail",
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await callApi("/payment/invite_via_email", "POST", data);
      if (response.ok) {
        return await response.json();
      } else {
        return rejectWithValue(response.status);
      }
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const fetchReferralInv = createAsyncThunk(
  "payment/fetchReferralInv",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi(
        "/payment/get_referral_invitations",
        "GET"
      );
      if (response.ok) {
        return await response.json();
      } else {
        return rejectWithValue(response.status);
      }
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const fetchToken = createAsyncThunk(
  "payment/fetchToken",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi("/payment/get_referral_token", "GET");
      if (response.ok) {
        return await response.json();
      } else {
        return rejectWithValue(response.status);
      }
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const fetchBalance = createAsyncThunk(
  "payment/fetchBalance",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi("/payment/get_referral_balance", "GET");
      if (response.ok) {
        return await response.json();
      } else {
        return rejectWithValue(response.status);
      }
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const orderPaymentRedirect = createAsyncThunk(
  "payment/orderPaymentRedirect",
  async ({ data }, { rejectWithValue }) => {
    console.log('data data +++', data)
    try {
      const response = await callApi(
        "/payment/order_subscription_button_redirect",
        "POST",
        data
      );
      if (response.ok) {
        return await response.json();
      } else {
        return rejectWithValue(response.status);
      }
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const orderPaymentAddons = createAsyncThunk(
  "payment/orderPaymentAddons",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi("/payment/order_addons", "POST", data);
      if (response.ok) {
        return await response.json();
      } else {
        return rejectWithValue(response.status);
      }
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const orderPaymentAddonsRedirect = createAsyncThunk(
  "payment/orderPaymentAddonsRedirect",
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await callApi(
        "/payment/order_addons_button_redirect",
        "POST",
        data
      );
      if (response.ok) {
        return await response.json();
      } else {
        return rejectWithValue(response.status);
      }
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const updateXeroInvoices = createAsyncThunk(
  "payment/updateXeroInvoices",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi("/payment/xero_invoice", "GET");
      if (response.ok) {
        return await response.json();
      } else {
        return rejectWithValue(response.status);
      }
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const updateXeroPayments = createAsyncThunk(
  "payment/updateXeroPayments",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi("/payment/xero_payments", "GET");
      if (response.ok) {
        return await response.json();
      } else {
        return rejectWithValue(response.status);
      }
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const fetchBillingMessages = createAsyncThunk(
  "payment/fetchBillingMessages",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi(
        "/notify/get_system_message_active?models_name=billing%20history",
        "GET"
      );
      if (response.ok) {
        return await response.json();
      } else {
        return rejectWithValue(response.status);
      }
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const orderPaymentChargeInvoiceRedirect = createAsyncThunk(
  "payment/orderPaymentChargeInvoiceRedirect",
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await callApi(
        "/payment/order_charge_invoice_button_redirect",
        "POST",
        data
      );
      if (response.ok) {
        return await response.json();
      } else {
        return rejectWithValue(response.status);
      }
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

export const removeCard = createAsyncThunk(
  "payment/removeCard",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi("/payment/remove_card", "DELETE");
      if (response.ok) {
        return await response.json();
      } else {
        return rejectWithValue(response.status);
      }
    } catch (err) {
      return rejectWithValue("Server error");
    }
  }
);

const initialState = {
  promo: [],
  referralInv: [],
  token: "",
  balance: {},
};

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(orderPayment.pending, (state) => {
        state.ordering = true;
      })
      .addCase(orderPayment.fulfilled, (state, action) => {
        state.ordering = false;
        state.orderingError = null;
        state.result = action.payload;
      })
      .addCase(orderPayment.rejected, (state, action) => {
        state.ordering = false;
        state.orderingError = parseServerError(action.payload);
      })
      .addCase(checkPromoCode.pending, (state) => {
        state.checkingPromo = true;
      })
      .addCase(checkPromoCode.fulfilled, (state, action) => {
        state.checkingPromo = false;
        state.promo = action.payload;
        state.checkingPromoError = null;
      })
      .addCase(checkPromoCode.rejected, (state, action) => {
        state.checkingPromo = false;
        state.promo = [];
        state.checkingPromoError = parseServerError(action.payload);
      })
      .addCase(orderVatRegRedirect.pending, (state) => {
        state.orderingRedirect = true;
      })
      .addCase(orderVatRegRedirect.fulfilled, (state, action) => {
        state.orderingRedirect = false;
        state.orderingRedirectError = null;
        state.resultRedirect = action.payload;
      })
      .addCase(orderVatRegRedirect.rejected, (state, action) => {
        state.orderingRedirect = false;
        state.orderingRedirectError = parseServerError(action.payload);
      })
      .addCase(orderSalesTaxRedirect.pending, (state) => {
        state.orderingSalesTaxRedirect = true;
      })
      .addCase(orderSalesTaxRedirect.fulfilled, (state, action) => {
        state.orderingSalesTaxRedirect = false;
        state.orderingSalesTaxRedirectError = null;
        state.resultSalesTaxRedirect = action.payload;
      })
      .addCase(orderSalesTaxRedirect.rejected, (state, action) => {
        state.orderingSalesTaxRedirect = false;
        state.orderingSalesTaxRedirectError = parseServerError(action.payload);
      })
      .addCase(changeCardRedirect.pending, (state) => {
        state.editingCardRedirect = true;
      })
      .addCase(changeCardRedirect.fulfilled, (state, action) => {
        state.editingCardRedirect = false;
        state.editingCardRedirectError = null;
      })
      .addCase(changeCardRedirect.rejected, (state, action) => {
        state.editingCardRedirect = false;
        state.editingCardRedirectError = parseServerError(action.payload);
      })
      .addCase(inviteByEmail.pending, (state) => {
        state.inviting = true;
      })
      .addCase(inviteByEmail.fulfilled, (state, action) => {
        state.inviting = false;
        state.invitingError = null;
      })
      .addCase(inviteByEmail.rejected, (state, action) => {
        state.inviting = false;
        state.invitingError = parseServerError(action.payload);
      })
      .addCase(fetchReferralInv.pending, (state) => {
        state.fetchingInv = true;
      })
      .addCase(fetchReferralInv.fulfilled, (state, action) => {
        state.fetchingInv = false;
        state.fetchingInvError = null;
        state.referralInv = action.payload;
      })
      .addCase(fetchReferralInv.rejected, (state, action) => {
        state.fetchingInv = false;
        state.fetchingInvError = parseServerError(action.payload);
      })
      .addCase(fetchToken.pending, (state) => {
        state.fetchingToken = true;
      })
      .addCase(fetchToken.fulfilled, (state, action) => {
        state.fetchingToken = false;
        state.fetchingTokenError = null;
        state.token = action.payload;
      })
      .addCase(fetchToken.rejected, (state, action) => {
        state.fetchingToken = false;
        state.fetchingTokenError = parseServerError(action.payload);
      })
      .addCase(fetchBalance.pending, (state) => {
        state.fetchingBalance = true;
      })
      .addCase(fetchBalance.fulfilled, (state, action) => {
        state.fetchingBalance = false;
        state.fetchingBalanceError = null;
        state.balance = action.payload;
      })
      .addCase(fetchBalance.rejected, (state, action) => {
        state.fetchingBalance = false;
        state.fetchingBalanceError = parseServerError(action.payload);
      })
      .addCase(updateXeroInvoices.pending, (state) => {
        state.updatingXeroInv = true;
      })
      .addCase(updateXeroInvoices.fulfilled, (state) => {
        state.updatingXeroInv = false;
        state.updatingXeroInvError = null;
      })
      .addCase(updateXeroInvoices.rejected, (state, action) => {
        state.updatingXeroInv = false;
        state.updatingXeroInvError = parseServerError(action.payload);
      })
      .addCase(updateXeroPayments.pending, (state) => {
        state.updatingXeroPmts = true;
      })
      .addCase(updateXeroPayments.fulfilled, (state) => {
        state.updatingXeroPmts = false;
        state.updatingXeroPmtsError = null;
      })
      .addCase(updateXeroPayments.rejected, (state, action) => {
        state.updatingXeroPmts = false;
        state.updatingXeroPmtsError = parseServerError(action.payload);
      })
      .addCase(fetchBillingMessages.pending, (state) => {
        state.fetchingBlMessages = true;
      })
      .addCase(fetchBillingMessages.fulfilled, (state, action) => {
        state.fetchingBlMessages = false;
        state.fetchingBlMessagesError = null;
        state.billingMessages = action.payload;
      })
      .addCase(fetchBillingMessages.rejected, (state, action) => {
        state.fetchingBlMessages = false;
        state.fetchingBlMessagesError = parseServerError(action.payload);
      }),
});

export default paymentSlice.reducer;
