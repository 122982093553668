import React, { Component } from "react";
import {
  Button,
  TextField,
  FormLayout,
  TextStyle,
  Text, Typography,
} from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import withRouter from "helpers/withRouter";
import { isEmail, isValidPassword } from "utils/validation";
import { forgotComplete, forgotStart } from '../../../../redux/features/user/userSlice'
import AuthContainer from "../../AuthContainer";

import passwordIMG from "img/password.svg";
import emailIMG from "img/email.svg";
import PageHelmet from "components/PageHelmet";
import { createCustomDispatch } from "helpers/customDispatch";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isCodeSent: false,
      email: "",
      errorEmail: false,

      newPassword: "",
      errorNewPassword: false,
      newPasswordRepeat: "",
      errorNewPasswordRepeat: false,

      isPasswordChanged: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.apiKey && nextProps.apiKey) {
      this.props.navigate("/");
    } else if (this.props.apiKey && !nextProps.apiKey) {
      this.props.logout();
    }
  }

  doResendCode(e) {
    if (e) {
      e.preventDefault();
    }
    this.setState({
      email: "",
      isCodeSent: false,
    });
    /*this.props.forgotStart({ email: this.state.email });*/
  }

  doForgotStart() {
    const { email } = this.state;
    const { t } = this.props;
    if (!email) {
      this.setState({
        errorEmail: t("forgotPassword.enterEmail"),
      });
      return;
    }

    this.props
      .forgotStart({ email })
      .then(() => {
        this.setState({
          isCodeSent: true,
        });
      })
      .catch((err) => {
        this.setState({
          serverError: err.error,
        });
      });
  }

  doForgotComplete() {
    const { t } = this.props;
    const { newPassword, newPasswordRepeat } = this.state;
    if (newPassword && newPasswordRepeat && newPassword === newPasswordRepeat) {
      this.props
        .forgotComplete({
          code: this.props.params.code,
          password: newPassword,
        })
        .then((res) => {
          this.setState({
            isPasswordChanged: true,
          });

          setTimeout(() => {
            this.props.navigate("/login");
          }, 1500);
        })
        .catch(() => {});
    } else if (newPassword !== newPasswordRepeat) {
      this.setState({
        errorNewPasswordRepeat: t("forgotPassword.passNotMatch"),
      });
    }
  }

  validateEmail = (email) => {
    const { t } = this.props;

    if (email.length > 0 && !isEmail(email)) {
      this.setState({
        errorEmail: t("forgotPassword.invalidEmail"),
      });
    } else {
      this.setState({
        errorEmail: false,
        serverError: false,
      });
    }
  };

  validatePassword = (password) => {
    const { t } = this.props;

    if (password.length > 0 && password.length < 7) {
      this.setState({
        errorNewPassword: t("forgotPassword.passLeast"),
      });
    } else if (password.length === 0) {
      this.setState({
        errorNewPassword: t("forgotPassword.enterPass"),
      });
    } else if (!isValidPassword(password)) {
      this.setState({
        errorNewPassword: "Use the Latin alphabet and special characters!",
      });
    } else {
      this.setState({
        errorNewPassword: false,
      });
    }
  };

  validatePasswordRepeat = (password) => {
    const { t } = this.props;

    const { newPassword } = this.state;
    if (password.length === 0) {
      this.setState({
        errorNewPasswordRepeat: t("forgotPassword.repeatPass"),
      });
    } else if (password !== newPassword) {
      this.setState({
        errorNewPasswordRepeat: t("forgotPassword.passNotMatch"),
      });
    }
  };

  render() {
    const { t } = this.props;
    let title = "";
    let description = "";

    const {
      email,
      errorEmail,
      newPassword,
      errorNewPassword,
      newPasswordRepeat,
      errorNewPasswordRepeat,
      serverError,
    } = this.state;

    if (this.props.params.code) {
      title = "Create password";
      description =
        "Strength requirements: min 8 characters, use symbols, numbers, letters, and special characters";
    } else if (!this.props.params.code && this.state.isCodeSent) {
      title = "We sent you a link to reset your password";
      description = `The letter was sent to ${email}.`;
    } else if (!this.props.params.code && !this.state.isCodeSent) {
      title = "Forgot password?";
      description =
        "Enter your email associated with your account and we will send an email with instruction to reset your password";
    }

    return (
      <AuthContainer
        title={title}
        description={description}
        content={
          <>
            <PageHelmet title='Forgot password' />
            {!this.props.params.code && !this.state.isCodeSent && (
              <form>
                <TextField
                  name='email'
                  type='email'
                  prefix={
                    <img src={emailIMG} alt='email' style={{ marginTop: 6 }} />
                  }
                  label={
                    <Text color='subdued' variant='bodySm'>
                      {/* {t('login.email')} */}
                      Enter your email
                    </Text>
                  }
                  placeholder={t("forgotPassword.email")}
                  autoComplete='email'
                  value={email}
                  error={errorEmail || serverError}
                  onBlur={() => this.validateEmail(email)}
                  onChange={(value) => {
                    this.setState({ email: value, errorEmail: false });
                  }}
                />
                <br />
                <div>
                  <Button variant={'primary'} onClick={() => this.doForgotStart()}>
                    {t("forgotPassword.recover")}
                  </Button>
                  <button
                    ref='formSubmit'
                    style={{ display: "none" }}
                    type='submit'
                  >
                    {t("forgotPassword.signIn")}
                  </button>
                </div>
              </form>
            )}
            {!this.props.params.code && this.state.isCodeSent && (
              <Button onClick={() => this.doResendCode()} primary>
                Resend an email
              </Button>
            )}
            {this.props.params.code && (
              <form ref='formComplete'>
                <FormLayout>
                  <TextField
                    name='password'
                    type='password'
                    prefix={
                      <img
                        src={passwordIMG}
                        alt='password'
                        style={{ marginTop: 6 }}
                      />
                    }
                    placeholder={t("forgotPassword.password")}
                    label={
                      <Text color='subdued' variant='bodySm'>
                        {/* {t('forgotPassword.password')} */}
                        Create password
                      </Text>
                    }
                    value={newPassword}
                    error={errorNewPassword}
                    onBlur={() => this.validatePassword(newPassword)}
                    onChange={(value) => {
                      this.setState({
                        newPassword: value,
                        errorNewPassword: false,
                      });
                    }}
                  />
                  <TextField
                    name='password_rereat'
                    type='password'
                    prefix={
                      <img
                        src={passwordIMG}
                        alt='password'
                        style={{ marginTop: 6 }}
                      />
                    }
                    // placeholder={t('forgotPassword.repPass')}
                    placeholder='Repeat your password'
                    label={
                      <Text color='subdued' variant='bodySm'>
                        {t("forgotPassword.repPass")}
                      </Text>
                    }
                    value={newPasswordRepeat}
                    error={errorNewPasswordRepeat}
                    onBlur={() =>
                      this.validatePasswordRepeat(newPasswordRepeat)
                    }
                    onChange={(value) => {
                      this.setState({
                        newPasswordRepeat: value,
                        errorNewPasswordRepeat: false,
                      });
                    }}
                  />
                </FormLayout>

                {this.props.forgotCompleteError && (
                  <div>
                    <Text variant="bodyMd" color="critical">
                      {t("forgotPassword.recLinkInvalid")}
                    </Text>
                    <br />
                  </div>
                )}
                {this.state.isPasswordChanged &&
                  !this.props.forgotCompleteError && (
                    <div>
                      <Text variant="bodyMd" color="success">
                        {t("forgotPassword.passChanged")}
                      </Text>
                      <br />
                    </div>
                  )}
                <br />
                <div>
                  <Button variant={'primary'} onClick={() => this.doForgotComplete()}>
                    Recover
                  </Button>
                  <button
                    ref='formSubmit'
                    style={{ display: "none" }}
                    type='submit'
                  >
                    {t("forgotPassword.signIn")}
                  </button>
                </div>
              </form>
            )}
          </>
        }
      />
    );
  }
}

const mapStateToProps = (state) => ({
  apiKey: state.user.api_key,
  forgotStartError: state.user.forgotStartError,
  forgotCompleteError: state.user.forgotCompleteError,
  refererLang: state.user.refererLang,
});

const mapDispatchToProps = (defaultDispatch) => {
  const dispatch = createCustomDispatch(defaultDispatch);

  return {
    forgotStart: (data) => dispatch(forgotStart(data)),
    forgotComplete: (data) => dispatch(forgotComplete(data)),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(ForgotPassword))
);
