import React from "react";

const DashboardIcon = ({ selected }) => {
  if (selected) {
    return (
      <svg
        viewBox='0 0 21 21'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        style={{ width: "18", height: "18" }}
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M11.0228 0.336112C10.6805 0.243432 10.3197 0.243432 9.97745 0.336112C9.58008 0.443701 9.24553 0.706355 8.97853 0.915985L8.90389 0.974402L2.04388 6.30988C1.66725 6.60216 1.33544 6.85966 1.08818 7.19396C0.871188 7.48735 0.709542 7.81785 0.61118 8.16926C0.4991 8.56967 0.499554 8.98968 0.500069 9.46642L0.500139 16.8383C0.500121 17.3654 0.500105 17.8202 0.530709 18.1948C0.56301 18.5901 0.634326 18.9833 0.82712 19.3617C1.11474 19.9262 1.57368 20.3851 2.13817 20.6728C2.51655 20.8656 2.90977 20.9369 3.30511 20.9692C3.67968 20.9998 4.13443 20.9998 4.66157 20.9997H16.3387C16.8658 20.9998 17.3206 20.9998 17.6952 20.9692C18.0905 20.9369 18.4837 20.8656 18.8621 20.6728C19.4266 20.3851 19.8855 19.9262 20.1732 19.3617C20.366 18.9833 20.4373 18.5901 20.4696 18.1948C20.5002 17.8202 20.5002 17.3654 20.5001 16.8383L20.5002 9.46643C20.5007 8.98969 20.5012 8.56967 20.3891 8.16926C20.2907 7.81785 20.1291 7.48735 19.9121 7.19396C19.6648 6.85966 19.333 6.60216 18.9564 6.30987L12.0964 0.974403L12.0218 0.915989C11.7547 0.706358 11.4202 0.443702 11.0228 0.336112ZM7.50015 12.5998V18.9998H13.5002V12.5998C13.5002 12.0397 13.5002 11.7597 13.3912 11.5458C13.2953 11.3576 13.1423 11.2046 12.9541 11.1087C12.7402 10.9998 12.4602 10.9998 11.9002 10.9998H9.10015C8.5401 10.9998 8.26007 10.9998 8.04616 11.1087C7.858 11.2046 7.70502 11.3576 7.60915 11.5458C7.50015 11.7597 7.50015 12.0397 7.50015 12.5998Z'
          fill='rgba(38, 127, 215, 1)'
        />
      </svg>
    );
  }
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path opacity='0.12' d='M7.5 17.5V10H12.5V17.5' fill='#707070' />
      <path
        d='M7.5 17.4996V11.333C7.5 10.8662 7.5 10.6329 7.59083 10.4546C7.67072 10.2978 7.79821 10.1703 7.95501 10.0904C8.13327 9.99962 8.36662 9.99962 8.83333 9.99962H11.1667C11.6334 9.99962 11.8667 9.99962 12.045 10.0904C12.2018 10.1703 12.3293 10.2978 12.4092 10.4546C12.5 10.6329 12.5 10.8662 12.5 11.333V17.4996M9.18141 2.30297L3.52949 6.6989C3.15168 6.99275 2.96278 7.13968 2.82669 7.32368C2.70614 7.48667 2.61633 7.67029 2.56169 7.86551C2.5 8.0859 2.5 8.32521 2.5 8.80384V14.833C2.5 15.7664 2.5 16.2331 2.68166 16.5896C2.84144 16.9032 3.09641 17.1582 3.41002 17.318C3.76654 17.4996 4.23325 17.4996 5.16667 17.4996H14.8333C15.7668 17.4996 16.2335 17.4996 16.59 17.318C16.9036 17.1582 17.1586 16.9032 17.3183 16.5896C17.5 16.2331 17.5 15.7664 17.5 14.833V8.80384C17.5 8.32521 17.5 8.0859 17.4383 7.86551C17.3837 7.67029 17.2939 7.48667 17.1733 7.32368C17.0372 7.13968 16.8483 6.99275 16.4705 6.69891L10.8186 2.30297C10.5258 2.07526 10.3794 1.9614 10.2178 1.91763C10.0752 1.87902 9.92484 1.87902 9.78221 1.91763C9.62057 1.9614 9.47418 2.07526 9.18141 2.30297Z'
        stroke='#707070'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default DashboardIcon;
