import React, { useEffect } from "react";
import {
	Button,
	DataTable,
	Text,
	TextField,
	Tooltip,
} from "@shopify/polaris";
import formatRow from "utils/formatRow";
import { t } from "i18next";
import deleteIMG from "img/delete.svg";
import PlusCircleIMG from "img/plus-circle.svg";
import { getCurrencySymbol } from '../../../../utils/currency'
import { formatMoney } from '../../../../utils/numbers'
import { createEmptyLineItem } from '../../../DocumentTemplate/common/utils'

const AddTableItems = ({ data, setData }) => {
	const { line_items } = data;
	const onAddRowClick = () => {
		setData((prevTemplateData) => ({
			...prevTemplateData,
			line_items: [...prevTemplateData.line_items, createEmptyLineItem()],
		}));
	};
	
	const onRowDelete = (index) => {
		setData((prevTemplateData) => ({
			...prevTemplateData,
			line_items: prevTemplateData.line_items.filter(
				({ rowIndex }) => rowIndex !== index
			),
		}));
	};
	
	const handleLineAddTableItemsChange = (rowIndex, id, newValue) => {
		const parsedValue = parseFloat(newValue);
		const isNegative = parsedValue < 0;
		
		setData((prevTemplateData) => ({
			...prevTemplateData,
			line_items: prevTemplateData.line_items.map((item) => {
				if (item.rowIndex === rowIndex) {
					const updatedItem = {
						...item,
						[id]: isNegative ? "0" : newValue,
					};
					
					const unitPrice = parseFloat(updatedItem.unit_price) || 0;
					const quantity = parseFloat(updatedItem.quantity) || 0;
					updatedItem.total = (unitPrice * quantity).toFixed(2);
					
					return updatedItem;
				}
				return item;
			}),
		}));
	};
	
	
	
	const columns = [
		{
			property: "index",
			header: {
				label: "",
			},
			cell: {
				formatters: [(_, { index }) => index + 1],
			},
		},
		{
			property: "title",
			header: {
				label: "Title",
			},
			cell: {
				formatters: [
					(_, { rowData, index }) => {
						const { rowIndex } = rowData;
						const id = "title";
						const value = line_items[index]?.[id];
						
						return (
							<TextField
								id={id}
								type='text'
								inputMode='text'
								placeholder='Item title'
								value={value}
								onChange={(newValue) =>
									handleLineAddTableItemsChange(rowIndex, id, newValue)
								}
							/>
						);
					},
				],
			},
		},
		{
			property: "unit_price",
			header: {
				label: "Unit price",
			},
			cell: {
				formatters: [
					(_, { rowData, index }) => {
						const { rowIndex } = rowData;
						const id = "unit_price";
						const value = line_items[index]?.[id];
						
						return (
							<TextField
								id={id}
								type='number'
								inputMode='numeric'
								placeholder='Unit price'
								value={value}
								onChange={(newValue) => handleLineAddTableItemsChange(rowIndex, id, newValue)}
								min={0}
							/>
						);
					},
				],
			},
		},
		{
			property: "quantity",
			header: {
				label: "Quantity",
			},
			cell: {
				formatters: [
					(_, { rowData, index }) => {
						const { rowIndex } = rowData;
						const id = "quantity";
						const value = line_items[index]?.[id];
						return (
							<TextField
								id={id}
								type='number'
								inputMode='numeric'
								placeholder='Quantity'
								value={value}
								onChange={(newValue) => {
									handleLineAddTableItemsChange(rowIndex, id, newValue)
								}}
								min={0}
							/>
						);
					},
				],
			},
		},
		// {
		// 	property: "total",
		// 	header: {
		// 		label: "Total",
		// 	},
		// 	cell: {
		// 		formatters: [
		// 			(_, { rowData, index }) => {
		// 				const { rowIndex } = rowData;
		// 				const id = "total";
		// 				const value = line_items[index]?.[id];
		//
		// 				return (
		// 					<TextField
		// 						id={id}
		// 						type='number'
		// 						inputMode='numeric'
		// 						placeholder='Total'
		// 						value={value}
		// 						onChange={(newValue) => handleLineAddTableItemsChange(rowIndex, id, newValue)}
		// 						min={0}
		// 					/>
		// 				);
		// 			},
		// 		],
		// 	},
		// },
		{
			property: "total",
			header: {
				label: "Total",
			},
			cell: {
				formatters: [
					(_, { rowData }) => {
						const { quantity, unit_price } = rowData;
						const totalValue = Number(quantity) * Number(unit_price);
						const total = formatMoney(totalValue || "0.00");
						
						return (
							<div className='totals-table-total'>
                <Text variant='bodyLg'>
                  {getCurrencySymbol(rowData?.currency)}
	                {total}
                </Text>
              </div>
						);
					},
				],
			},
		},
		{
			property: "rowIndex",
			header: {
				label: "",
			},
			cell: {
				formatters: [
					(rowIndex) => {
						return (
							<>
                {line_items?.length > 1 && (
	                <Tooltip content={t("profileMemb.delete")}>
                    <Button variant={'plain'} onClick={() => onRowDelete(rowIndex)}>
                      <img src={deleteIMG} alt={t("profileMemb.delete")} />
                    </Button>
                  </Tooltip>
                )}
              </>
						);
					},
				],
			},
		},
	];
	
	const rows = formatRow(line_items, columns);
	
	return (
		<>
      <DataTable
	      headings={columns && columns?.map(({ header }) => (
		      <Text fontWeight='semibold'>{header?.label}</Text>
	      ))}
	      rows={rows}
	      columnContentTypes={[
		      "text",
		      "text",
		      "text",
		      "text",
	      ]}
      />
      <Button
	      variant={'plain'}
	      icon={
		      <img
			      src={PlusCircleIMG}
			      alt='add company plus button'
			      style={{ marginTop: 3, marginRight: 4 }}
		      />
	      }
	      onClick={onAddRowClick}
      >
        Add new item
      </Button>
    </>
	);
};

export default AddTableItems;
