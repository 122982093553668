import React, { useState } from 'react';
import { Button, Popover, ActionList } from '@shopify/polaris';

const Dropdown = ({ choices, selected, onChange }) => {
	const [active, setActive] = useState(false);
	
	const togglePopover = () => setActive((prev) => !prev);
	
	const handleChoiceSelect = (value) => {
		onChange(value);
		setActive(false);
	};
	return (
		<div>
			{
				!active
				&&
				<Button onClick={togglePopover} ariaHaspopup="true" size="micro" variant="secondary">
				  {selected && selected.length > 0
					  ? choices?.find((choice) => choice?.value === selected[0])?.label
					  : 'All'}
				</Button>
				
			}
			{active && (
				<Popover
					active={active}
					activator={<Button onClick={togglePopover} size={'micro'} variant={'secondary'}>Filter</Button>}
					onClose={() => setActive(false)}
				>
          <ActionList
	          items={choices.map((choice) => ({
		          content: choice.label,
		          onAction: () => {
			          handleChoiceSelect(choice.value)
		          },
	          }))}
          />
        </Popover>
			)}
    </div>
	);
};

export default Dropdown;
