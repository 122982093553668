import React, { Component } from "react";
import { isEmpty } from "lodash";
import { Card, DataTable, Text } from "@shopify/polaris";
import { formatMoney } from "utils/numbers";
import { withTranslation } from "react-i18next";

class VatReturnROWPreview extends Component {
  renderSummaryRow = (sum, vat, title) => {
    const { currency } = this.props;
    const currencyIcon =
      currency.toLowerCase() === "eur" ? " \u{20AC} " : currency;

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        <Text variant='bodyMd'>
          {title}: {formatMoney(sum)} {currencyIcon}
        </Text>
        <Text variant='bodyMd'>
          VAT due: {formatMoney(vat)} {currencyIcon}
        </Text>
      </div>
    );
  };

  renderSummary = () => {
    const {
      sumOfServices,
      sumOfServicesCorrected,
      vatOfServices,
      vatOfServicesCorrected,
      step,
    } = this.props;

    return (
      <div>
        {this.renderSummaryRow(
          sumOfServices,
          vatOfServices,
          "Sales of services"
        )}
        {step > 1 &&
          this.renderSummaryRow(
            sumOfServicesCorrected,
            vatOfServicesCorrected,
            "Sales of services corrected"
          )}
        <br />
      </div>
    );
  };

  renderRowByType = (data, country, title, type) =>
    data.map((item, ind) => {
      return [
        ind === 0 ? country : "",
        ind === 0 ? title : "",
        formatMoney(item.summ),
        `${item.vat_percent}%`,
        formatMoney(item.vat),
      ];
    });

  renderSumRow = (title, revenue, vat) => {
    return [
      <Text fontWeight='semibold' variant={'bodyMd'}>{title}</Text>,
      "",
      <Text fontWeight='semibold'>{revenue}</Text>,
      "",
      <Text fontWeight='semibold'>{vat}</Text>,
    ];
  };

  columns = [
    this.props.t("createReturns.country"),
    "",
    `Revenue (${this.props.currency})`,
    "VAT rate",
    "VAT",
  ];

  getRows = () => {
    const {
      data,
      totalSumDue,
      totalSumRefund,
      sumOfServicesCorrected,
      sumOfServices,
      vatOfServices,
      vatOfServicesCorrected,
      t,
    } = this.props;

    const rows = !isEmpty(data)
      ? data
          .map((item) => {
            const salesOfServicesRows = this.renderRowByType(
              item.sales_of_services_oss || item.sales_of_services,
              item.country_name,
              "Sales of services",
              "s"
            );

            const salesOfServicesCorrectedRows = this.renderRowByType(
              item.sales_of_services_corrected,
              item.country_name,
              "Sales of services corrected",
              "s-crd"
            );

            return [...salesOfServicesRows, ...salesOfServicesCorrectedRows];
          })
          .flat()
      : [["-", "", "0", "-", "0"]];

    if (!isFinite(totalSumDue) && !isFinite(totalSumRefund)) {
      const revenue = formatMoney(sumOfServices + sumOfServicesCorrected);
      const vat = formatMoney(vatOfServicesCorrected + vatOfServices);

      rows.push(this.renderSumRow("Total", revenue, vat));
    }

    return rows;
  };

  render() {
    const { data } = this.props;
    
    return (
      <div>
        {this.renderSummary()}
        {data && (
          <Card>
            <DataTable
              columnContentTypes={["text", "text", "text", "numeric"]}
              headings={this.columns.map((label) => (
                <Text fontWeight='semibold' variant={'bodyMd'}>{label}</Text>
              ))}
              rows={this.getRows()}
              hideScrollIndicator
              increasedTableDensity
            />
          </Card>
        )}
      </div>
    );
  }
}

export default withTranslation()(VatReturnROWPreview);
