import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import ArrowLeftMinor from '../../img/arrow-left.svg'

const GoBackPageNavigation = ({content}) => {
	const navigate = useNavigate()
	
	// useEffect(() => {
	// 	const handleKeyDown = (event) => {
	// 		if (event.key === "Backspace") {
	// 			goBack()
	// 		}
	// 	}
	// 	document.addEventListener("keydown", handleKeyDown)
	// 	return () => {
	// 		document.removeEventListener("keydown", handleKeyDown)
	// 	}
	// }, [])
	
	const goBack = () => {
		navigate(-1)
	}
	return (
		<div style={{display: 'flex', alignItems: 'center'}}>
      <div
        style={{
          marginRight: '10px',
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer'
        }}
      >
        <div
          onClick={goBack}
          style={{
            width: '25px',
            height: '25px',
            backgroundColor: 'transparent',
            borderRadius: '50%',
            border: '2px solid #637381',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
	          marginRight: 10
          }}
        >
          <img
            style={{cursor: 'pointer', width: '10px', height: '10px'}}
            src={ArrowLeftMinor}
            alt={'ArrowLeftMinor'}
          />
        </div >
	      
	      {content}
	      
      </div >
    </div >
	)
}

export default GoBackPageNavigation