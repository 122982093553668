import React from 'react'

const Customers = () => {
	return (
		<>
			<h1 >Customers</h1 >
		</>
	)
}

export default Customers