import { Button, Text } from '@shopify/polaris'
import useAppDispatch from 'hooks/useAppDispatch'
import addIMG from "img/add-white.svg"
import deleteIMG from "img/delete.svg"
import editIMG from "img/edit.svg"
import { ReactComponent as NoDataImage } from "img/noData.svg"
import moment from 'moment/moment'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getDeclarationTransaction } from '../../../../../redux/features/declarations/declarationsSlice'
import { isEditDeclarationTransaction, isVoidTransaction } from '../../../../../redux/features/transactions/transactionsSlice'

const ListTransactions = ({active, data, changeVatStr, setDateRangeHandler}) => {
	const {declarationsTransactions} = useSelector((state) => state.declarations)
	const [loaderForDeclarationTransaction, setLoaderForDeclarationTransaction] = useState(false)
	const {fetchingIsVoidError, fetchingEditDeclarationError} = useSelector((state) => state.transactions)
	const [isVoidStatus, setIsVoidStatus] = useState({id: null, status: false})
	
	const [changeEditElement, setChangeEditElement] = useState(null)
	const [inputValue, setInputValue] = useState('')
	const dispatch = useAppDispatch()
	const inputRef = useRef()
	const {t} = useTranslation()
	
	const tehData = {
		...data,
		a_name: changeVatStr.title,
		a_percent: Number(changeVatStr.count),
		currency: '',
		limit: 25,
		offset: 0
	}
	
	const getAllDeclarationTransaction = useCallback(async () => {
		try {
			setLoaderForDeclarationTransaction(true)
			const res = await dispatch(getDeclarationTransaction(tehData))
			
			if (res) {
				setLoaderForDeclarationTransaction(false)
				res?.parameters && setDateRangeHandler({date_end: res?.parameters?.data_end, date_start: res?.parameters?.data_start})
			}
		} catch (e) {
			setLoaderForDeclarationTransaction(false)
		}
	}, [active])
	
	const editTransactionHandler = async (id) => {
		setInputValue('')
		setChangeEditElement(id)
		
	}
	
	const handleInputChange = (value) => {
		if (/^[-]?\d*(\.\d+)?$/.test(value)) {
			setInputValue(value)
		}
	}
	
	const handleButtonClick = async (id) => {
		if (inputValue !== '') {
			try {
				const res = await dispatch(isEditDeclarationTransaction({id: id, data: {transaction_sum: inputValue}}))
				// const res = await dispatch(isEditDeclarationTransaction({id: id, data: {transaction_sum: inputValue}}))
				if (res.transaction_sum) {
					setChangeEditElement(null)
					await dispatch(getDeclarationTransaction(tehData))
				}
			} catch (e) {
				await dispatch(fetchingEditDeclarationError(e))
			}
		} else {
			setChangeEditElement(null)
		}
	}
	
	const disabledTransactionHandler = async (id) => {
		try {
			const res = await dispatch(isVoidTransaction({id}))
			setIsVoidStatus({id: id, status: res.is_void})
			if (res.status === 200) await dispatch(getDeclarationTransaction(tehData))
		} catch (e) {
			await dispatch(fetchingIsVoidError(e))
		}
	}
	
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (inputRef.current && !inputRef.current.contains(event.target)) {
				setChangeEditElement(null)
			}
		}
		
		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [inputRef])
	
	useEffect(() => {
		if (active === true) getAllDeclarationTransaction()
	}, [active])
	
	return (
		<ul className={`list-vat-items ${loaderForDeclarationTransaction ? 'loading' : ''}`}>
	        {loaderForDeclarationTransaction ?
		        <Button loading={loaderForDeclarationTransaction}></Button >
		        :
		        (declarationsTransactions?.items?.length !== 0 && !loaderForDeclarationTransaction)
			        ?
			        <table className='Polaris-DataTable__Table table-bordered'>
				        <thead >
					        <tr >
						        <th className='Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--header table-cell'>№</th >
						        <th className='Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--header table-cell'>Transaction ID</th >
						        <th className='Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--header table-cell'>Date</th >
						        <th className='Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--header table-cell'>Transaction Type</th >
						        <th className='Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--header table-cell'>Currency</th >
						        <th className='Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--header table-cell'>Transaction amount <br /> (with tax) </th >
						        <th className='Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--header table-cell'>TAX Rate</th >
						        <th className='Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--header table-cell'>TAX Amount</th >
						        <th className='Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--header table-cell'></th >
					        </tr >
				        </thead >
				        <tbody >
					        {declarationsTransactions?.items?.map((item,
						        index) => (
						        <tr
							        className={`Polaris-DataTable__TableRow Polaris-DataTable--hoverable ${item?.noncomplete? 'yellowBgLine' : ''}`}
							        key={item?.transaction_id}
						        >
							        {/*{console.log('item', item)}*/}
				              <td
					              className='Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop table-cell'
				              >{index + 1}</td >
				              <td
					              className='Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop table-cell'
					              // style={{textDecoration:`${isVoidStatus.id === item?.transaction_id && isVoidStatus.status === true ? 'line-through' : ''}`}}
					              title={item?.transaction_id}
				              >{item?.transaction_id}</td >
				              <td
					              className='Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop table-cell'
				              >{moment(item?.transaction_datetime).format("DD-MM-YYYY hh:mm")}</td >
				              <td
					              className='Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop table-cell'
					              title={item?.transaction_type}
				              >{item?.transaction_type}</td >
				              <td
					              className='Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop table-cell'
					              title={item?.currency_id}
				              >{item?.currency_id}</td >
				              <td
					              className='Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop table-cell'
					              title={item?.transaction_sum}
				              >{item?.transaction_sum}
					              {
						              item.transaction_id === changeEditElement
						              &&
						              <div
							              ref={inputRef}
							              style={{alignItems: 'center', justifyContent: 'center', display: 'flex'}}
						              >
								              {/*{*/}
							              {/*  (fetchingEditDeclarationError) &&*/}
							              {/*  <Banner tone='critical'>*/}
							              {/*    <p >{`${fetchingEditDeclarationError?.[0].msg || ""}`}</p >*/}
							              {/*  </Banner >*/}
							              {/*}*/}
							              <input
								              label='Enter a number'
								              value={inputValue}
								              onChange={e => handleInputChange(e.target.value)}
								              autoComplete='off'
								              autoFocus
								              type='number' // Ограничиваем ввод чисел
								              style={{
									              width: '80px',
									              height: '30px',
									              borderRadius: '4px',
									              color: '#222',
									              padding: '0 10px',
									              MozAppearance: 'textfield',
									              appearance: 'none',
								              }}
							              />
												      <button
													      onClick={() => handleButtonClick(item.id)}
													      style={{
														      width: '50px',
														      height: '30px',
														      backgroundColor: 'rgba(38, 127, 215, 1)',
														      border: 'none',
														      alignItems: 'center',
														      justifyContent: 'center',
														      display: 'flex',
														      borderRadius: '4px',
														      cursor: 'pointer',
														      color: 'white',
														      fontSize: '14px',
														      marginLeft: '3px'
													      }}
												      >
													      <img
														      style={{cursor: 'pointer'}}
														      src={addIMG}
														      alt='plus'
													      />
												      </button >
												    </div >
					              }
											</td >
				              <td
					              className='Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop table-cell'
					              title={`${item?.vat_percent}%`}
				              >{item?.vat_percent}%</td >
				              <td
					              className='Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop table-cell'
					              title={item?.vat}
				              >{item?.vat}</td >
				              <td
					              className='Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop table-cell'
				              >
							              <button
								              className='table-cell_button'
								              onClick={() => editTransactionHandler(item.transaction_id)}
							              >
								              <img
									              className='table-cell_button-icon'
									              src={editIMG}
									              alt='edit icon'
									              title='Edit Sum Transaction'
								              />
							              </button >
					              {item.transaction_id === isVoidStatus.id ?
						              <Button loading={isVoidStatus.status}></Button >
						              :
						              <button
							              className='table-cell_button'
							              onClick={() => disabledTransactionHandler(item.transaction_id)}
						              >
							              {/*{*/}
							              {/*  (fetchingIsVoidError) &&*/}
							              {/*  <Banner tone='critical'>*/}
							              {/*      <p >{`${fetchingIsVoidError.error || ""}`}</p >*/}
							              {/*    </Banner >*/}
							              {/*}*/}
							              <img
								              className='table-cell_button-icon'
								              src={deleteIMG}
								              alt='edit icon'
								              title='Disable Transaction'
							              />
												</button >
					              }
				              </td >
				            </tr >
					        ))}
								</tbody >
				      </table >
			        :
			        <div className='noDataMessageWrap'>
				        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
					        <NoDataImage />
					        <div style={{display: 'flex', flexDirection: 'column', gap: '4px'}}>
						        {
							        <Text
								        color='subdued'
								        fontWeight='medium'
							        >
						            {t('noDataMessage.nothingToShowHere')}
											</Text >
						        }
						        {/*{description && <Text color='subdued'>{description}</Text >}*/}
						        {/*{buttonText && (*/}
						        {/*  <Button*/}
						        {/*    plain*/}
						        {/*    icon={<NoDataPlusBtn style={{marginTop: "4px"}} />}*/}
						        {/*    onClick={onClick}*/}
						        {/*  >*/}
						        {/*    {buttonText}*/}
						        {/*  </Button >*/}
						        {/*)}*/}
									</div >
								</div >
							</div >
	        }
        </ul >
	)
}

export default ListTransactions