import { Card, DataTable, Text } from "@shopify/polaris";
import React from "react";
import formatRow from "utils/formatRow";
import { t } from "i18next";

const TextilePreviewSimplified = ({ data }) => {
  const { household_linen, clothing, footwear } = data;
  const columns = [
    {
      property: "clothing",
      header: {
        label: t("eprReporting.clothing"),
      },
    },
    {
      property: "household_linen",
      header: {
        label: t("eprReporting.householdLinen"),
      },
    },
    {
      property: "footwear",
      header: {
        label: t("eprReporting.footwear"),
      },
    },
  ];

  const sortedRows = formatRow(
    [{ household_linen, clothing, footwear }],
    columns
  );

  return (
    <Card>
      <DataTable
        columnContentTypes={["text", "text", "text"]}
        headings={columns.map(({ header }) => (
          <Text fontWeight='semibold' variant={'bodyMd'}>{header.label}</Text>
        ))}
        rows={sortedRows}
        hideScrollIndicator
      />
    </Card>
  );
};

export default TextilePreviewSimplified;
