import React from "react";
import {
  Button,
  Checkbox,
  DataTable,
  Text,
  TextField,
  Tooltip,
} from "@shopify/polaris";
import { formatMoney } from "utils/numbers";
import { getCurrencySymbol } from "utils/currency";
import formatRow from "utils/formatRow";
import { t } from "i18next";
import deleteIMG from "img/delete.svg";
import PlusCircleIMG from "img/plus-circle.svg";
import { createEmptyLineItem } from "../../common/utils";

const Items = ({ data, setData }) => {
  const { line_items, currency } = data;
  const onAddRowClick = () => {
    setData((prevTemplateData) => ({
      ...prevTemplateData,
      line_items: [...prevTemplateData.line_items, createEmptyLineItem()],
    }));
  };

  const onRowDelete = (index) => {
    setData((prevTemplateData) => ({
      ...prevTemplateData,
      line_items: prevTemplateData.line_items.filter(
        ({ rowIndex }) => rowIndex !== index
      ),
    }));
  };

  const handleLineItemsChange = (rowIndex, id, newValue) => {
    const parsedValue = parseFloat(newValue);
    const isNegative = parsedValue < 0;

    setData((prevTemplateData) => ({
      ...prevTemplateData,
      line_items: prevTemplateData.line_items.map((item) => {
        if (item.rowIndex === rowIndex) {
          return {
            ...item,
            [id]: isNegative ? '0' : newValue,
          };
        }
        return item;
      }),
    }));
  };


  const columns = [
    {
      property: "index",
      header: {
        label: "",
      },
      cell: {
        formatters: [(_, { index }) => index + 1],
      },
    },
    {
      property: "description",
      header: {
        label: "Description",
      },
      cell: {
        formatters: [
          (_, { rowData, index }) => {
            const { rowIndex } = rowData;
            const id = "description";
            const value = line_items[index]?.[id];

            return (
              <TextField
                id={id}
                type='text'
                inputMode='text'
                placeholder='Item description'
                value={value}
                onChange={(newValue) =>
                  handleLineItemsChange(rowIndex, id, newValue)
                }
              />
            );
          },
        ],
      },
    },
    {
      property: "unit_price",
      header: {
        label: "Unit price",
      },
      cell: {
        formatters: [
          (_, { rowData, index }) => {
            const { rowIndex } = rowData;
            const id = "unit_price";
            const value = line_items[index]?.[id];

            return (
              <TextField
                id={id}
                type='number'
                inputMode='numeric'
                placeholder='Unit price'
                value={value}
                onChange={(newValue) => handleLineItemsChange(rowIndex, id, newValue)}
                min={0}
              />
            );
          },
        ],
      },
    },
    {
      property: "quantity",
      header: {
        label: "Quantity",
      },
      cell: {
        formatters: [
          (_, { rowData, index }) => {
            const { rowIndex } = rowData;
            const id = "quantity";
            const value = line_items[index]?.[id];

            return (
              <TextField
                id={id}
                type='number'
                inputMode='numeric'
                placeholder='Quantity'
                value={value}
                onChange={(newValue) => handleLineItemsChange(rowIndex, id, newValue)}
                min={0}
              />
            );
          },
        ],
      },
    },
    {
      property: "tax",
      header: {
        label: "Tax",
      },
      cell: {
        formatters: [
          (_, { rowData, index }) => {
            const { rowIndex } = rowData;
            const id = "tax";
            const value = line_items[index]?.[id];

            return (
              <Checkbox
                id={`${id}-${rowIndex}`}
                checked={value}
                onChange={(newValue) =>
                  handleLineItemsChange(rowIndex, id, newValue)
                }
              />
            );
          },
        ],
      },
    },
    {
      property: "total",
      header: {
        label: "Total",
      },
      cell: {
        formatters: [
          (_, { rowData }) => {
            const { quantity, unit_price } = rowData;
            const totalValue = Number(quantity) * Number(unit_price);
            const total = formatMoney(totalValue || "0.00");

            return (
              <div className='totals-table-total'>
                <Text variant='bodyLg'>
                  {getCurrencySymbol(currency)}
                  {total}
                </Text>
              </div>
            );
          },
        ],
      },
    },
    {
      property: "rowIndex",
      header: {
        label: "",
      },
      cell: {
        formatters: [
          (rowIndex) => {
            return (
              <>
                {line_items?.length > 1 && (
                  <Tooltip content={t("profileMemb.delete")}>
                    <Button variant={'plain'} onClick={() => onRowDelete(rowIndex)}>
                      <img src={deleteIMG} alt={t("profileMemb.delete")} />
                    </Button>
                  </Tooltip>
                )}
              </>
            );
          },
        ],
      },
    },
  ];

  const rows = formatRow(line_items, columns);

  return (
    <>
      <DataTable
        headings={columns && columns?.map(({ header }) => (
          <Text fontWeight='semibold'>{header?.label}</Text>
        ))}
        rows={rows}
        columnContentTypes={[
          "text",
          "text",
          "text",
          "text",
          "text",
          "text",
          "text",
        ]}
      />
      <Button
        variant={'plain'}
        icon={
          <img
            src={PlusCircleIMG}
            alt='add company plus button'
            style={{ marginTop: 3, marginRight: 4 }}
          />
        }
        onClick={onAddRowClick}
      >
        Add new item
      </Button>
    </>
  );
};

export default Items;
