import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  Page,
  Button,
  Layout,
  Card,
  Select,
  FormLayout,
  TextField,
  Checkbox,
  Spinner,
  Text,
} from "@shopify/polaris";
import withRouter from "helpers/withRouter";
import _ from "lodash";
import GoBackPageNavigation from '../../components/GoBackPageNavigation/GoBackPageNavigation'
import { fetchRegNumbers } from "../../redux/features/registration/registrationSlice";
import { fetchCountries, fetchCurrency } from "../../redux/features/other/otherSlice";
import Modal from "components/Modal";
import Payment from "../Payment";
import PageHelmet from "components/PageHelmet";
import { getYears } from "utils/years";
import { createCustomDispatch } from "helpers/customDispatch";

const years = getYears({
  yearsBefore: 1,
  yearsAfter: 0,
  showDefaultValue: false,
  reverseOrder: true,
});

class VATPayment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      amount: "",
      country: null,
      currency: null,
      period: "1q",
      periodType: "quarter",
      year: years[0].value,
      anotherCurrency: "eur",
      checkAnotherCurrency: false,
      paymentStatus: "",

      actionDialogs: {
        pay: { open: false },
        notification: { open: false },
      },
    };
  }

  componentDidMount() {
    this.props
      .fetchRegNumbers(true)
      .then(() =>
        this.setState({
          country:
            this.props.regNumbers && this.props.regNumbers.length > 0
              ? this.props.regNumbers[0].country
              : undefined,
          currency:
            this.props.regNumbers && this.props.regNumbers.length > 0
              ? this.props.regNumbers[0].currency
              : undefined,
        })
      )
      .catch(_.noop);
    this.props.fetchCountries();
    this.props.fetchCurrency();
  }

  paymentСurrencies = [
    { value: "eur", label: this.props.t("vatPayment.eur") },
    { value: "usd", label: this.props.t("vatPayment.usd") },
    { value: "gbr", label: this.props.t("vatPayment.gbr") },
  ];

  periods = [
    { type: "quarter", value: "1q", label: this.props.t("vatPayment.q1") },
    { type: "quarter", value: "2q", label: this.props.t("vatPayment.q2") },
    { type: "quarter", value: "3q", label: this.props.t("vatPayment.q3") },
    { type: "quarter", value: "4q", label: this.props.t("vatPayment.q4") },
    { type: "month", value: "1", label: this.props.t("vatPayment.jan") },
    { type: "month", value: "2", label: this.props.t("vatPayment.feb") },
    { type: "month", value: "3", label: this.props.t("vatPayment.mar") },
    { type: "month", value: "4", label: this.props.t("vatPayment.apr") },
    { type: "month", value: "5", label: this.props.t("vatPayment.may") },
    { type: "month", value: "6", label: this.props.t("vatPayment.june") },
    { type: "month", value: "7", label: this.props.t("vatPayment.july") },
    { type: "month", value: "8", label: this.props.t("vatPayment.aug") },
    { type: "month", value: "9", label: this.props.t("vatPayment.sept") },
    { type: "month", value: "10", label: this.props.t("vatPayment.oct") },
    { type: "month", value: "11", label: this.props.t("vatPayment.nov") },
    { type: "month", value: "12", label: this.props.t("vatPayment.dec") },
  ];

  handleActionDialogsClose = (name) => {
    const { actionDialogs } = this.state;
    actionDialogs[name].open = false;
    actionDialogs.cellData = {};
    this.setState({ actionDialogs });
  };

  handleActionDialogsOpen = (name, data = {}) => {
    const { actionDialogs } = this.state;
    actionDialogs[name].open = true;
    actionDialogs[name].data = data;
    this.setState({ actionDialogs });
  };

  renderPayDialog = () => {
    const { t } = this.props;
    const {
      actionDialogs,
      amount,
      currency,
      country,
      period,
      periodType,
      year,
      anotherCurrency,
    } = this.state;
    const { data } = actionDialogs.pay;

    return (
      data && (
        <div className="pay -- 9999">
           <Modal
             title={t("vatPayment.orderVP")}
             visible={actionDialogs.pay.open}
             onClose={() => this.handleActionDialogsClose("pay")}
             content={
               <Payment
                 service={"VAT payment"}
                 sum={amount}
                 currency={currency}
                 comment={`${t(
                   "vatPayment.vatPayment"
                 )}: ${country}, ${Number.parseInt(
                   period,
                   16
                 )} ${periodType} ${year}, ${t(
                   "vatPayment.paymentCurrLC"
                 )}: ${anotherCurrency}`}
                 pplButtonId={""}
                 closeDialog={this.handleActionDialogsClose}
                 openNextDialog={this.handleActionDialogsOpen}
                 currentDialog='pay'
                 nextDialog='notification'
               />
             }
           />
        </div>
      )
    );
  };

  renderNotificationDialog() {
    const { actionDialogs } = this.state;
    const { t } = this.props;
    return (
      <Modal
        title={t("vatPayment.payment")}
        contentOnCenter
        visible={actionDialogs.notification.open}
        onClose={() => {
          this.handleActionDialogsClose("notification");
        }}
        description={
          <div style={{ maxWidth: "450px" }}>{t("vatReg.thankForReq")}</div>
        }
        footer={
          <Button
            primary
            onClick={() => {
              this.setState(
                {
                  amount: undefined,
                  country: this.props.regNumbers
                    ? this.props.regNumbers[0].country
                    : undefined,
                  currency: this.props.regNumbers
                    ? this.props.regNumbers[0].currency
                    : undefined,
                  period: "1q",
                  periodType: "quarter",
                  year: years[0].value,
                  anotherCurrency: this.paymentСurrencies[0].value,
                  checkAnotherCurrency: false,
                },
                () => this.handleActionDialogsClose("notification")
              );
            }}
          >
            {t("vatPayment.ok")}
          </Button>
        }
      ></Modal>
    );
  }

  render() {
    const { regNumbers, fetchingRegNumbers, t } = this.props;

    const regCountries = [];
    if (regNumbers) {
      regNumbers.forEach((element) => {
        regCountries.push(element.country);
      });
    }

    let optionsCountries = [];
    _.map(regCountries, (country) => {
      optionsCountries.push({
        key: `${country} + 'cntr'`,
        label: country,
        value: country,
      });
    });
    optionsCountries = _.uniqBy(optionsCountries, "key");

    return (
      <Page
        separator
        fullWidth
        title={
          <GoBackPageNavigation content={
            <Text variant='heading3xl' as='span'>
             {t("vatPayment.orderVP")}
            </Text>
          } />
        }
      >
        <PageHelmet title={"VAT Payment"} />
        <Layout>
          <Layout.AnnotatedSection
            title={t("vatPayment.vatPaymentForm")}
            description={
              <Text variant="bodyMd" color="critical">
                {t("vatPayment.fillParam")}
              </Text>
            }
          >
            <Layout.Section>
              {fetchingRegNumbers ? (
                <Card sectioned>
                  <Spinner />
                </Card>
              ) : (
                <Card sectioned>
                  {_.isEmpty(regCountries) && (
                    <div>
                      {t("vatPayment.addVatRegData")}{" "}
                      <a className='Polaris-Link' href='/tax-setup'>
                        {t("vatPayment.here")}
                      </a>
                    </div>
                  )}
                  {!_.isEmpty(regCountries) && !this.state.orderResult && (
                    <div>
                      <FormLayout>
                        <FormLayout.Group>
                          <Select
                            id='formCountry'
                            name='from_country'
                            label={`${t("vatPayment.country")}`}
                            options={optionsCountries}
                            onChange={(value) => {
                              this.setState({
                                country: value,
                                paymentStatus: undefined,
                              });
                              regNumbers.forEach((element) => {
                                if (element.country === value) {
                                  this.setState({ currency: element.currency });
                                }
                              });
                              optionsCountries.forEach((element) => {
                                if (element.name === value) {
                                  this.setState({ countryCode: element.code });
                                }
                              });
                            }}
                            value={this.state.country}
                          />
                          <Select
                            id='fromYear'
                            name='from_year'
                            label={`${t("vatPayment.year")}:`}
                            options={years}
                            onChange={(value) => {
                              this.setState({
                                year: value,
                                paymentStatus: undefined,
                              });
                            }}
                            value={this.state.year}
                          />
                        </FormLayout.Group>
                        <FormLayout.Group>
                          <TextField
                            label={t("vatPayment.vatSum")}
                            suffix={this.state.currency}
                            value={this.state.amount}
                            onChange={(value) =>
                              this.setState({
                                amount: value.replace(/[^\+\d]/g, ""),
                                paymentStatus: undefined,
                              })
                            }
                          />
                          <Select
                            id='fromPeriod'
                            name='from_period'
                            label={`${t("vatPayment.period")}:`}
                            options={this.periods}
                            onChange={(value) => {
                              let periodType = "";
                              if (value.includes("q")) {
                                periodType = "quarter";
                              } else {
                                periodType = "month";
                              }
                              this.setState({
                                period: value,
                                periodType,
                                paymentStatus: undefined,
                              });
                            }}
                            value={this.state.period}
                          />
                        </FormLayout.Group>
                        <FormLayout.Group>
                          <Checkbox
                            checked={this.state.checkAnotherCurrency}
                            label={t("vatPayment.wantReq")}
                            onChange={(checkAnotherCurrency) => {
                              this.setState({ checkAnotherCurrency });
                            }}
                          />
                          {this.state.checkAnotherCurrency && (
                            <Select
                              id='anotherCurrency'
                              name='another_currency'
                              value={this.state.anotherCurrency}
                              placeholder='Select'
                              label={`${t("vatPayment.paymentCurr")}:`}
                              options={this.paymentСurrencies}
                              onChange={(anotherCurrency) => {
                                this.setState({ anotherCurrency });
                              }}
                            />
                          )}
                        </FormLayout.Group>
                      </FormLayout>
                      <br />
                      <Button
                        variant={'primary'}
                        onClick={() => this.handleActionDialogsOpen("pay", {})}
                        disabled={
                          !(
                            this.state.country &&
                            this.state.currency &&
                            this.state.period &&
                            this.state.year
                          )
                        }
                      >
                        {t("vatPayment.order")}
                      </Button>
                    </div>
                  )}
                </Card>
              )}
            </Layout.Section>
          </Layout.AnnotatedSection>
        </Layout>
        {this.renderPayDialog()}
        {this.renderNotificationDialog()}
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  countries: state.other.countries,
  currency: state.other.currency,
  regNumbers: state.vatRegistration.regNumbers,
  fetchingRegNumbers: state.vatRegistration.fetching,
  defaultLanguage: state.user.defaultLanguage,
});

const mapDispatchToProps = (defaultDispatch) => {
  const dispatch = createCustomDispatch(defaultDispatch);

  return {
    fetchRegNumbers: (active) => dispatch(fetchRegNumbers(active)),
    fetchCountries: () => dispatch(fetchCountries()),
    fetchCurrency: () => dispatch(fetchCurrency()),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(VATPayment))
);
