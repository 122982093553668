import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import callApi from "helpers/callApi";
import { parseServerError } from "utils/errors";


export const fetchLookUp = createAsyncThunk(
  "look-up/fetchLookUp",
  async (params, { rejectWithValue }) => {
    try {
      const response = await callApi("/peppol/lookup", "POST", params);
      if (!response.ok) {
        throw new Error("Server error");
      }
      const data = await response.json();
      console.log('data 111', data)
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  look_up: [],
};

const lookUpSlice = createSlice({
  name: "look-up",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(fetchLookUp.pending, (state) => {
        state.fetchingLookUp = true;
      })
      .addCase(fetchLookUp.fulfilled, (state, action) => {
        state.fetchingLookUp = false;
        state.fetchingLookUpError = null;
        state.look_up = action.payload.companies;
      })
      .addCase(fetchLookUp.rejected, (state, action) => {
        state.fetchingLookUp = false;
        state.fetchingLookUpError = parseServerError(action.error);
      })
});

export default lookUpSlice.reducer;
