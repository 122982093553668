import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./features";
import { unauthorizedMiddleware } from "./middleware";

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([unauthorizedMiddleware]),
});

export default store;

