import React, { useEffect, useRef, useCallback, useState, useMemo } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import MainNavigationForEinvoice from './components/MainNavigation/MainNavigationForEinvoice'
import { fetchCurrentPlan } from "./redux/features/subscription/subscriptionSlice";
import RoutesComponent from "./routes";
import withRouter from "helpers/withRouter";
import MainNavigation from "./components/MainNavigation";
import { Frame, TopBar } from "@shopify/polaris";
import Logo from "./img/logo.svg";

import "react-datepicker/dist/react-datepicker.css";
import RenderTopMenus from "./components/TopMenu/RenderTopMenus";
import _ from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchUser } from "./redux/features/user/userSlice";
import { createCustomDispatch } from "helpers/customDispatch";
import RoutesForEinvoice from './routes/routesForEinvoice'

const App = (props) => {
	const {loggingIn, isLogged, user, i18n, defaultLanguage} = props;
	const {fetchUser, fetchCurrentPlan} = props;
	const navigate = useNavigate();
	const location = useLocation();
	
	console.log('location', location)
	
	const skipToContentRef = useRef(null);
	const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
	const [userMenuActive, setUserMenuActive] = useState(false);
	
	const isUnsubscribePage = location.pathname.includes("/unsubscribe");
	
	const currentSubdomain = useMemo(() => {
		const host = window.location.hostname;
    console.log('host', host)
		if (host.includes('invoice')) return 'invoice';
		// if (host.includes('localhost')) return 'invoice';
		if (host.includes('localhost')) return 'main';
		return 'main';
	}, []);
	console.log('currentSubdomain', currentSubdomain)
	useEffect(() => {
		fetchUser().catch(_.noop);
		if (isLogged) {
			fetchCurrentPlan();
		}
	}, [isLogged, fetchUser, fetchCurrentPlan, navigate]);
	
	useEffect(() => {
		const frame = document.querySelector(".Polaris-Frame");
		if (!isUnsubscribePage && isLogged) {
			frame.style.backgroundColor = "inherit";
		} else {
			frame.style.backgroundColor = "white"; // background color on login page = white
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLogged, isUnsubscribePage]);
	
	useEffect(() => {
		if (defaultLanguage) {
			i18n.changeLanguage(defaultLanguage?.lang || "en");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user, defaultLanguage]);
	
	const toggleUserMenuActive = useCallback(
		() => setUserMenuActive((userMenuActive) => !userMenuActive),
		[]
	);
	
	const toggleMobileNavigationActive = useCallback(
		() =>
			setMobileNavigationActive(
				(mobileNavigationActive) => !mobileNavigationActive
			),
		[]
	);
	
	const userMenuMarkup = (
		<TopBar.UserMenu
			actions={[{items: [{content: "Log out"}]}]}
			open={userMenuActive}
			onToggle={toggleUserMenuActive}
		/>
	);
	
	const topBarMarkup = (
		<TopBar
			showNavigationToggle
			userMenu={userMenuMarkup}
			onNavigationToggle={toggleMobileNavigationActive}
		/>
	);
	
	return (
		<div className='App'>
      {
	      currentSubdomain === 'main'
		      ?
		      <Frame
			      logo={{
				      width: 100,
				      topBarSource: Logo,
				      contextualSaveBarSource: Logo,
				      url: "/",
				      accessibilityLabel: "Lovat",
			      }}
			      showMobileNavigation={mobileNavigationActive}
			      onNavigationDismiss={toggleMobileNavigationActive}
			      skipToContentTarget={skipToContentRef.current}
			      topBar={isLogged ? topBarMarkup : null}
			      navigation={
				      !isUnsubscribePage && isLogged && <MainNavigation user={user} />
			      }
		      >
            {!isUnsubscribePage && isLogged && <RenderTopMenus />}
                <RoutesComponent
                  isLogged={isLogged}
                  isLoggingIn={loggingIn}
                />
          </Frame >
		      :
		      <Frame
			      logo={{
				      width: 100,
				      topBarSource: Logo,
				      contextualSaveBarSource: Logo,
				      url: "/",
				      accessibilityLabel: "Lovat",
			      }}
			      showMobileNavigation={mobileNavigationActive}
			      onNavigationDismiss={toggleMobileNavigationActive}
			      skipToContentTarget={skipToContentRef.current}
			      topBar={isLogged ? topBarMarkup : null}
			      navigation={
				      !isUnsubscribePage && isLogged && <MainNavigationForEinvoice user={user} />
			      }
		      >
            {!isUnsubscribePage && isLogged && <RenderTopMenus />}
                <RoutesForEinvoice
                  isLogged={isLogged}
                  isLoggingIn={loggingIn}
                />
          </Frame >
	      
      }
      
    </div >
	);
};

const mapStateToProps = (state) => ({
	user: state.user.user,
	currentPlan: state.subscription.currentPlan,
	loggingIn: state.auth.loggingIn,
	isLogged: state.auth.isLogged,
	defaultLanguage: state.user.defaultLanguage,
});

const mapDispatchToProps = (defaultDispatch) => {
	const dispatch = createCustomDispatch(defaultDispatch);
	
	return {
		fetchUser: () => dispatch(fetchUser()),
		fetchCurrentPlan: () => dispatch(fetchCurrentPlan()),
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation()(withRouter(App)));
