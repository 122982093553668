import { Banner, Box, Button, ButtonGroup, Inline, Text } from '@shopify/polaris'
import { t } from 'i18next'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Datepicker from 'components/Datepicker'
import Modal from 'components/Modal'
import useAppDispatch from 'hooks/useAppDispatch'
import { formatDateForServer } from 'utils/dates'
import { convertInvoicesToTransaction } from '../../../redux/features/documents/documentsSlice'

const ConvertToTransactions = (props) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const {
		fetchingConvertInvoicesToTransactionLoading: loading,
		fetchingConvertInvoicesToTransactionLoadingError: error,
	} = useSelector((state) => state.documents);
	const { onClose, afterUpload, isOpen } = props;
	
	const [fromDate, setFromDate] = useState("");
	const [toDate, setToDate] = useState("");
	
	const onUploadClick = () => {
		const params = {
			from_date: formatDateForServer(fromDate),
			to_date: formatDateForServer(toDate),
		};
		
		dispatch(convertInvoicesToTransaction(params)).then(() => {
			afterUpload();
			onClose();
		});
	};
	
	return (
		<Modal
			title='Convert Invoices To Transaction'
			description='You are about to upload the invoices from Convert Invoices To Transaction'
			visible={isOpen}
			onClose={onClose}
			content={
				<>
          <br />
          <Box
	          display="flex"
          >
            <Datepicker
	            label={t("subscription.from")}
	            allowRange={false}
	            value={fromDate}
	            onChange={(date) => {
		            setFromDate(date);
	            }}
	            autoComplete="off"
            />
            <Datepicker
	            label={t("subscription.till")}
	            allowRange={false}
	            value={toDate}
	            onChange={(value) => setToDate(value)}
	            autoComplete="off"
            />
          </Box>
        </>
			}
			footer={
				<>
          {error?.message === "no_integration" && (
	          <>
              <Banner tone='critical'>
                <Box display="flex" alignItems="space-between">
                  <Text>You have no integration with Convert Invoices To Transaction!</Text>
                  <Button variant={'plain'} onClick={() => navigate("/stores")}>
                    Go to stores
                  </Button>
                </Box>
              </Banner>
              <br />
            </>
          )}
					<ButtonGroup fullWidth>
            <Button size='large' onClick={onClose}>
              Cancel
            </Button>
            <Button
	            size='large'
	            onClick={onUploadClick}
	            loading={loading}
	            primary
	            disabled={!(fromDate && toDate)}
            >
              Convert
            </Button>
          </ButtonGroup>
        </>
			}
		/>
	);
};
export default ConvertToTransactions