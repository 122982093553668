import React, { Component } from "react";
import _ from "lodash";
import classNames from "classnames";
import moment from "moment";
import {
  Page,
  Layout,
  Card,
  Button,
  TextField,
  Tag,
  Badge,
  Avatar,
  Text,
  Grid,
  Box,
} from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import withRouter from "helpers/withRouter";
import BannerCustom from '../../components/Banners/BannerCustom'
import {
  downloadMessageFile,
  fetchDialog,
  fetchNewMessagesCount,
  sendMessengerMessage,
  setUnreadToRead,
} from "../../redux/features/messenger/messengerSlice";
import ClipIMG from "img/clip.svg";
import "./index.scss";
import PageHelmet from "components/PageHelmet";
import { createCustomDispatch } from "helpers/customDispatch";
import BackArrowWithComponent from "components/BackArrow";

class Dialog extends Component {
  constructor(props) {
    super(props);
    props
      .setUnreadToRead(props.params.dialogId)
      .then(() => props.fetchNewMessagesCount())
      .catch(_.noop);

    this.state = {
      description: "",
      errorDescription: false,
      dialog: null,

      fileNameList: {
        proof_files: [],
      },
    };

    this.createMessage = this.createMessage.bind(this);
    this.dialogWindowRef = React.createRef();
  }

  componentDidMount() {
    this.props
      .fetchDialog()
      .then((result) => {
        this.setState({ dialog: result });

        this.scrollToBottom();
      })
      .catch(_.noop);
  }

  getUserDataForMessage = (user, message) => {
    const data = {
      avatar: "M",
      userName: "Manager LOVAT",
      isCurrentUserMessage: false,
    };

    if (!user.is_manager && user.id === message.sender_id) {
      data.avatar = user.first_name.substr(0, 1) + user.last_name.substr(0, 1);
      data.userName = `${user.first_name} ${user.last_name}`;
    }
    if (user.is_manager && !message.is_manager) {
      data.avatar =
        message.user_name.substr(0, 1) +
        message.user_name.match(/[^ ]+ (.+)/)[1][0];
      data.userName = message.user_name;
    }

    if (message.sender_id === user.id) {
      data.isCurrentUserMessage = true;
      data.userName = "You";
    }

    return data;
  };

  docsFiles = {
    proof_files: [],
  };

  attachFiles = (docsFiles, text) => {
    // attach documents to form
    const formDataFiles = new FormData();
    for (let i = 0; i < docsFiles.proof_files.length; i++) {
      formDataFiles.append(
        "file_" + i,
        docsFiles.proof_files[i],
        docsFiles.proof_files[i].name
      );
    }

    formDataFiles.append("text", text);

    return formDataFiles;
  };

  handleFileSelect = (docsType) => {
    const { fileNameList } = this.state;
    if (this.fileUploadInput) {
      this.fileUploadInput.click();
      this.fileUploadInput.onchange = () => {
        const files = this.fileUploadInput.files;
        for (let i = 0; i < files.length; i++) {
          this.doDeleteFile(docsType, files[i].name); // delete the same file from list, comparing inside 'doDeleteFile' function
          this.docsFiles[docsType][this.docsFiles[docsType].length] = files[i]; // add file
          fileNameList[docsType][fileNameList[docsType].length] = files[i].name; // add filename
        }
        this.setState({ fileNameList });
      };
    }
  };

  doDeleteFile = (fileListName, fileName) => {
    const { fileNameList } = this.state;
    let fileToDelete = undefined;
    _.map(this.docsFiles[fileListName], (file, index) => {
      if (file.name === fileName) {
        fileToDelete = index;
      } // get index
    });

    if (fileToDelete !== undefined) {
      this.docsFiles[fileListName].splice(fileToDelete, 1); // delete file name the list
      fileNameList[fileListName].splice(fileToDelete, 1); // delete file name from the list
      this.setState({ fileNameList });
    }
  };

  downloadFile(data) {
    this.props.downloadMessageFile(data).then((toDownload) => {
      const from = data.indexOf("/") + 1;
      const to = data.lastIndexOf(".");
      const fileName = data.substring(from, to);
      const a = document.createElement("a");
      a.setAttribute("href", toDownload.link);
      a.setAttribute("download", fileName); // data - filename
      a.style.display = "none";

      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(toDownload.link);
      document.body.removeChild(a);
    });
  }

  createMessage(event) {
    const { t } = this.props;
    event.preventDefault();
    const { description } = this.state;
    if (!description) {
      this.setState({
        errorDescription: t("dialog.addCommMess"),
      });
      return;
    }

    if (description) {
      const { dialog } = this.state;
      const formData = {
        dialogId: +dialog.id,
        text: description,
      };
      const files = this.attachFiles(this.docsFiles, description);

      this.props
        .sendMessengerMessage({ ...formData, data: files })
        .then(() => {
          const { fileNameList } = this.state;
          fileNameList.proof_files = [];
          this.docsFiles.proof_files = [];
          this.setState({
            description: "",
            sendingError: null,
            fileNameList,
          });

          this.props.fetchDialog().then((result) => {
            this.setState({ dialog: result }, () => {
              this.scrollToBottom();
            });
          });
        })
        .catch((resp) => {
          this.setState({ sendingError: resp });
        });
    }
  }

  scrollToBottom = () => {
    if (this.dialogWindowRef.current) {
      const { scrollHeight, clientHeight } = this.dialogWindowRef.current;
      this.dialogWindowRef.current.scrollTop = scrollHeight - clientHeight;
    }
  };

  renderCaseNumber = () => {
    const { dialog } = this.state;

    return `Request ${
      (dialog && dialog?.id.toString().padStart(10, "0")) || ""
    }`;
  };

  renderStatusBadge = (value) => {
    const { t } = this.props;

    let status = "";
    switch (value.toLowerCase()) {
      case "info":
        status = <Badge tone={"info"}>{t("dialog.info")}</Badge>;
        break;
      case "action required":
        status = <Badge tone={"warning"}>{t("dialog.actionR")}</Badge>;
        break;
      case "pending lovat action":
        status = <Badge tone={"success"}>{t("dialog.pendingLovat")}</Badge>;
        break;
      default:
        status = <Badge>{t("dialog.answered")}</Badge>;
    }
    return status;
  };

  renderMultipleFileSelect = (docType) => {
    const { fileNameList } = this.state;
    const { t } = this.props;

    return (
      <>
        <Box display="flex" alignItems="center" gap="4">
          <Button
            onClick={() => this.handleFileSelect(docType)}
            disabled={this.props.isUploading}
          >
            Add file
            <input
              type='file'
              multiple
              style={{ display: "none" }}
              ref={(input) => (this.fileUploadInput = input)}
            />
          </Button>
          <Text color='subdued'>{t("dialog.chooseFiles")}</Text>
        </Box>
        <div>
          {_.map(fileNameList["proof_files"], (fileName, index) => {
            return (
              <div
                style={{
                  display: "inline-block",
                  margin: "5px",
                }}
                key={index}
              >
                <Tag
                  key={index}
                  onRemove={() => {
                    this.doDeleteFile("proof_files", fileName);
                  }}
                >
                  {fileName}
                </Tag>
              </div>
            );
          })}
        </div>
      </>
    );
  };

  onNavigateBack = () => {
    this.props.navigate("/help", { state: { defaultTab: 1 } });
  };

  render() {
    const { dialog } = this.state;

    const messages = dialog ? dialog.messages : null;

    return (
      <Page
        title={
          <BackArrowWithComponent
            onClick={this.onNavigateBack}
            withComponent={
              <Text variant='heading3xl' as='span'>
                {this.renderCaseNumber()}
              </Text>
            }
          />
        }
        fullWidth
      >
        <PageHelmet title={"Support chat"} />

        {dialog && (
          <Layout>
            <Layout.Section>
              <Grid>
                <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 6, lg: 8, xl: 8 }}>
                  <Card>
                    <div>
                      <Text variant='headingLg'>Support chat</Text>
                    </div>
                    <div>
                      <div
                        className='dialog__messages'
                        ref={this.dialogWindowRef}
                      >
                        {messages &&
                          messages.map((message) => {
                            const data = this.getUserDataForMessage(
                              this.props.user,
                              message
                            );
                            return (
                              <div
                                key={message.id}
                                className={classNames("dialog__message", {
                                  myMessage: data.isCurrentUserMessage,
                                })}
                              >
                                {!data.isCurrentUserMessage && (
                                  <Avatar initials={data.avatar} />
                                )}

                                <div>
                                  {/*<Inline*/}
                                  {/*  alignY='center'*/}
                                  {/*  align={*/}
                                  {/*    data.isCurrentUserMessage*/}
                                  {/*      ? "space-between"*/}
                                  {/*      : "start"*/}
                                  {/*  }*/}
                                  {/*>*/}
                                  <Box display="flex" alignItems={data.isCurrentUserMessage
                                       ? "space-between"
                                        : "start"}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                      <Text>
                                        {
                                          data.userName /*this.props.user.id === message.sender_id ? `${user.first_name} ${user.last_name}` : 'Manager LOVAT'*/
                                        }
                                      </Text>
                                    </div>

                                    <div className='dialog__message-date'>
                                      <Text color='subdued' variant='bodySm'>
                                        {moment(message.created).format("LLL")}
                                      </Text>
                                    </div>
                                  </Box>
                                  <p className='dialog__message-text'>
                                    {message.text}
                                  </p>
                                  {message.files &&
                                    message.files.map((f, index) => (
                                      <div
                                        key={`${index}-file`}
                                        className='dialog__message-file'
                                      >
                                        <img
                                          className='Button__Prefix'
                                          src={ClipIMG}
                                          alt='icon save'
                                        />
                                        <a
                                          className='Polaris-Link'
                                          onClick={() => this.downloadFile(f)}
                                        >
                                          {f.slice(5)}
                                        </a>
                                      </div>
                                    ))}
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                    <div>
                      {this.state.sendingError && (
                        // <Banner title='Error' tone='critical' />
                        <BannerCustom
                        status={'critical'}
                      message={'Error'}
                    />
                      )}
                      <form onSubmit={this.createMessage}>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'stretch'}}>
                          <TextField
                            id='desc'
                            name='description'
                            placeholder='Write a message'
                            multiline={6}
                            value={this.state.description}
                            error={this.state.errorDescription}
                            onChange={(description) =>
                              this.setState({
                                description,
                                errorDescription: false,
                              })
                            }
                          />
                        </div>
                        <br />
                        <Box
                          display="flex"
                          flexDirection="column"
                        >
                          {this.renderMultipleFileSelect("proof_files")}
                          <Button variant={'primary'} submit size='large'>
                            Send
                          </Button>
                        </Box>
                      </form>
                    </div>
                  </Card>
                </Grid.Cell>
                <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 6, lg: 4, xl: 4 }}>
                  <Card sectioned>
                    <Box
                      display="flex"
                      flexDirection="column"
                      gap="6"
                    >
                      <Box
                        display="flex"
                        flexDirection="column"
                        gap="2"
                      >
                        <Text fontWeight='medium'>Case number</Text>
                        <Text color='subdued'>{this.renderCaseNumber()}</Text>
                      </Box>
                        <Box
                          display="flex"
                          flexDirection="column"
                          gap="2"
                        >
                        <Text fontWeight='medium'>Status</Text>
                        {this.renderStatusBadge(dialog.dialog_status)}
                      </Box>
                     <Box
                       display="flex"
                       flexDirection="column"
                       gap="2"
                     >
                        <Text fontWeight='medium'>Date update</Text>
                        <Text color='subdued'>
                          {moment(dialog.modified).format("DD-MM-YYYY hh:mm")}
                        </Text>
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="column"
                        gap="2"
                      >
                        <Text fontWeight='medium'>Case group</Text>
                        <Text color='subdued'>
                          <span style={{ textTransform: "capitalize" }}>
                            {dialog.case_group}
                          </span>
                        </Text>
                      </Box>
                    </Box>
                  </Card>
                </Grid.Cell>
              </Grid>
            </Layout.Section>
          </Layout>
        )}
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  dialog: state.messenger.dialog,
  user: state.user.user,
  defaultLanguage: state.user.defaultLanguage,
});

const mapDispatchToProps = (defaultDispatch) => {
  const dispatch = createCustomDispatch(defaultDispatch);

  return {
    sendMessengerMessage: (params) => dispatch(sendMessengerMessage(params)),
    downloadMessageFile: (name) => dispatch(downloadMessageFile(name)),
    setUnreadToRead: (id) => dispatch(setUnreadToRead(id)),
    fetchNewMessagesCount: () => dispatch(fetchNewMessagesCount()),
    fetchDialog: (params) => dispatch(fetchDialog(params)),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(Dialog))
);
