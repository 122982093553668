import React from 'react';
import {
  Layout,
  Card,
  Button,
  TextField,
} from '@shopify/polaris';

const StoreUrlBlock = (props) => (
  <Layout.AnnotatedSection
    title={props.title}
    description={props.description}>
    <Layout.Section>
      <Card sectioned>
        <TextField
          label={props.label}
          value={props.value}
          connectedLeft={props.withHttps && <div style={{width: '80px'}}><TextField value="https://" disabled /></div>}
          connectedRight={props.connectedRight}
          onChange={props.onChangeTextField} />
          <br />
          <Button
            variant={'primary'}
            onClick={props.onClickIntegration}
            disabled={props.buttonDisabled}
          >{props.buttonName}</Button>
       </Card>
    </Layout.Section>
  </Layout.AnnotatedSection>
);

export default StoreUrlBlock;
