import { Card, DataTable, Text } from "@shopify/polaris";
import React from "react";
import formatRow from "utils/formatRow";
import { t } from "i18next";

const PackagingPreviewSimplified = ({ data }) => {
  const { number_of_csu, product_description } = data;
  const columns = [
    {
      property: "product_description",
      header: {
        label: t("eprReporting.productDescription"),
      },
    },
    {
      property: "number_of_csu",
      header: {
        label: t("eprReporting.numberOfScu"),
      },
    },
  ];

  const sortedRows = formatRow(
    [{ number_of_csu, product_description }],
    columns
  );

  return (
    <Card>
      <DataTable
        columnContentTypes={["text", "numeric"]}
        headings={columns.map(({ header }) => (
          <Text fontWeight='semibold' variant={'bodyMd'}>{header.label}</Text>
        ))}
        rows={sortedRows}
        hideScrollIndicator
      />
    </Card>
  );
};

export default PackagingPreviewSimplified;
