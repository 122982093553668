import React from "react";

const StoresIcon = ({ selected }) => {
  if (selected) {
    return (
      <svg
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M5.1339 1.6665H14.8648C15.3041 1.66649 15.6831 1.66648 15.9952 1.69198C16.3247 1.7189 16.6523 1.77833 16.9677 1.93899C17.4381 2.17867 17.8205 2.56113 18.0602 3.03153C18.2209 3.34685 18.2803 3.67453 18.3072 4.00398C18.3327 4.31612 18.3327 4.69509 18.3327 5.13437L18.3327 5.83317C18.3327 6.60509 17.5019 7.49984 16.1105 7.49984C14.719 7.49984 13.8882 6.60509 13.8882 5.83317C13.8882 5.37293 13.5151 4.99984 13.0549 4.99984C12.5947 4.99984 12.2216 5.37293 12.2216 5.83317C12.2216 6.60509 11.3908 7.49984 9.99935 7.49984C8.6079 7.49984 7.77713 6.60509 7.77713 5.83317C7.77713 5.37293 7.40403 4.99984 6.94379 4.99984C6.48356 4.99984 6.11046 5.37293 6.11046 5.83317C6.11046 6.60509 5.27969 7.49984 3.88824 7.49984C2.49679 7.49984 1.66602 6.60509 1.66602 5.83317V5.13435C1.666 4.69509 1.66599 4.31611 1.69149 4.00398C1.71841 3.67453 1.77784 3.34685 1.9385 3.03153C2.17819 2.56113 2.56064 2.17867 3.03104 1.93899C3.34636 1.77833 3.67404 1.7189 4.00349 1.69198C4.31562 1.66648 4.69464 1.66649 5.1339 1.6665Z'
          fill='rgba(38, 127, 215, 1)'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M16.1105 9.16651C16.9025 9.16651 17.682 8.95228 18.3327 8.56827V14.8653C18.3327 15.3046 18.3327 15.6836 18.3072 15.9957C18.2803 16.3252 18.2209 16.6528 18.0602 16.9681C17.8205 17.4386 17.4381 17.821 16.9677 18.0607C16.6523 18.2213 16.3247 18.2808 15.9952 18.3077C15.6831 18.3332 15.3041 18.3332 14.8648 18.3332H5.13388C4.6946 18.3332 4.31563 18.3332 4.00349 18.3077C3.67404 18.2808 3.34636 18.2213 3.03104 18.0607C2.56064 17.821 2.17819 17.4386 1.9385 16.9681C1.77784 16.6528 1.71841 16.3252 1.69149 15.9957C1.66599 15.6836 1.666 15.3046 1.66602 14.8653V8.56827C2.31675 8.95228 3.09625 9.16651 3.88824 9.16651C5.05941 9.16651 6.20326 8.69805 6.94379 7.90484C7.68433 8.69805 8.82818 9.16651 9.99935 9.16651C11.1705 9.16651 12.3144 8.69805 13.0549 7.90484C13.7954 8.69805 14.9393 9.16651 16.1105 9.16651ZM8.83268 11.6665H11.166C11.6327 11.6665 11.8661 11.6665 12.0443 11.7573C12.2011 11.8372 12.3286 11.9647 12.4085 12.1215C12.4993 12.2998 12.4993 12.5331 12.4993 12.9998V16.6665H7.49935V12.9998C7.49935 12.5331 7.49935 12.2998 7.59018 12.1215C7.67007 11.9647 7.79756 11.8372 7.95436 11.7573C8.13262 11.6665 8.36597 11.6665 8.83268 11.6665Z'
          fill='rgba(38, 127, 215, 1)'
        />
      </svg>
    );
  }
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        opacity='0.4'
        d='M12.5 17.5003V13.267C12.5 12.7069 12.5 12.4269 12.391 12.213C12.2951 12.0248 12.1422 11.8719 11.954 11.776C11.7401 11.667 11.4601 11.667 10.9 11.667H9.1C8.53995 11.667 8.25992 11.667 8.04601 11.776C7.85785 11.8719 7.70487 12.0248 7.60899 12.213C7.5 12.4269 7.5 12.7069 7.5 13.267V17.5003'
        stroke='#2E2E2E'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M2.5 5.83333C2.5 7.21405 3.61929 8.33333 5 8.33333C6.38071 8.33333 7.5 7.21405 7.5 5.83333C7.5 7.21405 8.61929 8.33333 10 8.33333C11.3807 8.33333 12.5 7.21405 12.5 5.83333C12.5 7.21405 13.6193 8.33333 15 8.33333C16.3807 8.33333 17.5 7.21405 17.5 5.83333M5.16667 17.5H14.8333C15.7668 17.5 16.2335 17.5 16.59 17.3183C16.9036 17.1586 17.1586 16.9036 17.3183 16.59C17.5 16.2335 17.5 15.7668 17.5 14.8333V5.16667C17.5 4.23325 17.5 3.76654 17.3183 3.41002C17.1586 3.09641 16.9036 2.84144 16.59 2.68166C16.2335 2.5 15.7668 2.5 14.8333 2.5H5.16667C4.23325 2.5 3.76654 2.5 3.41002 2.68166C3.09641 2.84144 2.84144 3.09641 2.68166 3.41002C2.5 3.76654 2.5 4.23325 2.5 5.16667V14.8333C2.5 15.7668 2.5 16.2335 2.68166 16.59C2.84144 16.9036 3.09641 17.1586 3.41002 17.3183C3.76654 17.5 4.23325 17.5 5.16667 17.5Z'
        stroke='#707070'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default StoresIcon;
