import { Button, Text } from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import React from "react";

import { ReactComponent as NoDataImage } from "img/noData.svg";
import { ReactComponent as NoDataPlusBtn } from "img/noDataPlus.svg";

import "./noDataMessage.scss";

const NoDataMessage = (props) => {
  const { t } = useTranslation();
  const {
    title = t("noDataMessage.nothingToShowHere"),
    description = "",
    buttonText = "",
    onClick = () => {},
    style,
    Image = NoDataImage,
  } = props;

  return (
    <div className='noDataMessageWrap' style={style}>
      <div style={{ display: 'flex', alignItems: 'center', position: 'relative', zIndex: 1 }}>
        <Image />
        <div style={{ display: 'flex', flexDirection: 'column', gap: 'var(--spacing-extra-tight)'}}>
          {
            <Text color='subdued' fontWeight='medium'>
              {title}
            </Text>
          }
          {description && <Text color='subdued'>{description}</Text>}
          {buttonText && (
            <Button
              variant={'plain'}
              icon={<NoDataPlusBtn style={{ marginTop: "4px" }} />}
              onClick={onClick}
            >
              {buttonText}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

NoDataMessage.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  buttonText: PropTypes.string,
  onClick: PropTypes.func,
  Image: PropTypes.elementType,
  style: PropTypes.object,
};

export default NoDataMessage;
