import { Box, Button, Tooltip } from '@shopify/polaris'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import continueIMG from "img/continue.svg"
import deleteIMG from "img/delete.svg"
import downloadIMG from "img/download.svg"
import useActionDialogs from '../../../../hooks/useActionDIalogs'

const actionDialogsKeys = {
	deleteVatInvoice: "deleteVatInvoice",
	deleteVatInvoiceBulk: "deleteVatInvoiceBulk",
	uploadFromXero: "uploadFromXero",
	sendEInvoices: "sendEInvoices",
	scanFiles: "scanFiles",
	convertToTransactions: 'convertToTransactions'
}

const RenderActions = () => {
	const navigate = useNavigate()
	const { t } = useTranslation()
	const { handleActionDialogsOpen } = useActionDialogs(Object.values(actionDialogsKeys))
	
	const downloadFile = (link) => {
		if (!link) return;
		const fileName = link.split("/").pop();
		const absoluteLink = new URL(link, window.location.origin).href;
		const a = document.createElement("a");
		
		a.setAttribute("href", absoluteLink);
		a.setAttribute("download", fileName);
		a.style.display = "none";
		
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	};
	
	// Возвращаем **функцию**, которую можно передавать как `renderActions`
	return (rowData) => (
		<Box display='flex' alignItems='end' justifyContent='center' gap='4'>
			<Tooltip content={t("vatReturns.continue")}>
				<Button
					variant={'plain'}
					onClick={() => navigate(`/documents/exemption/add`, { state: rowData })}
				>
					<img src={continueIMG} alt='continue' />
				</Button>
			</Tooltip>
			
			{/*{rowData?.status === "draft" && (*/}
			{/*	<Tooltip content={t("vatReturns.delete")}>*/}
			{/*		<Button*/}
			{/*			variant={'plain'}*/}
			{/*			onClick={() => handleActionDialogsOpen(actionDialogsKeys.deleteVatInvoice, rowData)}*/}
			{/*		>*/}
			{/*			<img src={deleteIMG} alt='delete' />*/}
			{/*		</Button>*/}
			{/*	</Tooltip>*/}
			{/*)}*/}
			
			<Tooltip content={t("vatReturns.downloadFile")}>
				<Button variant={'plain'} onClick={() => downloadFile(rowData?.file)}>
					<img src={downloadIMG} alt='download' />
				</Button>
			</Tooltip>
		</Box>
	);
};

export default RenderActions;
