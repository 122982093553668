import React, { useEffect } from "react";
import {
  AlphaStack, Box,
  Button,
  ButtonGroup,
  Card,
  FormLayout,
  Select,
} from "@shopify/polaris";
import { useSelector } from "react-redux";
import useActionDialogs from "hooks/useActionDIalogs";
import { getCustomersList } from '../../../../redux/features/documents/documentsSlice'
import { CustomerModal } from "../../modals";
import useAppDispatch from "hooks/useAppDispatch";
import InlineText from "../../common/InlineText";

const ActionDialogs = Object.freeze({
  customer: "customer",
});

const Customer = ({ data, setData }) => {
  const dispatch = useAppDispatch();
  const { customerId } = data;
  const { customers } = useSelector((state) => state.documents);
  const currentCustomer =
    customers.find(({ id }) => {
      return String(customerId) === String(id)
    }) ?? {};
  const { actionDialogs, handleActionDialogsOpen, handleActionDialogsClose } =
    useActionDialogs([ActionDialogs.customer]);

  useEffect(() => {
    dispatch(getCustomersList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAddNewCustomer = () => {
    handleActionDialogsOpen(ActionDialogs.customer, { type: "create" });
  };

  const onEditCustomer = () => {
    handleActionDialogsOpen(ActionDialogs.customer, {
      type: "update",
      ...currentCustomer,
    });
  };

  const onCustomerSelect = (id) => {
    setData((prev) => ({
      ...prev,
      customerId: Number(id), // Приводим id к числу
    }));
  };

  const customersOptions = [
    "",
    ...customers.map(({ id, name }) => ({
      value: String(id),
      label: name,
    })),
  ];

  return (
    <div style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}>
      <FormLayout>
        <FormLayout.Group>
          <Select
            label='Select or add customer'
            options={customersOptions}
            onChange={onCustomerSelect}
            value={String(customerId) || ""}
          />

          <div style={{ marginTop: "1.5rem" }}>
            <ButtonGroup>
              <Button size={'micro'} variant={'primary'} onClick={onAddNewCustomer}>
                Add new customer
              </Button>
            </ButtonGroup>
          </div>
        </FormLayout.Group>
        {customerId && (
          <FormLayout.Group>
            <Card sectioned>
              <Box
                display="flex"
                flexDirection="column"
                gap="2"
              >
                <InlineText label='Name' text={currentCustomer?.name} />
                <InlineText
                  label='VAT number'
                  text={currentCustomer?.vat_number}
                />
                <InlineText label='Email' text={currentCustomer?.email} />
                <InlineText label='Country' text={currentCustomer?.country?.name} />
                <InlineText label='Address' text={currentCustomer?.address} />
                <Button onClick={onEditCustomer} variant={'plain'}>
                  Edit customer
                </Button>
              </Box>
            </Card>
            <br />
          </FormLayout.Group>
        )}
      </FormLayout>

      <CustomerModal
        onClose={() => handleActionDialogsClose(ActionDialogs.customer)}
        isOpen={actionDialogs.customer.open}
        data={actionDialogs.customer.cellData}
      />
    </div>
  );
};

export default Customer;
