import React, { useEffect } from "react";
import Modal from "../Modal";
import { Avatar, Badge, Button, Spinner, Text } from "@shopify/polaris";
import { useSelector } from "react-redux";
import { getCompanyInitials } from "./utils";
import classNames from "classnames";
import {
  changeUserCompany,
  getUserCompanies,
} from "../../redux/features/user/userSlice";
import useAppDispatch from "hooks/useAppDispatch";

import ChangeCompanyArrowIMG from "img/change-company-arrow.svg";
import PlusCircleIMG from "img/plus-circle.svg";

import "./index.scss";

const ChangeCompanyModal = ({ visible, onClose, onAddCompanyClick }) => {
  const dispatch = useAppDispatch();
  const { userCompanies, selectedCompanyId, fetchingUserCompanies } =
    useSelector((state) => state.user);

  useEffect(() => {
    if (visible) {
      dispatch(getUserCompanies());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const handleChangeCompanyClick = (id) => {
    dispatch(changeUserCompany(id)).then(() => window.location.reload());
  };

  return (
    <Modal
      title={"Change Company Account"}
      visible={visible}
      onClose={onClose}
      description={
        <div style={{ maxWidth: "500px" }}>
          Easily manage tax reporting for all your registered companies
          displayed below. Please choose a company to proceed.
        </div>
      }
      content={
        <>
          <br />

          <div className='changeCompany'>
            {fetchingUserCompanies ? (
              <div style={{ padding: "1rem" }}>
                <Spinner />
              </div>
            ) : null}
            {userCompanies.map((company) => {
              const { id, name } = company;
              const isActiveCompany = selectedCompanyId === id;

              const onClick = !isActiveCompany
                ? () => handleChangeCompanyClick(id)
                : undefined;

              return (
                <div
                  className={classNames("changeCompany-option", {
                    active: isActiveCompany,
                  })}
                  onClick={onClick}
                >
                  <div className='changeCompany-option-body'>
                    <Avatar initials={getCompanyInitials(name)} size='small' />
                    <div className='changeCompany-option-body-info'>
                      <Text variant='bodyMd' fontWeight='medium'>
                        {name}
                      </Text>
                      {!isActiveCompany ? (
                        <img
                          src={ChangeCompanyArrowIMG}
                          alt='change company arrow'
                        />
                      ) : (
                        <Badge tone='success'>Active</Badge>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <br />

          <Button
            variant={'plain'}
            onClick={onAddCompanyClick}
            icon={
              <img
                src={PlusCircleIMG}
                alt='add company plus button'
                style={{ marginTop: 3, marginRight: 4 }}
              />
            }
          >
            Add Company
          </Button>
        </>
      }
    />
  );
};

export default ChangeCompanyModal;
