import React from "react";
import InvoiceTemplate from "./templates/Invoice";
import withRouter from "helpers/withRouter";
import { DocumentType, StepIndex } from "./common/constants";
import "./DocumentTemplate.scss";

const DocumentTemplate = ({ params }) => {
  const { type } = params;

  switch (type) {
    case DocumentType.InvoiceVAT: {
      const getTitle = (stepIndex) => {
        switch (stepIndex) {
          case StepIndex.Edit:
            return "Edit VAT Invoice";
          case StepIndex.Send:
            return "Send VAT Invoice";
          case StepIndex.Create:
            return "Create VAT Invoice";
          default:
            return <></>;
        }
      };

      return <InvoiceTemplate getTitle={getTitle} documentType={type} />;
    }

    case DocumentType.InvoiceSalesTax: {
      const getTitle = (stepIndex) => {
        switch (stepIndex) {
          case StepIndex.Create:
            return "Create Sales Tax Invoice";
          case StepIndex.Edit:
            return "Edit Sales Tax Invoice";
          case StepIndex.Send:
            return "Send Sales Tax Invoice";
          default:
            return <></>;
        }
      };

      return <InvoiceTemplate getTitle={getTitle} documentType={type} />;
    }

    case DocumentType.Exemption: {
      const getTitle = (stepIndex) => {
        switch (stepIndex) {
          case StepIndex.Create:
            return "Create Exemption";
          case StepIndex.Edit:
            return "Edit Exemption";
          case StepIndex.Send:
            return "Send Exemption";
          default:
            return <></>;
        }
      };

      return <InvoiceTemplate getTitle={getTitle} documentType={type} />;
    }
    
    default:
      console.error("No Document Type matches template");
      return null;
  }
};

export default withRouter(DocumentTemplate);
