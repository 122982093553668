import { TextField } from '@shopify/polaris'
import React, { useState } from 'react'
import Dropdown from '../../containers/Messages/Dropdown'

const FilterCustom = ({choices, selected, onChange, onQueryChange, queryValue, onQueryClear, onClearAll}) => {
	
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'space-between',
				marginBottom: 20
			}}
		>
      <div style={{width: '100%', marginRight: 10}}>
         <TextField
	         value={queryValue}
	         onChange={onQueryChange}
	         placeholder={'Search'}
	         fullWidth
	         prefix={
		         <div style={{marginTop: 3}}>
             <svg
	             xmlns='http://www.w3.org/2000/svg'
	             width='16'
	             height='16'
	             viewBox='0 0 16 16'
	             fill='none'
             >
              <circle
	              cx='7'
	              cy='7'
	              r='5'
	              stroke='#999'
	              strokeWidth='2'
	              fill='none'
              />
              <line
	              x1='10'
	              y1='10'
	              x2='14'
	              y2='14'
	              stroke='#999'
	              strokeWidth='2'
              />
            </svg >
           </div >
		         
	         }
	         clearButton
	         onClearButtonClick={onQueryClear}
         />
      </div >
   
    <Dropdown
	    choices={choices}
	    selected={selected}
	    onChange={onChange}
    />
      </div >
	)
}

export default FilterCustom