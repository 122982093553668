import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./style.scss";

const IsCondensedContainer = ({ isCondensed, children }) => {
  if (isCondensed) {
    return (
      <div className='Polaris-DataTable--condensed'>
        <div className='Polaris-DataTable Polaris-DataTable--condensed'>
          {children}
        </div>
      </div>
    );
  }
  return children;
};

const HighlightableDataTable = (props) => {
  const {
    headings = [], // Ожидаем массив заголовков
    rows = [], // Ожидаем массив строк данных
    rowClassNameCallback = () => {}, // Функция для добавления классов строкам
    rawItems = [], // Необязательные сырые данные
    condensed = false, // Флаг для сжатого режима
    styles = { // Стиль для элементов таблицы
      tr: {fontSize: 14},
      th: {fontSize: 14},
      td: {fontSize: 14}
    }
  } = props;
  // const {
  //   headings,
  //   rows,
  //   rowClassNameCallback = () => {},
  //   rawItems = [],
  //   condensed = false,
  //   styles = {
  //     tr: {},
  //     th: {},
  //     td: {},
  //   },
  // } = props;

  const tdClassName =
    "Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop";
  const thClassName =
    "Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop Polaris-DataTable__Cell--truncated Polaris-DataTable__Cell--header";

  return (
    <div className='HighlightableDataTable'>
      <IsCondensedContainer isCondensed={condensed}>
        <div className='Polaris-DataTable__ScrollContainer'>
          <table className='Polaris-DataTable__Table'>
            <thead>
              <tr>
                {headings.map((heading, rowIndex) => (
                  <th
                    className={thClassName}
                    style={styles.th}
                    key={`th-${rowIndex}`}
                  >
                    {heading}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rows?.map((rowData, i) => (
                <tr
                  key={`tr-${i}`}
                  className={classNames(
                    "Polaris-DataTable__TableRow Polaris-DataTable--hoverable",
                    rowClassNameCallback(rowData, rawItems[i])
                  )}
                  style={styles.tr}
                >
                  {rowData?.map((item, index) => {
                    return (
                      <td
                        key={`td-${index}`}
                        className={tdClassName}
                        style={styles.td}
                      >
                        {item}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </IsCondensedContainer>
    </div>
  );
};

HighlightableDataTable.propTypes = {
  headings: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  condensed: PropTypes.bool,
  styles: PropTypes.shape({
    tr: PropTypes.object,
    td: PropTypes.object,
    th: PropTypes.object,
  }),
  rawItems: PropTypes.array, // raw array of data
  rowClassNameCallback: PropTypes.func, // the function takes the current row as an argument, returns an object where the keys are the classnames, and the values are the conditions under which they are applied
};

export default HighlightableDataTable;
