import { Select, TextField } from '@shopify/polaris'
import React, { useEffect, useState } from 'react'
import Datepicker from 'components/Datepicker'
import { useSelector } from 'react-redux'
import SearchingCountry from '../../../../../../components/SearchingCountry/SearchingCountry'

const FormsForCreateNewTransactionDigital = ({
	setFormDataHandler,
	dateRange,
	data
}) => {
	const {countries, currency} = useSelector((state) => state.other)
	
	const getCurrentDateTime = () => {
		const now = new Date()
		const offset = now.getTimezoneOffset() * 60000
		const localDate = new Date(now.getTime() - offset)
		return localDate.toISOString()
	}
	
	// Required Fields States
	const [transactionDatetime, setTransactionDatetime] = useState(getCurrentDateTime())
	const [transactionSum, setTransactionSum] = useState('')
	const [currencyValue, setCurrencyValue] = useState('EUR')
	const [billingAddressCountry, setBillingAddressCountry] = useState('FRA')
	
	// Additional Fields States
	const [transactionId, setTransactionId] = useState('')
	const [vatInvoiceNumber, setVatInvoiceNumber] = useState('')
	const [invoiceDate, setInvoiceDate] = useState('')
	const [orderId, setOrderId] = useState('')
	const [parentTransactionId, setParentTransactionId] = useState('')
	const [billingAddressZip, setBillingAddressZip] = useState('')
	const [billingState, setBillingState] = useState('')
	const [billingCity, setBillingCity] = useState('')
	const [billingAddressLine, setBillingAddressLine] = useState('')
	const [paymentMethodCountry, setPaymentMethodCountry] = useState('FRA')
	const [serviceCode, setServiceCode] = useState('')
	const [buyerIpAddress, setBuyerIpAddress] = useState('')
	const [paymentDatetime, setPaymentDatetime] = useState('')
	const [quantity, setQuantity] = useState('')
	const [itemDescription, setItemDescription] = useState('')
	const [buyerName, setBuyerName] = useState('')
	const [buyerId, setBuyerId] = useState('')
	
	const [error, setError] = useState(data?.customer_country_id === 'SVN' ? 'VAT invoice number is required.' : "");
	
	// ------Functionals--------
	useEffect(() => {
		const formData = {}
		
		if (transactionDatetime) formData.transaction_datetime = transactionDatetime
		if (transactionSum) formData.transaction_sum = parseFloat(transactionSum)
		if (currencyValue) formData.currency = currencyValue
		if (billingAddressCountry) formData.billing_address_country = billingAddressCountry || billingAddressCountry.code
		if (transactionId) formData.transaction_id = transactionId
		if (orderId) formData.order_id = orderId
		if (parentTransactionId) formData.parent_transaction_id = parentTransactionId
		if (billingAddressZip) formData.billing_address_zip = billingAddressZip
		if (billingState) formData.billing_state = billingState
		if (billingCity) formData.billing_city = billingCity
		if (billingAddressLine) formData.billing_address_line = billingAddressLine
		if (paymentMethodCountry) formData.payment_method_country = paymentMethodCountry
		if (serviceCode) formData.service_code = serviceCode
		if (buyerIpAddress) formData.buyer_ip = buyerIpAddress
		if (paymentDatetime) formData.payment_date_time = paymentDatetime
		if (quantity) formData.quantity = parseInt(quantity, 10)
		if (itemDescription) formData.item_description = itemDescription
		if (buyerName) formData.buyer_name = buyerName
		if (buyerId) formData.buyer_id = buyerId
		if (vatInvoiceNumber) formData.inv_number = vatInvoiceNumber
		if (invoiceDate) formData.inv_date = invoiceDate
		
		setFormDataHandler(formData)
	}, [
		transactionDatetime,
		transactionSum,
		currencyValue,
		transactionId,
		orderId,
		parentTransactionId,
		billingAddressZip,
		billingState,
		billingAddressLine,
		paymentMethodCountry,
		serviceCode,
		buyerIpAddress,
		paymentDatetime,
		quantity,
		itemDescription,
		buyerName,
		buyerId,
		vatInvoiceNumber,
		invoiceDate,
	])
	
	const handleDateChange = (selectedDate,
		line) => {
		if (selectedDate) {
			const isoString = selectedDate.toISOString()
			if (line === 'transaction') {
				setTransactionDatetime(isoString)
			} else if (line === 'payment') {
				setPaymentDatetime(isoString)
			} else if (line === 'invoiceDate') {
				setInvoiceDate(isoString)
			}
		}
	}
	
	const currencyOptions = currency.map((item) => ({
		label: `${item}`,
		value: item,
		key: item,
	}))
	
	const countryOptions = countries.map((country) => ({
		label: `${country.name} (${country.code})`,
		value: country.code,
		key: `${country.name}(${country.code})`,
	}))
	
	const handleTransactionSumChange = (value, line) => {
		const regex = /^-?\d*\.?\d{0,2}$/;
		if (regex.test(value)) {
			if (line === 'transactionSum') setTransactionSum(value)
		}
	}
	const handleChange = (value) => {
		setVatInvoiceNumber(value);
		if (data?.customer_country_id === 'SVN' && !value) {
			setError('VAT invoice number is required.');
		} else {
			setError('');
		}
	};
	return (
		<div className='transaction-create_form-wrapper'>
			<div className='transaction-create_form-wrapper_fields'>
				<h3 className='transaction-create_form-wrapper_fields-title'>
					Required Fields <span style={{color: 'red'}}>*</span >
				</h3 >
				<label >
				  Transaction Date <span style={{color: 'red'}}>*</span >
				</label >
				<Datepicker
					id='due_date3'
					allowRange={false}
					value={transactionDatetime}
					onChange={newDate => handleDateChange(newDate, 'transaction')}
					autoComplete='off'
					placeholder='Select a date'
					label=''
					maxDateOverride={dateRange.date_end}
					minDateOverride={dateRange.date_start}
				/>
				<TextField
					label="Transaction amount (with tax)"
					placeholder="Enter Transaction amount (with tax)"
					value={transactionSum}
					onChange={(value) => handleTransactionSumChange(value, 'transactionSum')}
					type="text"
					inputMode="decimal"
					requiredIndicator
					className="form-input"
					autoComplete='off'
				/>
				<Select
					label='Currency'
					placeholder='Currency'
					options={currencyOptions}
					value={currencyValue}
					onChange={setCurrencyValue}
					className='form-select'
					requiredIndicator
				/>
				<SearchingCountry
					title={'Billing Address Country'}
					placeholder={`Enter Billing Address Country`}
					onChange={setBillingAddressCountry}
					dataCountriesList={countryOptions}
					changeCountry={billingAddressCountry.name}
					isOpen={true}
				/>
      </div >
			<div className='transaction-create_form-wrapper_fields transaction-create_form-wrapper_fields-additional'>
				<h3 className='transaction-create_form-wrapper_fields-title'>Additional Fields</h3 >
				<TextField
					label='Transaction id'
					placeholder='Enter Transaction id'
					value={transactionId}
					onChange={(value) => setTransactionId(value)}
					type='text'
					className='form-input'
					autoComplete='off'
				/>
				<TextField
					label='Order id'
					placeholder='Enter Order id'
					value={orderId}
					onChange={(value) => setOrderId(value)}
					type='text'
					className='form-input'
					autoComplete='off'
				/>
				<TextField
					label='Parent Transaction id'
					placeholder='Enter Parent Transaction id'
					value={parentTransactionId}
					onChange={(value) => setParentTransactionId(value)}
					type='text'
					className='form-input'
					autoComplete='off'
				/>
				<TextField
					label='VAT invoice number'
					placeholder='Enter VAT invoice number'
					value={vatInvoiceNumber}
					onChange={(value) => handleChange(value)}
					type='text'
					className='form-input'
					autoComplete='off'
					requiredIndicator={ data?.customer_country_id === 'SVN' ? true : false}
					error={error}
				/>
				<Datepicker
					id='due_date3'
					allowRange={false}
					value={invoiceDate}
					onChange={newDate => handleDateChange(newDate, 'invoiceDate')}
					autoComplete='off'
					placeholder='Select a date'
					label='Invoice date'
					start={invoiceDate}
				/>
				<TextField
					label='Billing address ZIP'
					placeholder='Enter Billing address ZIP'
					value={billingAddressZip}
					onChange={(value) => setBillingAddressZip(value)}
					type='text'
					className='form-input'
					autoComplete='off'
				/>
				<TextField
					label='Billing State'
					placeholder='Enter Billing State'
					value={billingState}
					onChange={(value) => setBillingState(value)}
					type='text'
					className='form-input'
					autoComplete='off'
				/>
				<TextField
					label='Billing City'
					placeholder='Enter Billing City'
					value={billingCity}
					onChange={(value) => setBillingCity(value)}
					type='text'
					className='form-input'
					autoComplete='off'
				/>
				<TextField
					label='Billing address line'
					placeholder='Enter Billing address line'
					value={billingAddressLine}
					onChange={(value) => setBillingAddressLine(value)}
					type='text'
					className='form-input'
					autoComplete='off'
				/>
				<SearchingCountry
					title={'Payment method country'}
					placeholder={`Enter Payment method country`}
					onChange={setPaymentMethodCountry}
					dataCountriesList={countryOptions}
					changeCountry={paymentMethodCountry}
				/>
				<TextField
					label='Service code'
					placeholder='Enter Service code'
					value={serviceCode}
					onChange={(value) => setServiceCode(value)}
					type='text'
					className='form-input'
					autoComplete='off'
				/>
				<TextField
					label='Buyer IP address'
					placeholder='Enter Buyer IP address'
					value={buyerIpAddress}
					onChange={(value) => setBuyerIpAddress(value)}
					type='text'
					className='form-input'
					autoComplete='off'
				/>
				<Datepicker
					id='due_date4'
					allowRange={false}
					value={paymentDatetime}
					onChange={newDate => handleDateChange(newDate, 'payment')}
					autoComplete='off'
					placeholder='Select a date'
					label='Payment date time'
					start={paymentDatetime}
				/>
				<TextField
					label='Quantity'
					placeholder='Enter Quantity'
					value={quantity}
					onChange={(value) => setQuantity(parseInt(value))}
					type='number'
					className='form-input'
					min='0'
					step='1'
					autoComplete='off'
				/>
				<TextField
					label='Item description'
					placeholder='Enter Item description'
					value={itemDescription}
					onChange={(value) => setItemDescription(value)}
					type='text'
					className='form-input'
					autoComplete='off'
				/>
				<TextField
					label='Buyer name'
					placeholder='Enter Buyer name'
					value={buyerName}
					onChange={(value) => setBuyerName(value)}
					type='text'
					className='form-input'
					autoComplete='off'
				/>
				<TextField
					label='Buyer id'
					placeholder='Enter Buyer id'
					value={buyerId}
					onChange={(value) => setBuyerId(value)}
					type='text'
					className='form-input'
					autoComplete='off'
				/>
			</div >
		</div >
	)
}

export default FormsForCreateNewTransactionDigital