import { Box, Button, ButtonGroup, Card, Checkbox, DataTable, Inline, Text } from "@shopify/polaris";
import SaveBar from "components/SaveBar/SaveBar";
import useAppDispatch from "hooks/useAppDispatch";
import { t } from "i18next";
import React from "react";
import { useSelector } from "react-redux";
import ArrowLeftBack from '../../../../img/arrow-left-for-button-back.svg'
import ArrowRight from '../../../../img/arrow-right-white.svg'
import { selectCategory5 } from "../../../../redux/features/declarations/declarationsSlice";
import formatRow from "utils/formatRow";

const SubcategoryStep5 = ({toNextStep, toPrevStep}) => {
	const dispatch = useAppDispatch();
	const {
		eprDeclaration: {subcategories_data},
		eprSubcategories: {selectedCategory5, selectedCategory4},
	} = useSelector((state) => state.declarations);
	
	const handleChange = (
		checked,
		category,
		subCategory,
		subSubCategory,
		subSubSubCategory,
		checkbox
	) => {
		dispatch(
			selectCategory5({
				checked,
				category,
				subCategory,
				subSubCategory,
				subSubSubCategory,
				subSubSubSubCategory: checkbox,
			})
		);
	};
	
	const columns = [
		{
			header: {
				label: "Subcategory 1",
			},
			cell: {
				formatters: [
					(_,
						{rowData}) => rowData[0],
				],
			},
		},
		{
			header: {
				label: "Subcategory 2",
			},
			cell: {
				formatters: [
					(_,
						{rowData}) => <div style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>{rowData[1]}</div>,
				],
			},
		},
		{
			header: {
				label: "Subcategory 3",
			},
			cell: {
				formatters: [
					(_,
						{rowData}) => <div style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>{rowData[2]}</div>,
				],
			},
		},
		{
			header: {
				label: "Subcategory 4",
			},
			cell: {
				formatters: [
					(_,
						{rowData}) => <div style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>{
						rowData[3].name === undefined ? rowData[3] : `${rowData[3]?.name} ${rowData[3]?.units}`
					}</div>,
				],
			},
		},
		{
			header: {
				label: "Subcategory 5",
			},
			cell: {
				formatters: [
					(_,
						{rowData}) => {
						const category = rowData[0];
						const subCategory = rowData[1];
						const subSubCategory = rowData[2];
						const subSubSubCategory = rowData[3];
						// console.log('subcategories_data.subcategory_5', subcategories_data)
						const checkboxes = subcategories_data?.subcategory_5[subSubSubCategory?.name] || [];
						// console.log('checkboxes', checkboxes)
						// console.log('subSubSubCategory', subSubSubCategory)
						// console.log('subcategories_data.subcategory_5', subcategories_data)
						return (
							<Box fullWidth style={{ display: 'flex', flexDirection: 'column', gap: '16px', whiteSpace: 'normal' }}>
								{checkboxes.map((checkbox) => {
									const id = `${subSubSubCategory?.name || subSubSubCategory}-${subSubSubCategory?.units || ''}-${category}-${subCategory}-${subSubCategory}-${checkbox}`;
									
									return (
										<Checkbox
											key={id}
											id={id}
											value={checkbox}
											label={checkbox}
											checked={selectedCategory5?.some(
												(c) =>
													c[0] === category &&
													c[1] === subCategory &&
													c[2] === subSubCategory &&
													(
														// Сравнение subSubSubCategory
														(typeof c[3] === 'object' &&
															c[3]?.name === subSubSubCategory.name &&
															c[3]?.units === (subSubSubCategory.units || '')
														) ||
														(typeof c[3] === 'string' && c[3] === subSubSubCategory)
													) &&
													c[4] === checkbox
											)}
											onChange={(checked) =>
												handleChange(
													checked,
													category,
													subCategory,
													subSubCategory,
													subSubSubCategory,
													checkbox
												)
											}
										/>
									);
								})}
							</Box>
						);
					},
				],
			},
		},
	];
	
	const rows = formatRow(selectedCategory4, columns);
	
	return (
		<>
            <Card sectioned>
                <DataTable
	                columnContentTypes={["text", "text", "text", "text", "text"]}
	                headings={columns.map(({header}) => (
		                <Text
			                key={header.label}
			                fontWeight='semibold'
		                >{header.label}</Text >
	                ))}
	                rows={rows}
                />
            </Card >

            <SaveBar >
                <ButtonGroup >
                    <Button size={'micro'} onClick={toPrevStep}> <img src={ArrowLeftBack} alt='arrow' style={{width: 10, height:10 , marginRight:8}}/>{t("eprReporting.back")}</Button >
                    <Button
	                    variant={'primary'}
	                    size={'micro'}
	                    onClick={toNextStep}
                    >
                        {t("orderReg.next")}<img src={ArrowRight} alt='arrow' style={{width: 10, height:10 , marginLeft:8}}/>
                    </Button >
                </ButtonGroup >
            </SaveBar >
        </>
	);
};

export default SubcategoryStep5;
