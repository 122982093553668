import React, { useEffect, useMemo } from "react";
import {
  AlphaStack, Box,
  Button,
  ButtonGroup,
  Card,
  Inline,
  Select,
  Text,
} from "@shopify/polaris";
import { formatMoney } from "utils/numbers";
import { useSelector } from "react-redux";
import useActionDialogs from "hooks/useActionDIalogs";
import { getCurrencySymbol } from "utils/currency";
import { getTaxList } from '../../../../redux/features/documents/documentsSlice'
import { TaxInfoModal } from "../../modals";
import useAppDispatch from "hooks/useAppDispatch";
import InlineText from "../../common/InlineText";
import {
  calculateSubtotals,
  calculateTotalTaxAmount,
} from "containers/DocumentTemplate/common/utils";

const ActionDialogs = Object.freeze({
  tax: "tax",
});

const Totals = ({ data, setData }) => {
  const dispatch = useAppDispatch();
  const { taxId, line_items, currency } = data;
  const { taxes } = useSelector((state) => state.documents);
  const { actionDialogs, handleActionDialogsOpen, handleActionDialogsClose } =
    useActionDialogs([ActionDialogs.tax]);
  const currentTax = taxes.find(({ id }) => taxId === String(id)) ?? {};

  useEffect(() => {
    dispatch(getTaxList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const taxesOptions = [
    "",
    ...taxes.map(({ id, name, rate }) => ({
      label: `${name} ${rate}%`,
      value: String(id),
    })),
  ];

  const subtotals = useMemo(() => {
    return calculateSubtotals(line_items);
  }, [line_items]);

  const totalTaxAmount = useMemo(() => {
    return calculateTotalTaxAmount(line_items, taxId, taxes);
  }, [line_items, taxId, taxes]);

  const subtotal = formatMoney(subtotals);
  const total = formatMoney(subtotals + totalTaxAmount);
  const totalTax = formatMoney(totalTaxAmount);

  const onAddClick = () => {
    handleActionDialogsOpen("tax", { type: "create" });
  };

  const onEditClick = () => {
    handleActionDialogsOpen("tax", {
      type: "update",
      ...currentTax,
    });
  };

  const onTaxSelect = (id) => {
    setData((prev) => ({
      ...prev,
      taxId: id,
    }));
  };

  return (
    <>
      <div className='totals'>
        <div className='totals-tax'>
          <div className='totals-tax-select'>
            <Select
              label='Select or add tax'
              options={taxesOptions}
              onChange={onTaxSelect}
              value={taxId}
            />
          </div>
          <div style={{ marginTop: "1.5rem" }}>
            <div style={{marginLeft: '0.6rem'}}>
              <Button size={'micro'} variant={'primary'} onClick={onAddClick}>
                Add new tax
              </Button>
            </div>
          </div>
        </div>
        <div className='totals-selectedTax'>
          {taxId && (
            <Card sectioned>
              <Box
                display="flex"
                flexDirection="column"
                gap="2"
              >
                <InlineText label='Tax name' text={currentTax.name} />
                <InlineText label='Rate' text={`${currentTax.rate} %`} />
                <Button onClick={onEditClick} variant={'plain'}>
                  Edit tax
                </Button>
              </Box>
            </Card>
          )}
        </div>
        <div className='totals-numbers'>
          <Box display="flex" alignItems="space-between">
            <Text variant='headingMd'>Subtotal:</Text>
            <Text variant='headingMd' fontWeight='regular'>
              {getCurrencySymbol(currency)}
              {subtotal}
            </Text>
          </Box>
          <Box display="flex" alignItems="space-between">
            <Text variant='headingMd'>Total Tax Amount:</Text>
            <Text variant='headingMd' fontWeight='regular'>
              {getCurrencySymbol(currency)}
              {totalTax}
            </Text>
          </Box>
          <Box display="flex" alignItems="space-between">
            <Text variant='headingXl'>Total:</Text>
            <Text variant='headingXl' fontWeight='regular'>
              {getCurrencySymbol(currency)}
              {total}
            </Text>
          </Box>
        </div>
      </div>

      <TaxInfoModal
        onClose={() => handleActionDialogsClose("tax")}
        isOpen={actionDialogs.tax.open}
        data={actionDialogs.tax.cellData}
      />
    </>
  );
};

export default Totals;
