import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import withRouter from "helpers/withRouter";
import {
  Box,
  Card,
  Text,
} from "@shopify/polaris";

import NoAvatarIMG from "img/avatar.svg";
import SupportTeamIMG from "img/supportTeam.svg";

import "./index.scss";
import SupportMessages from "../SupportMessages/SupportMessages";

class Support extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { t } = this.props;
    const {
      user: { company },
    } = this.props;
    return (
      <Box>
        <Card sectioned>
          <div className='support__managerWrap'>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <img
                src={NoAvatarIMG}
                alt='avatar'
                style={{ width: 110, height: 110, marginRight: 20 }}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "10px",
                }}
              >
                <div style={{marginBottom: 10}}>
                  <Text variant='headingLg' fontWeight='medium'>
                  {t("help.pm")}
                </Text>
                </div>
                
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "2px",
                    width:"100%"
                  }}
                >
                  <div style={{marginBottom: 8}}>
                  <Text>{`${t("help.name")}: ${company.manager.first_name} ${
                    company.manager.last_name
                  }`}</Text>
                  </div>
                  <div style={{marginBottom: 8}}>
                  <Text>
                    {t("help.skype")}:{" "}
                    <a
                      className='Polaris-Link'
                      style={{ textDecoration: "none" }}
                      href={`skype:${company.manager.contacts.skype}`}
                    >
                      {company.manager.contacts.skype}
                    </a>
                  </Text>
                  </div>
                  <div style={{marginBottom: 8}}>
                  <Text>
                    {t("help.email")}:{" "}
                    <a
                      className='Polaris-Link'
                      style={{ textDecoration: "none" }}
                      href={`mailto:${company.manager.email}`}
                    >
                      {company.manager.email}
                    </a>
                  </Text>
                  </div>
                </div>
              </div>
            </div>
            <div style={{
              display: "flex",
              alignItems: "center",
              width: "2px",
              
              border: '1px solid rgb(227, 227, 227)'
            }}></div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <img
                src={SupportTeamIMG}
                alt='support team'
                style={{ height: 110, width: "auto" }}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "4px",
                }}
              >
                <div style={{marginBottom: 8}}>
                <Text variant='headingLg' fontWeight='medium'>
                  Support team
                </Text>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "2px",
                  }}
                >
                  <div style={{marginBottom: 8}}>
                  <Text>
                    Email support:{" "}
                    <a
                      className='Polaris-Link'
                      style={{ textDecoration: "none" }}
                      href='mailto:support@vatcompliance.co'
                    >
                      support@vatcompliance.co
                    </a>
                  </Text>
                  </div>
                  <div style={{marginBottom: 8}}>
                  <Text>
                    Phone support:{" "}
                    <a
                      className='Polaris-Link'
                      style={{ textDecoration: "none" }}
                      href='tel:+442039662390'
                    >
                      +44 203 966 2390
                    </a>
                  </Text>
                  <div style={{marginBottom: 8, opacity: 0}}>
                  <Text>
                    <a
                      className='Polaris-Link'
                      style={{ textDecoration: "none" }}
                      href='/'
                    />
                    f
                  </Text>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card>
        <SupportMessages />
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(Support))
);
