import { Button, ButtonGroup } from "@shopify/polaris";
import SaveBar from "components/SaveBar/SaveBar";
import { StepIndex } from "containers/DocumentTemplate/common/constants";
import {
  areAllRequiredFieldsValid,
  validateRequiredFields,
} from "containers/DocumentTemplate/templates/Invoice/helpers";
import useAppDispatch from "hooks/useAppDispatch";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { formatDate } from "utils/dates";
import { createInvoice } from '../../../../redux/features/documents/documentsSlice'

const InvoiceEditSaveBar = ({ data, documentType, setStepIndex }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    invoice: { loading },
  } = useSelector((state) => state.documents);

  const requiredFields = validateRequiredFields(documentType, data);
  const disabled = !areAllRequiredFieldsValid(requiredFields);

  const generateInvoiceClickHandler = (action, callback) => {
    const {
      customerId,
      bankId,
      currency,
      taxId,
      due_date,
      invoice_date,
      ...restData
    } = data;

    return () => {
      const params = {
        action,
        bank_id: bankId,
        customer_id: customerId,
        due_date: formatDate(due_date),
        invoice_date: formatDate(invoice_date),
        ...restData,
        ...(taxId !== null && { tax_id: taxId }), // Добавляем tax_id только если он не null
      };
      dispatch(createInvoice({ params })).then(callback);
    };
  };

  const onPreviewInvoiceClick = generateInvoiceClickHandler("preview", () =>
    setStepIndex(StepIndex.Send)
  );

  const onSaveInvoiceClick = generateInvoiceClickHandler("save", () =>
    navigate("/documents")
  );

  return (
    <SaveBar>
      <ButtonGroup>
        <Button
          size={'micro'}
          loading={loading.save}
          onClick={onSaveInvoiceClick}
          disabled={disabled}
        >
          Save as draft
        </Button>
        <Button
          variant={'primary'}
          size={'micro'}
          onClick={onPreviewInvoiceClick}
          loading={loading.preview}
          disabled={disabled}
        >
          Review & Send Invoice
        </Button>
      </ButtonGroup>
    </SaveBar>
  );
};

export default InvoiceEditSaveBar;
