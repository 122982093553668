import { Card, Checkbox, DataTable, Layout, Spinner, Text } from '@shopify/polaris'
import NoDataMessage from 'components/NoDataMessage/NoDataMessage'
import { t } from 'i18next'
import _ from 'lodash'
import React from 'react'

const AutopilotStores = ({
	storeList,
	storeCheckedList,
	handleCheckboxChange,
	fetchingAutoStores = true,
	errorMessageStatus
}) => {
	
	const columns = [
		{header: t("taxSettings.store"), key: "title", align: "left"},
		{header: '', key: "autosubmit", align: "right"}
	]
	
	const rows = storeList.map(store => [
		store.title,
		<div
			style={{display: 'flex', justifyContent: 'flex-end', margin: '0 auto'}}
			key={store.title}
		>
			<div
				className='error-checkbox-view'
				style={{
					border: errorMessageStatus ? '2px solid red' : 'none',
				}}
			>
				<Checkbox
					checked={
						storeCheckedList?.find(item => item.id === store.id)?.autosubmit || false
					}
					onChange={(checked) => handleCheckboxChange(checked, store.id, 'store')}
				/>
			</div >
		</div>
	])
	
	return (
		<Layout.AnnotatedSection
			title={
				<Text
					variant='headingLg'
					as='p'
				>
					{t("taxSettings.stores")}
				</Text >
			}
		>
			<Card >
				{fetchingAutoStores ? (
						<div >
						<Spinner
							accessibilityLabel='Loading stores'
							size='large'
						/>
					</div >
					)
					:
					_.isEmpty(storeList) && (
						<NoDataMessage style={{height: "auto", padding: "1.5rem 0px"}} />
					)
				}
				
				{!fetchingAutoStores && !_.isEmpty(storeList) && (
					<DataTable
						columnContentTypes={["text", "checkbox"]}
						headings={columns.map(({header, align}) => (
							<div style={{textAlign: align, width: '100%', fontWeight: 'bold'}}>
								{header}
							</div >
						))}
						rows={rows}
					/>
				)}
			</Card >
			{
				errorMessageStatus && (
					<p className='error-message'>
            Please select all store from the list.
        </p>
				)
			}
		</Layout.AnnotatedSection >
	)
}

export default AutopilotStores
